/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { Button, Menu, MenuItem } from '@mui/material';
import { currentRegionState } from './regionsSelector';
import { Language } from '@mui/icons-material';
import {
  selector,
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { viewStateAtom } from '../map/atoms';
import bbox from '@turf/bbox';
import React from 'react';
import {
  centroid,
  Feature,
  feature,
  featureCollection,
  Polygon,
} from '@turf/turf';
import { FlyToInterpolator, WebMercatorViewport } from '@deck.gl/core';
import { GeoJsonLayer, TextLayer } from '@deck.gl/layers';

export const FemaRegions = React.memo(() => {
  const [currentRegion, setCurrentRegion] = useRecoilState(currentRegionState);
  const setViewState = useSetRecoilState(viewStateAtom);
  const femaRegionsJson = useRecoilValueLoadable(regionState);

  const handleRegionTransition = React.useCallback(
    (props: { region: Feature<Polygon, any> }) => {
      const { region } = props;
      if (region?.properties.region) {
        if ([9, 10].includes(region?.properties.region ?? 0)) {
          // @ts-ignore
          const centerRegion = centroid(region);
          // @ts-ignore
          const zoom = [2, 9, 10].includes(region.properties.region) ? 3 : 4;

          // @ts-ignore
          setViewState((view: any) => ({
            ...view,
            longitude: centerRegion.geometry.coordinates[0],
            latitude: centerRegion.geometry.coordinates[1],
            transitionInterpolator: new FlyToInterpolator({ speed: 3.0 }),
            transitionDuration: 1000,
            zoom,
          }));
        } else {
          const [minLng, minLat, maxLng, maxLat] = bbox(region);

          // @ts-ignore
          setViewState((v: any) => {
            const viewport = new WebMercatorViewport({
              width: v.width,
              height: v.height,
              longitude: -122.45,
              latitude: 37.78,
              zoom: 12,
              pitch: 30,
              bearing: 15,
            });
            const viewState = viewport.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat],
              ],
              {
                padding: 80,
              }
            );

            return {
              // @ts-expect-error
              latitude: viewState.latitude,
              // @ts-expect-error
              longitude: viewState.longitude,
              zoom: viewState.zoom,
              // @ts-expect-error
              pitch: viewState.pitch,
              // @ts-expect-error
              bearing: viewState.bearing,
              transitionInterpolator: new FlyToInterpolator(),
              transitionDuration: 1000,
            };
          });
        }
      }
    },
    [setViewState]
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleMenuClick = React.useCallback(
    (f: any) => {
      if (f == null) {
        handleClose();
        return setCurrentRegion(null);
      }

      if (f.properties.region !== currentRegion) {
        setCurrentRegion(f.properties.region);

        handleRegionTransition({ region: f });
      }
      handleClose();
    },
    [handleClose, handleRegionTransition, setCurrentRegion, currentRegion]
  );

  if (femaRegionsJson.state === 'loading') {
    return null;
  }

  return (
    <>
      <div className="region-picker">
        <Button
          variant="contained"
          color="primary"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          startIcon={<Language />}
        >
          {currentRegion
            ? // @ts-ignore
              `Region ${currentRegion}`
            : 'Region View'}
        </Button>
        <Menu
          id="region-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: '300px',
              width: '20ch',
            },
          }}
        >
          <MenuItem dense onClick={() => handleMenuClick(null)}>
            Clear
          </MenuItem>

          {femaRegionsJson?.contents?.features
            ?.filter((f: any) => f.properties?.region != null)
            ?.sort(
              (a: any, b: any) => a.properties.region - b.properties.region
            )
            ?.map((f: any, index: number) => (
              <MenuItem dense key={index} onClick={() => handleMenuClick(f)}>
                Region: {f.properties.region}
              </MenuItem>
            ))}
        </Menu>
      </div>
    </>
  );
});

export const regionState = selector({
  key: 'regionSelect',
  get: async () => {
    try {
      const response = await fetch(
        'https://www.fema.gov/api/open/v2/FemaRegions',
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
      const json = await response.json();
      return featureCollection(
        json.FemaRegions.filter((r: any) => r.region != null).map((r: any) => {
          const { regionGeometry, ...rest } = r;
          return feature(regionGeometry, rest);
        })
      );
    } catch (e) {
      console.error(e);
      return featureCollection([]);
    }
  },
});

export const useRegionLayer = (props: { mode: 'all' | 'select' }) => {
  const femaRegionsJson = useRecoilValueLoadable(regionState);
  const [currentRegion] = useRecoilState(currentRegionState);

  const regionLayer = React.useMemo(() => {
    if (femaRegionsJson.state === 'loading') {
      return [];
    }

    const textLayer = new TextLayer({
      id: 'text-layer',
      data: femaRegionsJson.contents ?? null,
      // @ts-ignore
      getPosition: f => centroid(f).geometry.coordinates,
      // @ts-ignore
      getText: f => `Region ${f.properties.region}`,
      getSize: 16,
      getColor: [255, 255, 255],
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center',
    });

    return [
      new GeoJsonLayer({
        id: 'region-layer',
        data: femaRegionsJson.contents ?? null,
        getFillColor: f => {
          if (props.mode === 'all') {
            return [0, 0, 0, 0];
          }

          // @ts-ignore
          return f.properties.region === currentRegion
            ? [0, 82, 135, 40]
            : [0, 0, 0, 0];
        },
        getLineColor: f => {
          if (props.mode === 'all') {
            return [0, 106, 165, 250];
          }
          // @ts-ignore
          return f.properties.region === currentRegion
            ? [0, 106, 165, 250]
            : [0, 0, 0, 0];
        },
        getLineWidth: f =>
          props.mode === 'all'
            ? 1
            : // @ts-ignore
            f.properties.region === currentRegion
            ? 1
            : 0,
        lineWidthMinPixels: 2,
        lineWidthScale: 2,
        pickable: false,
        stroked: props.mode === 'all' ? true : currentRegion != null,
        visible: props.mode === 'all' ? true : currentRegion != null,
        wrapLongitude: true,
        updateTriggers: {
          getFillColor: [
            props.mode,
            currentRegion,
            femaRegionsJson.contents != null,
          ],
          getLineColor: [
            props.mode,
            currentRegion,
            femaRegionsJson.contents != null,
          ],
          getLineWidth: [
            props.mode,
            currentRegion,
            femaRegionsJson.contents != null,
          ],
        },
      }),
      textLayer,
    ];
  }, [currentRegion, femaRegionsJson, props.mode]);

  return regionLayer;
};
