import React from 'react';
import { Button, Paper, Tooltip } from '@mui/material';
import { Download } from '@mui/icons-material';
import { _MapContext as MapContext, _useMapControl } from 'react-map-gl';

export const DownloadButton = (props: { title: string }) => {
  const { eventManager, container, map } = React.useContext(MapContext);

  const { title } = props;
  const { containerRef: downloadButtonRef } = _useMapControl({
    captureClick: true,
    captureScroll: true,
    captureDrag: false,
    captureDoubleClick: false,
    capturePointerMove: false,
  });

  // @ts-ignore
  const canvasEl = container?.children?.['deckgl-overlay'];
  const mapCanvasEl = map?._canvas;
  const handleDownload = React.useCallback(
    (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const mapEl = document.querySelector<HTMLElement>('#map-container');
      if (canvasEl && mapCanvasEl && mapEl != null) {
        import('html2canvas').then(html2canvas =>
          html2canvas
            .default(mapEl, {
              backgroundColor: null,
              useCORS: true,
              ignoreElements: el => {
                if (el.getAttribute('data-print') === 'hidden') {
                  return true;
                }
                return false;
              },
              logging: false,
            })
            .then(canvas => {
              const a = document.createElement('a');
              a.href = canvas.toDataURL('image/png');
              a.download = `${title}.png`;
              a.click();
            })
        );
      }

      eventManager.once('click', (e: any) => e.stopPropagation(), evt.target);
    },
    [title, canvasEl, mapCanvasEl, eventManager]
  );

  const handleHover = React.useCallback(
    (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      eventManager.once('hover', (e: any) => e.stopPropagation(), evt.target);
    },
    [eventManager]
  );

  return (
    <Tooltip title="Download map">
      <Paper elevation={0}>
        <Button
          ref={downloadButtonRef}
          sx={{ backgroundColor: 'white', width: 30, minWidth: 30, height: 30 }}
          size="small"
          onMouseEnter={handleHover}
          onClick={handleDownload}
        >
          <Download />
        </Button>
      </Paper>
    </Tooltip>
  );
};
