import React, { useState } from 'react';
import Map from '../map/Map';
import { MapControls } from '../map/MapControls';
import { ReactComponent as TempoLogo } from '../images/tempo_full_color_DKGray.svg';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import { IncidentChart } from '../Incident/IncidentChart';
import { ExposureStats } from '../Incident/ExposureStats';
import { usePeriodicIncidentDataRefresh } from '../Incident/atoms';

export const IncidentcastPage: React.FC = () => {
  usePeriodicIncidentDataRefresh();
  const [isPulsing] = useState(false);

  const match = useMatch({ path: '/wowcast/:incidentId/:sectionId' });
  const incidentId = match?.params.incidentId || '';

  const [currentFeatureProperties] = useState<Record<string, any>>();

  return (
    <Box sx={{ height: '100vh', overflow: 'hidden' }}>
      <div
        style={{
          width: '100vw',
          height: '85vh',
          backgroundColor: 'rgb(0, 62, 103)',
        }}
      >
        <Map
          miniMap
          context="wowcast"
          hideDrawer
          selectedMap="wow"
          title="Wowcast Dashboard"
          outerChildren={
            <>
              <MapControls miniMap context="wowcast" />
            </>
          }
        />
        <Box
          sx={{
            height: '85vh',
            width: '500px',
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1000,
          }}
        >
          <IncidentChart context="wowcast" />
        </Box>
      </div>
      <Box
        sx={{
          backgroundColor: 'rgb(0, 62, 103)',
          height: '240px',
          width: '100%',
        }}
      >
        <NewsCastFooter
          isPulsing={isPulsing}
          incidentId={incidentId}
          currentFeatureProperties={currentFeatureProperties}
        />
      </Box>
    </Box>
  );
};

type NewsCastFooterProps = {
  incidentId: string;
  isPulsing: boolean;
  currentFeatureProperties?: Record<string, any>;
};

const NewsCastFooter = React.memo((props: NewsCastFooterProps) => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        bottom: 0,
        right: 0,
        zIndex: 1000,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
      }}
      className="news-cast-wrap"
    >
      <Box
        className={`news-cast-container ${
          props.isPulsing ? 'pulsing-highlight' : ''
        }`}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'space-between',
          alignContent: 'space-between',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ width: 200 }}>
          <Link to="/">
            <TempoLogo
              height={100}
              width={140}
              style={{
                backgroundColor: 'white',
                borderRadius: '0.5rem',
                padding: '0.5rem',
                minHeight: 100,
                minWidth: 120,
              }}
              aria-label="TEMPO"
            />
          </Link>
        </Box>

        {/* <div className="news-cast-title">WoW Alerts</div> */}
        <Box>
          <ExposureStats
            section="population"
            incidentId={props.incidentId}
            viewType="wowcast"
            hideFilters
          />
        </Box>

        <div className="news-cast-footer">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center',
              padding: '0.5rem 0.5rem',
              width: '200px',
              textAlign: 'right',
            }}
          >
            <div className="qr-code-background" />
            FEMA Contact:{' '}
            <a
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
              href="mailto:adam.barker@fema.dhs.gov"
            >
              adam.barker@fema.dhs.gov
            </a>
          </Box>
        </div>
      </Box>
      <Box
        sx={{
          padding: '0.5rem 1rem',
          width: '100%',
          textAlign: 'left',
          fontSize: '1.4rem',
        }}
      >
        The map layer displays POST's relative population exposure rank, a
        metric used to prioritize disaster response operations based on the
        predicted exposure of vulnerable populations. This web page and the data
        shown will automatically refresh every 15 minutes.
      </Box>
    </Box>
  );
});
