/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { Feature, Polygon } from '@turf/turf';
import { wowChunksSelector } from './selectors';
import { WowProperties, WowPostProperties, CategoryID } from './types';
import { primaryKeyForSection } from '../Post/PostLayer';
import { useGridCellLayer } from '../map/GridCellLayer';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import React from 'react';
import { Legend } from '../components/Legend';
import { RGBColor, RGBAColor } from '@deck.gl/core/utils/color';
import { useMatch } from 'react-router-dom';
import { Box } from '@mui/material';
import { selectedFeatureAtom } from '../Incident/FeaturePopup';
import { Toast } from '../components/Toast';

type Props = {
  extruded: boolean;
  visible: boolean;
};

export const useWowLayer = (props: Props) => {
  const isHome = useMatch({ path: '/' });
  const isWowcast = useMatch({ path: '/wowcast' });
  const match = isHome || isWowcast;
  const [selectedPost] = useRecoilState(selectedFeatureAtom);
  const primaryKey = useRecoilValue(primaryKeyForSection('wow-post'));
  const wowPerimeters = useRecoilValueLoadable(
    wowChunksSelector({ type: 'perimeter', visible: match != null })
  );

  const wowPost = useRecoilValueLoadable(
    wowChunksSelector({ type: 'post', visible: match != null })
  );

  const handlePostFillColor = React.useMemo(
    () => (primaryKey === 'priority_rank' ? getPostFillColor : undefined),
    [primaryKey]
  );

  const handleFilterValue = React.useCallback(() => 0, []);

  const WowGridCellLayer = useGridCellLayer<
    Feature<Polygon, WowPostProperties>
  >({
    id: 'wow-post',
    // @ts-ignore
    data: wowPost.state === 'hasValue' ? wowPost.contents : [],
    getFillColor: handlePostFillColor,
    extruded: props.extruded,
    visible: props.visible && wowPost.state === 'hasValue',
    highlightId: selectedPost?.id,
    // onClick: handleClick,
  });

  const handleFillColor = React.useCallback(
    (d: Feature<Polygon, WowProperties>): RGBAColor => [
      ...wowColorMap?.[d.properties.CategoryID],
      100,
    ],
    []
  );
  const handleLineColor = React.useCallback(
    (d: {
      id?: string | number;
      properties: { CategoryID: CategoryID };
    }): RGBAColor => {
      if (d.id === selectedPost?.id) {
        return [255, 255, 255, 255];
      }

      return [...wowColorMap[d.properties.CategoryID], 255];
    },
    [selectedPost?.id]
  );

  const WowPerimeterLayer = useGridCellLayer<Feature<Polygon, WowProperties>>({
    id: 'wow-perimeter',
    // @ts-ignore
    data: wowPerimeters.state === 'hasValue' ? wowPerimeters.contents : [],
    pickable: true,
    getFilterValue: handleFilterValue,
    getFillColor: handleFillColor,
    getLineColor: d => handleLineColor({ id: d.id, properties: d.properties }),
    // @ts-ignore
    extruded: false,

    highlightId: selectedPost?.id,
    visible: props.visible && wowPerimeters.state === 'hasValue',
  });

  const layers = React.useMemo(
    () => [WowPerimeterLayer, WowGridCellLayer],
    [WowPerimeterLayer, WowGridCellLayer]
  );

  return layers;
};

export const wowColorMap: Record<CategoryID, RGBColor> = {
  earthquake1: [139, 69, 19],
  rain1: [127, 23, 14],
  rain2: [1, 255, 0],
  rain3: [74, 137, 92],
  tornado1: [250, 1, 2],
  tornado2: [255, 254, 0],
  tornado3: [255, 69, 0],
  wildfire1: [181, 9, 9],
  wildfire2: [255, 221, 176],
  wildfire3: [253, 68, 3],
  winter1: [123, 104, 238],
  winter2: [74, 212, 240],
};

const postColorRange: RGBAColor[] = [
  [181, 9, 9, 190], // #B50909
  [219, 129, 0, 190], // #DB8100
  [226, 178, 0, 190], // #E2B200
  [143, 195, 201, 190], // #8FC3C9
  [95, 133, 188, 190], // #5F85BC
];

export const getPostFillColor = (
  d: Feature<Polygon, WowPostProperties>
): RGBAColor => {
  const { priority_rank } = d.properties;
  switch (priority_rank) {
    case 1:
      return postColorRange[0];
    case 2:
      return postColorRange[1];
    case 3:
      return postColorRange[2];
    case 4:
      return postColorRange[3];
    case 5:
      return postColorRange[4];
    default:
      return [0, 0, 0, 0];
  }
};

type WowRecord = Record<
  CategoryID,
  {
    pastCount: number;
    futureCount: number;
    category: string;
  }
>;

export const WowLegend = React.memo(() => {
  const wowPerimeters = useRecoilValueLoadable(
    wowChunksSelector({ type: 'perimeter', visible: true })
  );

  const eventCount = React.useMemo(() => {
    if (
      wowPerimeters.state === 'hasError' ||
      wowPerimeters.state === 'loading'
    ) {
      return null;
    }

    return wowPerimeters.contents.reduce<Partial<WowRecord>>((acc, chunk) => {
      const chunkEvents = chunk.features.reduce<Partial<WowRecord>>(
        (chunkAcc, event) => {
          const { CategoryID, Category, time_category } = event.properties;
          if (CategoryID in acc) {
            const chunk = chunkAcc[CategoryID];
            if (chunk != null) {
              if (time_category === 'future') {
                chunk.futureCount++;
              }
              if (time_category === 'past') {
                chunk.pastCount++;
              }
            }
          } else {
            if (time_category === 'future') {
              chunkAcc[CategoryID] = {
                futureCount: 1,
                pastCount: 0,
                category: Category,
              };
            }
            if (time_category === 'past') {
              chunkAcc[CategoryID] = {
                futureCount: 0,
                pastCount: 1,
                category: Category,
              };
            }
          }
          return acc;
        },
        acc
      );

      return chunkEvents;
    }, {});
  }, [wowPerimeters]);

  if (eventCount == null) {
    return null;
  }

  const events = Object.keys(eventCount).sort();

  const forecastedEvents = events.filter(
    event => (eventCount[event as CategoryID]?.futureCount ?? 0) > 0
  );

  const observationEvents = events.filter(
    event => (eventCount[event as CategoryID]?.pastCount ?? 0) > 0
  );

  return (
    <>
      {forecastedEvents.length > 0 && (
        <Box
          sx={{
            pb: observationEvents.length > 0 ? 1 : 0,
          }}
        >
          <Legend
            id="wow-perimeter"
            title="WOW Forecast Perimeters"
            disableZoomToExtent
            items={forecastedEvents.map(event => {
              const wowRecord = eventCount[event as CategoryID];
              return {
                label: wowRecord?.category ?? '',
                color: `rgb(${wowColorMap[event as CategoryID]})`,
                value: wowRecord?.futureCount,
              };
            })}
          />
        </Box>
      )}
      <Toast
        open={events.length === 0}
        message="No current WoW (Worst of the Worst) forecasts or incidents."
        severity="success"
        autoHideDuration={10000}
      />
      {observationEvents.length > 0 && (
        <Legend
          id="wow-perimeter"
          title="WOW Observation Perimeters"
          disableZoomToExtent
          items={observationEvents.map(event => {
            const wowRecord = eventCount[event as CategoryID];
            return {
              label: wowRecord?.category ?? '',
              color: `rgb(${wowColorMap[event as CategoryID]})`,
              value: wowRecord?.pastCount,
            };
          })}
        />
      )}
    </>
  );
});
