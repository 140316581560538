/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { AdvisoryStats } from '../home/AdvisoryStats';
import { AlertsList, IncidentRow } from '../Ipaws/IPAWSAlertList';
import { FlyToInterpolator } from '@deck.gl/core';
import { initialViewState, viewStateAtom } from '../map/atoms';
import { Avatar, List, Paper, Tooltip, Typography } from '@mui/material';
import { useIPAWSLayer } from '../Ipaws/IPAWSLayer';
import { useMatch } from 'react-router-dom';
import { usePostLayer } from '../Post/PostLayer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import React from 'react';
import { useNifcLayer } from '../NIFC/NifcLayer';
import { PrimaryLayer } from './MapPage';
import { ReactComponent as NifcLogo } from '../NIFC/nifc-logo.svg';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { configSelector } from '../config/api';
import { IncidentConfig } from '../types';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import HistoryIcon from '@mui/icons-material/History';
import { useWowLayer } from '../wow/WowLayer';
import { visualModeAtom } from '../map/atoms';
import { useCanopyLayer } from '../canopy/CanopyLayer';
import canopyLogo from '../images/canopy-logo.png';
import { useCanopySwathLayer } from '../canopy/CanopySwathLayer';

export const useHomeLayers = (props: { selectedMap: PrimaryLayer }) => {
  const visualMode = useRecoilValue(visualModeAtom);
  const setViewState = useSetRecoilState(viewStateAtom);
  const match = useMatch({ path: '/' });

  const isMatch = match != null;

  React.useEffect(() => {
    if (isMatch) {
      setViewState({
        ...initialViewState,
        maxPitch: 85,
        transitionInterpolator: new FlyToInterpolator({ speed: 3.0 }),
        transitionDuration: 1000,
      });
    }
  }, [isMatch, setViewState]);

  const wowLayer = useWowLayer({
    visible: match != null && props.selectedMap === 'wow',
    extruded: visualMode === '3d',
  });

  const ipawsLayer = useIPAWSLayer({
    visible: match != null && props.selectedMap === 'ipaws',
  });
  const postLayer = usePostLayer({
    visible: match != null && props.selectedMap === 'ipaws',
    extruded: visualMode === '3d',
  });

  const nifcLayer = useNifcLayer({
    visible: match != null && props.selectedMap === 'nifc',
    extruded: visualMode === '3d',
  });

  const canopyLayer = useCanopyLayer({
    visible: match != null && props.selectedMap === 'canopy',
    extruded: visualMode === '3d',
  });

  const canopySwathLayer = useCanopySwathLayer({
    visible: match != null && props.selectedMap === 'canopy',
    extruded: false,
  });

  const layers = React.useMemo(
    () => [
      wowLayer,
      ipawsLayer,
      postLayer,
      nifcLayer,
      canopyLayer,
      canopySwathLayer,
    ],
    [wowLayer, ipawsLayer, postLayer, nifcLayer, canopyLayer, canopySwathLayer]
  );

  return layers;
};

export const HomeStats = React.memo(() => {
  return (
    <Box
      sx={{
        display: 'grid',
        padding: 1,
        top: 0,
        position: 'absolute',
        right: 0,

        '@media screen and (max-height: 768px), screen and (max-width: 768px)':
          {
            gridTemplateColumns: 'repeat(2, 1fr)',
            transform: 'scale(0.8)',
            transformOrigin: 'top right',
          },
      }}
    >
      <AdvisoryStats />
    </Box>
  );
});

export const HomeDrawer = React.memo(
  (props: {
    selectedMap: PrimaryLayer;
    setSelectedMap: React.Dispatch<React.SetStateAction<PrimaryLayer>>;
  }) => {
    const appConfig = useRecoilValue(configSelector);

    const { watches, activations, historical } = appConfig.incidents.reduce<{
      historical: IncidentConfig[];
      watches: IncidentConfig[];
      activations: IncidentConfig[];
    }>(
      (acc, incident: IncidentConfig) => {
        switch (incident.pageType) {
          case 'watch':
            acc.watches.push(incident);
            break;
          case 'nrcc-activation':
            acc.activations.push(incident);
            break;
          case 'historical':
            acc.historical.push(incident);
            break;
          default:
            break;
        }
        return acc;
      },
      { watches: [], activations: [], historical: [] }
    );
    const enableCanopy = true;

    return (
      <Box sx={{ padding: 0 }}>
        <Box sx={{ width: '100%' }}>
          <Accordion defaultExpanded disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ alignItems: 'center', p: '0 8px' }}
            >
              <Box sx={{ p: 1, width: '100%' }}>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <NotificationImportantIcon sx={{ mr: 1 }} />
                    24/7 Monitoring
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '60%',
                    pt: 2,
                  }}
                >
                  <Tooltip title="Worst of the Worst">
                    <Paper
                      sx={{ borderRadius: '50%' }}
                      elevation={props.selectedMap === 'wow' ? 3 : 0}
                    >
                      <Avatar
                        alt="WOW"
                        sx={{
                          border: `1px solid rgb(0, 62, 103)`,
                          backgroundColor: 'rgb(255, 255, 255)',
                        }}
                        imgProps={{ sx: { height: 'auto' } }}
                        onClick={e => {
                          e.stopPropagation();
                          props.setSelectedMap('wow');
                        }}
                      >
                        <Typography variant="h6">WOW</Typography>
                      </Avatar>
                    </Paper>
                  </Tooltip>
                  <Tooltip title="National Interagency Fire Center">
                    <Paper
                      sx={{ borderRadius: '50%' }}
                      elevation={props.selectedMap === 'nifc' ? 3 : 0}
                    >
                      <Avatar
                        alt="NIFC"
                        sx={{
                          border: `1px solid rgb(0, 62, 103)`,
                        }}
                        onClick={e => {
                          e.stopPropagation();
                          props.setSelectedMap('nifc');
                        }}
                      >
                        <NifcLogo width={40} height={40} />
                      </Avatar>
                    </Paper>
                  </Tooltip>
                  {enableCanopy && (
                    <Tooltip title="Canopy Tornado Alerts">
                      <Paper
                        sx={{ borderRadius: '50%' }}
                        elevation={props.selectedMap === 'canopy' ? 3 : 0}
                      >
                        <Avatar
                          alt="Canopy"
                          src={canopyLogo}
                          sx={{ border: '1px solid rgb(0, 62, 103)' }}
                          imgProps={{ sx: { height: 'auto' } }}
                          onClick={e => {
                            e.stopPropagation();
                            props.setSelectedMap('canopy');
                          }}
                        />
                      </Paper>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <Accordion variant="outlined" disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ alignItems: 'center' }}
                >
                  <Typography variant="h6">WOW (Worst of the Worst)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AlertsList
                    selectedMap="wow"
                    setSelectedMap={() => props.setSelectedMap('wow')}
                  />
                </AccordionDetails>
              </Accordion>

              <Accordion variant="outlined" disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ alignItems: 'center' }}
                >
                  <Typography variant="h6">NIFC Events</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AlertsList
                    selectedMap="nifc"
                    setSelectedMap={() => props.setSelectedMap('nifc')}
                  />
                </AccordionDetails>
              </Accordion>
              {enableCanopy && (
                <Accordion variant="outlined" disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ alignItems: 'center' }}
                  >
                    <Typography variant="h6">Canopy</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AlertsList
                      selectedMap="canopy"
                      setSelectedMap={() => props.setSelectedMap('canopy')}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ alignItems: 'center', p: '0 8px' }}
            >
              <Box sx={{ p: 1 }}>
                <Typography variant="h5">Active Incidents</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Accordion variant="outlined" disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">NRCC Activations</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {activations.map(
                      (incident: IncidentConfig, index: number) => (
                        <IncidentRow
                          key={index}
                          event={incident.name}
                          eventType=""
                          incidentId={incident.id}
                        />
                      )
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion disableGutters variant="outlined">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">
                    {watches.length || ''} Other Incidents
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {watches.map((incident: IncidentConfig, index: number) => (
                      <IncidentRow
                        key={index}
                        event={incident.name}
                        eventType={incident.description}
                        incidentId={incident.id}
                      />
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ alignItems: 'center', p: '0 8px' }}
            >
              <Box sx={{ p: 1 }}>
                <Typography variant="h5">
                  <HistoryIcon sx={{ mr: 1 }} /> Historical Incidents
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {historical.map((incident: IncidentConfig, index: number) => (
                  <IncidentRow
                    key={index}
                    event={incident.name}
                    eventType={incident.description}
                    incidentId={incident.id}
                  />
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return prevProps?.selectedMap === nextProps?.selectedMap;
  }
);
