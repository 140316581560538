/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { Download, Settings } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Popover,
  IconButton,
  MenuItem,
} from '@mui/material';
import { featureCollection } from '@turf/turf';
import { ChartOptions } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { ipawsColorMap } from '../Ipaws/IPAWSLayer';
import { layerVisibility } from '../map/atoms';
import { defaultSectionKey, postColorRange } from '../Post/PostLayer';
import stateNameToAbbreviation from '../utils/stateNameToAbbreviation';
import {
  selectedRunTimeAtom,
  incidentChunksSelector,
  incidentSectionStats,
  getOutputStatisticsBySectionId,
  countyChartDataAtom,
} from './atoms';
import { filterByCountyAtom, filterByStateAtom } from './ExposureStats';
import { postKeyToLabel } from './FeaturePopup';

export const barOptions: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  layout: {
    padding: {
      left: 8,
      right: 8,
    },
  },
  font: {
    family:
      '"ITC Franklin Gothic", "Source Sans Pro", Open Sans, Arial, Helvetica, sans-serif',
  },
  plugins: {
    legend: {
      labels: {
        font: {
          family:
            '"ITC Franklin Gothic", "Source Sans Pro", Open Sans, Arial, Helvetica, sans-serif',
        },
      },
      position: 'bottom' as const,
    },
    tooltip: {
      callbacks: {
        beforeTitle: context => {
          const { dataset, dataIndex } = context[0];
          // @ts-ignore - this is a custom property
          return dataset.data?.[dataIndex]?.comments;
        },
      },
    },

    title: {
      display: true,
      position: 'bottom' as const,
      font: {
        family:
          '"ITC Franklin Gothic", "Source Sans Pro", Open Sans, Arial, Helvetica, sans-serif',
      },
    },
  },
};

type ChartProps = {
  yAttribute: string;
  bounds?: { xmin: number; ymin: number; xmax: number; ymax: number };
  context?: string;
};

export const IncidentCountyChart = React.memo((props: ChartProps) => {
  const { yAttribute } = props;
  const [chartHeight, setChartHeight] = React.useState(
    window.innerHeight / 3.8
  ); // Default to half of the viewport height

  React.useEffect(() => {
    if (props.context === 'wowcast') {
      return;
    }
    const handleResize = () => {
      console.log('resizing', window.innerHeight);
      setChartHeight(window.innerHeight / 3.8); // Adjust this calculation as needed
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.context]);

  const { incidentId, sectionId } = useParams();
  const [stacked, setStacked] = React.useState(false);
  const [hightlightCounties, setHighlightCounties] = useRecoilState(
    layerVisibility('county-highlights')
  );
  const [showTop10 = true, setShowTop10] = React.useState<boolean>(true);
  const [chartDataType, setChartDataType] = React.useState<'percent' | 'sum'>(
    'sum'
  );
  const filterByCounty = useRecoilValue(filterByCountyAtom);
  const filterByState = useRecoilValue(filterByStateAtom);

  const setCountyCartData = useSetRecoilState(
    countyChartDataAtom({ incidentId, sectionId })
  );
  React.useEffect(() => {
    if (sectionId !== 'population') {
      setChartDataType('sum');
    }

    if (
      stacked &&
      ['declarations', 'foodWaterShelter', 'communications'].includes(
        sectionId ?? ''
      )
    ) {
      setStacked(false);
    }
  }, [sectionId, stacked]);

  const selectedRuntime = useRecoilValue(
    selectedRunTimeAtom(`${incidentId}-${sectionId}`)
  );
  const data = useRecoilValue(
    incidentChunksSelector({
      incidentId: 'county',
      sectionId: 'county',
      visible: true,
    })
  );

  const countyLayerData = React.useMemo(() => {
    const array = data.flatMap(a => a.data);
    return featureCollection(array.flatMap(f => f.features));
  }, [data]);

  const handleGroupByFieldForStatistics = React.useCallback(
    (viewByRank: boolean) => {
      if (sectionId === 'foodWaterShelter') {
        return 'counties_name,lastupdatedon';
      }
      if (sectionId === 'communications' || sectionId === 'declarations') {
        return 'name,lastupdatedon';
      }

      if (sectionId === 'commodities') {
        return 'county,lastupdatedon';
      }

      if (sectionId && viewByRank) {
        return defaultSectionKey(sectionId) + ',county,lastupdatedon';
      }

      if (sectionId) {
        return 'county,lastupdatedon';
      }

      return 'lastupdatedon';
    },
    [sectionId]
  );

  const sectionStatsByCounty = useRecoilValueLoadable(
    incidentSectionStats({
      incidentId: incidentId,
      sectionId: sectionId,
      geometry: props.bounds,
      groupByFieldsForStatistics: handleGroupByFieldForStatistics(stacked),
    })
  );

  const getImpactedCountiesData = React.useCallback(
    (counties: string[]) => {
      if (sectionStatsByCounty.state === 'hasValue') {
        return counties
          .map(countyName =>
            countyLayerData.features.find(
              county => county?.properties?.county_name === countyName
            )
          )
          .filter(Boolean);
      }
      return [];
    },
    [sectionStatsByCounty, countyLayerData]
  );

  const defaultLabel = `${
    postKeyToLabel?.[sectionId ?? 'population']?.[yAttribute]
  }`;

  const label: string =
    chartDataType === 'percent'
      ? `Percent of the ${defaultLabel}`
      : defaultLabel;

  const countyData = React.useMemo(() => {
    if (
      sectionStatsByCounty.contents === null ||
      sectionStatsByCounty.state === 'hasError' ||
      sectionStatsByCounty.state === 'loading'
    ) {
      return {
        labels: [],
        datasets: [],
        comments: [],
      };
    }
    const features = sectionStatsByCounty.contents.features;

    if (features.length === 0) {
      return {
        labels: [],
        datasets: [],
        comments: [],
      };
    }

    const getCountyName = (f: { attributes: { [key: string]: any } }) => {
      const countyName =
        f.attributes?.county ??
        f.attributes?.counties_name ??
        f.attributes?.name ??
        f.attributes?.NAME;

      return countyName as string;
    };

    const outputStats = getOutputStatisticsBySectionId(
      sectionId ?? 'population'
    );
    const dataIndex = outputStats
      .map(f => f.outStatisticFieldName)
      .indexOf(yAttribute);

    const currentOutputStat = outputStats[dataIndex];
    const runtimeData = features.filter(f => {
      if (
        (filterByCounty != null && filterByCounty.length > 0) ||
        (filterByState != null && filterByState.length > 0)
      ) {
        return (
          f.attributes.lastupdatedon === selectedRuntime?.time &&
          yAttribute != null &&
          f.attributes[yAttribute] != null &&
          (filterByCounty.includes(getCountyName(f)) ||
            filterByState.some(state => getCountyName(f)?.match(state)))
        );
      }
      return (
        f.attributes.lastupdatedon === selectedRuntime?.time &&
        yAttribute != null &&
        f.attributes[yAttribute] != null
      );
    });

    const allLabels: string[] = Array.from(
      new Set(runtimeData.map(f => getCountyName(f)))
    );

    const impactedCountyData =
      currentOutputStat?.countyFieldName == null
        ? {}
        : [...getImpactedCountiesData(allLabels)].reduce((acc, county) => {
            const countyName: string =
              county?.properties?.county_name ?? county?.properties?.county;

            if (countyName != null) {
              acc[countyName] = {
                x: countyName,
                y: county?.properties?.[
                  currentOutputStat?.countyFieldName ?? ''
                ],
              };
            }
            return acc;
          }, {} as Record<string, { x: string; y: number }>);

    const handleDataSort = (data: typeof runtimeData) => {
      return [...data].sort((a, b) => {
        const bAttribute = b.attributes[yAttribute];
        const aAttribute = a.attributes[yAttribute];
        if (typeof bAttribute === 'number' && typeof aAttribute === 'number') {
          if (chartDataType === 'percent') {
            const countyNameB = getCountyName(b);
            const countyNameA = getCountyName(a);

            return (
              (bAttribute / impactedCountyData?.[countyNameB]?.y ?? 1) * 100 -
              (aAttribute / impactedCountyData?.[countyNameA]?.y ?? 1) * 100
            );
          }

          return bAttribute - aAttribute;
        }
        return 0;
      });
    };

    const sortedLabels: string[] = Array.from(
      new Set(handleDataSort(runtimeData).map(f => getCountyName(f)))
    );

    const labels = showTop10 ? sortedLabels.slice(0, 10) : sortedLabels;

    const filteredLabels = labels.filter(label => {
      if (
        (filterByCounty == null || filterByCounty.length === 0) &&
        (filterByState == null || filterByState.length === 0)
      ) {
        return true;
      }

      return (
        filterByCounty.includes(label) ||
        filterByState.some(state => label.match(state) != null)
      );
    });

    const sortedData = handleDataSort(runtimeData);
    const splicedData = showTop10 ? sortedData.slice(0, 10) : sortedData;

    const dataset = [
      {
        label,
        key: yAttribute,
        borderColor: `rgb(${Object.values(ipawsColorMap)[dataIndex]})`,
        backgroundColor: `rgba(${
          Object.values(ipawsColorMap)[dataIndex]
        }, 0.5)`,
        data: splicedData.map((f, index) => {
          const countyName = getCountyName(f);

          return {
            ...f.attributes,
            x: countyName,
            y:
              chartDataType === 'percent'
                ? ((f.attributes[yAttribute] as number) /
                    impactedCountyData?.[countyName]?.y ?? 1) * 100
                : f.attributes[yAttribute],
          };
        }),
      },
    ];

    const stackedDataset = [1, 2, 3, 4, 5].map((score, i) => {
      if (yAttribute == null) {
        return {
          yAxisGroup: 'A',
          label: `Score ${score}`,
          key: yAttribute,
          data: [],
          borderColor: `rgb(${postColorRange[0].slice(0, 3)})`,
          backgroundColor: `rgba(${postColorRange[0].slice(0, 3)}, 0.5)`,
        };
      }
      const data = sortedData
        .filter(f => {
          return (
            f.attributes[defaultSectionKey(sectionId ?? 'population')] ===
              score &&
            f.attributes[yAttribute] != null &&
            f.attributes[yAttribute] > 1 &&
            filteredLabels.includes(getCountyName(f))
          );
        })
        .map((f, index) => {
          const countyName = getCountyName(f);

          return {
            ...f.attributes,
            y:
              chartDataType === 'percent'
                ? ((f.attributes[yAttribute] as number) /
                    impactedCountyData?.[countyName]?.y ?? 1) * 100
                : f.attributes[yAttribute],
            x: countyName,
          };
        });

      return {
        yAxisGroup: 'A',
        key: yAttribute,
        label: `Rank ${score} Cells`,
        data,

        borderColor: `rgb(${postColorRange[i].slice(0, 3)})`,
        backgroundColor: `rgba(${postColorRange[i].slice(0, 3)}, 0.5)`,
      };
    });

    const nonStackedDatasets = [
      'commodities',
      'communications',
      'foodWaterShelter',
      'declarations',
    ];

    const datasetConditional = nonStackedDatasets.includes(
      sectionId ?? 'population'
    )
      ? dataset
      : stacked
      ? stackedDataset
      : dataset;

    const datasets = datasetConditional;

    return {
      labels: filteredLabels.length ? filteredLabels : labels,
      comments: handleDataSort(runtimeData).map(f => f.attributes.comments),
      datasets,
    };
  }, [
    filterByState,
    filterByCounty,
    label,
    getImpactedCountiesData,
    sectionStatsByCounty,
    sectionId,
    selectedRuntime?.time,
    yAttribute,
    stacked,
    chartDataType,
    showTop10,
  ]);

  const ChartSettings = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <>
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          data-print="hidden"
        >
          <Settings />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'rgb(245, 250, 252)',
              maxWidth: 300,
              p: 0.75,
              '@media print': {
                display: 'none',
              },
            }}
          >
            <FormControl sx={{ pl: 4 }}>
              <FormLabel id="radio-buttons-group-label">View By</FormLabel>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                value={chartDataType}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (typeof e.target.value === 'string') {
                    setChartDataType(e.target.value as 'percent' | 'sum');
                  }
                }}
              >
                <FormControlLabel
                  value="percent"
                  control={<Radio />}
                  label="Percent"
                  disabled={sectionId !== 'population'}
                />
                <FormControlLabel value="sum" control={<Radio />} label="Sum" />
              </RadioGroup>
            </FormControl>
            <FormControl sx={{ pl: 4 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stacked}
                    onChange={e => setStacked(e.target.checked)}
                  />
                }
                disabled={[
                  'foodWaterShelter',
                  'declarations',
                  'communications',
                ].includes(sectionId ?? '')}
                label="View by POST priority rank"
              />
            </FormControl>
            <FormControl sx={{ pl: 4 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showTop10}
                    onChange={e => setShowTop10(e.target.checked)}
                  />
                }
                label="Show Top 10"
              />
            </FormControl>
            <FormControl sx={{ pl: 4 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hightlightCounties}
                    onChange={e => setHighlightCounties(e.target.checked)}
                  />
                }
                label="Hightlight Counties on Map"
              />
            </FormControl>
          </Box>
        </Popover>
      </>
    );
  };

  React.useEffect(() => {
    if (countyData) {
      setCountyCartData({
        yAttribute,
        chartDataType,
        labels: countyData.labels,
        data: [...(countyData.datasets?.[0]?.data ?? [])],
      });
    }
  }, [countyData, setCountyCartData, yAttribute, chartDataType]);

  const imageRef = React.useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleDownload = React.useCallback(() => {
    const el = imageRef.current;
    if (el != null) {
      import('html2canvas').then(({ default: html2canvas }) => {
        html2canvas(el, {
          scrollX: 0,
          scrollY: -window.scrollY,
          useCORS: true,
          allowTaint: true,
          backgroundColor: 'white',
          ignoreElements: el => {
            if (el.getAttribute('data-print') === 'hidden') {
              return true;
            }
            return false;
          },
        }).then(canvas => {
          const a = document.createElement('a');
          a.href = canvas.toDataURL('image/png');
          a.download = 'chart.png';
          a.click();
        });
      });
    }
  }, []);

  const handleCsvDownload = React.useCallback(() => {
    const outputStats = getOutputStatisticsBySectionId(
      sectionId ?? 'population'
    );

    const data = countyData?.datasets?.[0]?.data?.map(d => {
      const row = d as any;
      const { lastupdatedon, county } = row;
      return [
        [
          new Date(lastupdatedon).toString(),
          county,
          ...outputStats.map(v => v.onStatisticField).map(v => row[v]),
        ],
      ];
    });

    const labelsObject = postKeyToLabel?.[sectionId ?? 'population'] ?? {};

    const headRow = [
      'Model Time',
      'County',
      'State',
      ...outputStats.map(v => labelsObject[v.onStatisticField]),
    ];

    const rows = [headRow, ...data];
    let csvContent =
      'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');

    const a = document.createElement('a');
    a.href = encodeURI(csvContent);
    a.download = `${defaultLabel} exposed by county.csv`;
    a.click();
  }, [countyData, defaultLabel, sectionId]);

  const open = Boolean(anchorEl);
  const id = open ? 'county-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (sectionStatsByCounty.state === 'loading') {
    return (
      <Box
        sx={{
          height: props.context === 'wowcast' ? chartHeight : 420,
          mb: 4,
          p: 2,

          '@media print': {
            display: 'none',
          },
        }}
      >
        <Typography
          variant="h5"
          color={props.context === 'wowcast' ? 'white' : ''}
          sx={{ mb: 2 }}
        >
          {`${showTop10 ? 'Top 10' : ''} ${label}`}
        </Typography>
        <Box
          sx={{
            backgroundColor: 'white',
            p: 2,
            position: 'relative',
            minHeight: props.context === 'wowcast' ? chartHeight : 420,
            mb: 4,
            pt: 4,
            width: '100%',
          }}
        >
          <CircularProgress
            sx={{
              position: 'absolute',
              left: 'calc(50% - 20px)',
              top: 'calc(50% - 20px)',
              zIndex: 3,
            }}
            color="primary"
          />
          <Bar
            options={barOptions}
            data={{
              labels: [],
              datasets: [],
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      key={chartHeight}
      ref={imageRef}
      sx={{
        minHeight: props.context === 'wowcast' ? chartHeight : 420,
        mt: 2,
        p: 1,

        '@media print': {
          minWidth: '1100px',
          pageBreakInside: 'avoid',
        },
      }}
    >
      <Typography
        color={props.context === 'wowcast' ? 'white' : ''}
        variant="h5"
        sx={{
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {`${showTop10 ? 'Top 10' : ''} ${label} exposed by county`}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pl: 1,
          }}
        >
          <Box>
            <ChartSettings />
            <IconButton data-print="hidden" onClick={handleClick}>
              <Download />
            </IconButton>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={handleDownload}>Download as PNG</MenuItem>
            <MenuItem onClick={handleCsvDownload}>Download as CSV</MenuItem>
          </Popover>

          {/* <IconButton onClick={handleDownload} data-print="hidden">
            <Download />
          </IconButton>
          <ChartSettings /> */}
        </Box>
      </Typography>

      <Box
        sx={{
          minHeight: props.context === 'wowcast' ? chartHeight : 420,
          mb: 4,
          pt: 4,
          '@media print': {
            minWidth: '900px',
          },
        }}
      >
        <Bar
          options={{
            ...barOptions,
            scales: {
              ...barOptions.scales,
              x: {
                title: {
                  ...barOptions.scales?.x?.title,
                },
                ticks: {
                  callback: function (val, index) {
                    return formatCountyStateName(
                      this.getLabelForValue(val as number)
                    );
                  },
                },
              },
              y: {
                ...(barOptions.scales?.y ?? {}),
                title: {
                  display: true,
                  text: generateCountyChartTitle(
                    sectionId,
                    yAttribute,
                    chartDataType
                  ),
                },
              },
            },
          }}
          data={{
            ...countyData,
            datasets: countyData?.datasets?.filter(
              (d: any) => d.key === yAttribute
            ),
          }}
        />
      </Box>
    </Box>
  );
});

export function generateCountyChartTitle(
  sectionId?: string,
  yAttribute?: string,
  chartDataType?: 'percent' | 'sum'
) {
  return `${chartDataType === 'percent' ? 'Percent of the ' : ''}${
    postKeyToLabel[sectionId ?? 'population']?.[yAttribute ?? ''] ??
    yAttribute ??
    ''
  } exposed`;
}

function formatCountyStateName(countyStateName: string) {
  if (countyStateName == null) {
    return '';
  }

  const countyName = countyStateName?.split(', ')?.[0] ?? '';
  const state = stateNameToAbbreviation(
    countyStateName?.split(', ')?.[1]?.toLowerCase() ?? ''
  );
  return `${countyName}, ${state}`;
}
