/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import 'chartjs-adapter-date-fns';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { getOutputStatisticsBySectionId } from './atoms';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  TimeScale,
} from 'chart.js';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { WebMercatorViewport } from 'deck.gl';
import { postKeyToLabel } from './FeaturePopup';
import { debouncedViewStateAtom } from '../map/atoms';
import { filterByViewAtom } from './ExposureStats';
import { IncidentCountyChart } from './IncidentCountyChart';
import { IncidentTimeSeriesChart } from './IncidentTimeSeriesChart';
import { SelectChangeEvent } from '@mui/material/Select';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const IncidentChart = ({ context }: { context?: string }) => {
  const { incidentId, sectionId, attributeId } = useParams();
  const navigate = useNavigate();

  const attribute =
    attributeId ??
    getOutputStatisticsBySectionId(sectionId ?? 'population')[0]
      ?.outStatisticFieldName ??
    null;

  const filterByView = useRecoilValue(filterByViewAtom);
  const viewState = useRecoilValue(debouncedViewStateAtom);
  const defaultDataSet =
    getOutputStatisticsBySectionId(sectionId ?? 'population')?.find(
      s => s.outStatisticFieldName === attribute
    )?.outStatisticFieldName ?? null;

  const [selectedDataSet, setSelectedDataSet] = React.useState<string | null>(
    defaultDataSet
  );

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      setSelectedDataSet(event.target.value as string);
      navigate(`/${incidentId}/${sectionId}/detail/${event.target.value}`); // replace with your desired URL or parameters
    },
    [navigate, incidentId, sectionId]
  );

  const viewStateEnvelope = React.useMemo(() => {
    if (!filterByView) {
      return undefined;
    }

    // @ts-ignore
    const bbox = new WebMercatorViewport(viewState)?.getBounds();

    return {
      xmin: bbox[0],
      ymin: bbox[1],
      xmax: bbox[2],
      ymax: bbox[3],
    };
  }, [viewState, filterByView]);

  React.useEffect(() => {
    setSelectedDataSet(defaultDataSet);
  }, [defaultDataSet]);

  return (
    <React.Suspense fallback={null}>
      <Box
        sx={{
          backgroundColor:
            context === 'wowcast' ? 'rgba(0, 62, 103, 0.2)' : 'white',
          backdropFilter: 'blur(10px)',
          borderRadius: '4px',
          p: 2,
          position: 'relative',
          minHeight: 400,
          height: '100%',
          width: '100%',
          maxWidth: '1200px',

          // '@media print': {
          //   width: '1020px',
          // },
        }}
      >
        {context !== 'wowcast' && selectedDataSet != null && (
          <FormControl
            sx={{
              m: 1,
              width: 300,
              '@media print': {
                display: 'none',
              },
            }}
          >
            <InputLabel size="small" id="select-attribute-label">
              Select Attribute
            </InputLabel>
            <Select
              labelId="select-attribute-label"
              size="small"
              label="Select  Attribute"
              input={<OutlinedInput label="Select Attribute" />}
              value={selectedDataSet}
              onChange={handleChange}
            >
              {getOutputStatisticsBySectionId(sectionId ?? 'population').map(
                d => (
                  <MenuItem
                    key={d.outStatisticFieldName}
                    value={d.outStatisticFieldName}
                  >
                    {postKeyToLabel?.[sectionId ?? 'population']?.[
                      d.outStatisticFieldName ?? ''
                    ] ?? d.outStatisticFieldName}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        )}

        {selectedDataSet != null && (
          <React.Suspense fallback={null}>
            <IncidentCountyChart
              context={context}
              yAttribute={selectedDataSet}
              bounds={viewStateEnvelope}
            />
            <IncidentTimeSeriesChart
              context={context}
              yAttribute={selectedDataSet}
              bounds={viewStateEnvelope}
            />
          </React.Suspense>
        )}
      </Box>
    </React.Suspense>
  );
};
