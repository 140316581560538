/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const api = process.env.REACT_APP_TEMPO_SERVER_URL;

const UnsubscribeConfirmPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const confirmSubscription = async () => {
      try {
        const response = await fetch(
          `${api}/subscription/unsubscribe/confirm/${id}`
        );
        if (response.ok) {
          setConfirmed(true);
        } else if (response.status === 410) {
          setExpired(true);
        }
      } catch (error) {
        console.error('Error confirming subscription:', error);
      } finally {
        setLoading(false);
      }
    };

    confirmSubscription();
  }, [id]);

  return (
    <Container maxWidth="sm">
      <Box textAlign="center" py={4}>
        {loading ? (
          <CircularProgress />
        ) : expired ? (
          <>
            <Typography variant="h4" gutterBottom>
              Link Expired
            </Typography>
            <Typography>
              Sorry, this link has expired. Please request a new confirmation
              link.
            </Typography>
          </>
        ) : confirmed ? (
          <>
            <Typography variant="h4" gutterBottom>
              Unsubscribe Confirmed
            </Typography>
            <Typography>You've been removed from TEMPO alerts.</Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>
              Confirmation Failed
            </Typography>
            <Typography>
              We could not confirm your email address. Please try again later.
            </Typography>
          </>
        )}
      </Box>
    </Container>
  );
};

export default UnsubscribeConfirmPage;
