/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { FeatureCollection, Polygon } from '@turf/turf';
import { atom, selectorFamily, useRecoilCallback } from 'recoil';
import { esriParamsObject } from '../esri';
import { IPAWSProperties } from './types';
// @ts-ignore
import arcgisPbfDecode from 'arcgis-pbf-parser';

const IPAWS_FEATURE_SERVER =
  'https://services.arcgis.com/XG15cJAlne2vxtgt/ArcGIS/rest/services/IPAWS/FeatureServer/0/query';

const eventTypes = [
  'Flood Warning',
  'Flash Flood Warning',
  'High Wind Warning',
  'Red Flag Warning',
  'Severe Thunderstorm Warning',
  'Storm Warning',
  'Tornado Warning',
  'Tropical Storm Warning',
  'Winter Storm Warning',
  'Hurricane Warning',
];

const severityLevels = ['Extreme', 'Severe'];
const paramsObject = {
  ...esriParamsObject,
  where: `STATUS IN ('Active') AND EVENT IN ('${eventTypes.join(
    "','"
  )}') and SEVERITY IN ('${severityLevels.join("','")}')`,
};

export async function asyncGetIpawsData(offset = 0): Promise<
  FeatureCollection<Polygon, IPAWSProperties> & {
    properties: { exceededTransferLimit: boolean };
  }
> {
  const limit = 2000;
  let searchParams = new URLSearchParams(paramsObject);

  const response = await fetch(
    `${IPAWS_FEATURE_SERVER}?${searchParams.toString()}&resultRecordCount=${limit}&resultOffset=${offset}`
  );
  const buffer = await response.arrayBuffer();

  const data = arcgisPbfDecode(new Uint8Array(buffer));
  return {
    ...data.featureCollection,
    properties: { exceededTransferLimit: data?.exceededTransferLimit ?? false },
  };
}

export const ipawsDataChunksAtom = atom<
  FeatureCollection<Polygon, IPAWSProperties>[]
>({
  key: 'ipawsDataChunks',
  default: [],
});

export const useSetIpawsDataChunksUsers = (onLoadingFinish?: Function) => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        const getChunks = async (offset: number) => {
          const data = await asyncGetIpawsData(offset);
          set(ipawsDataChunksAtom, current => [...current, data]);
          if (data.properties.exceededTransferLimit) {
            getChunks(offset + 2000);
          } else {
            onLoadingFinish?.();
          }
        };

        getChunks(0);
      },
    []
  );
};

export const ipawsChunksSelector = selectorFamily<
  FeatureCollection<Polygon, IPAWSProperties>[],
  boolean
>({
  key: 'ipawsChunksSelector',
  get: (visible: boolean) => async () => {
    let results: any[] = [];

    const getChunks = async (offset: number) => {
      const data = await asyncGetIpawsData(offset);

      if (data.properties?.exceededTransferLimit) {
        await getChunks(offset + 2000);
      }
      results = [...results, data];
    };

    if (visible) {
      await getChunks(0);
    }

    return results;
  },
});
