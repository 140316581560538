/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import {
  SelectChangeEvent,
  Box,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
  Popover,
} from '@mui/material';
import { ChartOptions } from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { configSelector } from '../config/api';
import { ipawsColorMap } from '../Ipaws/IPAWSLayer';
import { incidentSectionStats } from './atoms';
import { postKeyToLabel } from './FeaturePopup';
import { enUS } from 'date-fns/locale';
import { filterByCountyAtom, filterByStateAtom } from './ExposureStats';
import { Download } from '@mui/icons-material';

export const options: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 8,
      right: 8,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
      },
    },
    x: {
      type: 'time',
      adapters: {
        date: {
          locale: enUS,
        },
      },
      time: {
        unit: 'day',
        displayFormats: {
          hour: 'MMM d, ha',
        },
      },
    },
  },
  font: {
    family:
      '"ITC Franklin Gothic", "Source Sans Pro", Open Sans, Arial, Helvetica, sans-serif',
  },
  plugins: {
    legend: {
      labels: {
        font: {
          family:
            '"ITC Franklin Gothic", "Source Sans Pro", Open Sans, Arial, Helvetica, sans-serif',
        },
      },
      position: 'bottom' as const,
    },
    tooltip: {
      callbacks: {
        beforeTitle: context => {
          const { dataset, dataIndex } = context[0];
          // @ts-ignore - this is a custom property
          return dataset.data?.[dataIndex]?.comments;
        },
      },
    },

    title: {
      display: true,
      position: 'bottom' as const,
      text: 'Exposure by Time',
      font: {
        family:
          '"ITC Franklin Gothic", "Source Sans Pro", Open Sans, Arial, Helvetica, sans-serif',
      },
    },
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
type ChartProps = {
  yAttribute: string;
  bounds?: { xmin: number; ymin: number; xmax: number; ymax: number };
  context?: string;
};

export const IncidentTimeSeriesChart = React.memo((props: ChartProps) => {
  const { yAttribute, bounds } = props;
  const { incidentId, sectionId } = useParams();

  const [chartHeight, setChartHeight] = React.useState(window.innerHeight / 4); // Default to half of the viewport height

  React.useEffect(() => {
    if (props.context === 'wowcast') {
      return;
    }
    const handleResize = () => {
      console.log('resizing', window.innerHeight);
      setChartHeight(window.innerHeight / 4); // Adjust this calculation as needed
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.context]);

  const appConfig = useRecoilValue(configSelector);
  const incidentConfig = appConfig.incidents.find(i => i.id === incidentId);
  const [selectedModels, setSelectedModels] = React.useState<string[]>([]);
  const filterByState = useRecoilValue(filterByStateAtom);
  const filterByCounty = useRecoilValue(filterByCountyAtom);

  const disabledModels = React.useMemo(
    () =>
      sectionId
        ? [
            ...(incidentConfig?.globalSettings?.disabledModules ?? []),
            ...(incidentConfig?.sectionSettings?.[sectionId]?.disabledModels ??
              []),
          ]
        : [],
    [sectionId, incidentConfig]
  );

  const sectionStats = useRecoilValueLoadable(
    incidentSectionStats({
      incidentId: incidentId,
      sectionId: sectionId,
      geometry: bounds,
      counties: filterByCounty,
      states: filterByState,
      disabledModels: disabledModels,
    })
  );

  const data = React.useMemo(() => {
    if (sectionStats.contents === null || sectionStats.state === 'loading') {
      return {
        labels: [],
        datasets: [],
      };
    }
    const sortedFeatures = [...sectionStats.contents.features]
      .filter(f => {
        if (disabledModels == null || disabledModels.length === 0) {
          return true;
        }
        return disabledModels.includes(f.comments) === false;
      })
      .sort((a, b) => {
        return a.attributes.lastupdatedon - b.attributes.lastupdatedon;
      });

    if (sortedFeatures.length === 0) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const featureKeys = Object.keys(sortedFeatures[0].attributes).filter(
      f => f !== 'lastupdatedon' && f !== 'comments'
    );
    return {
      labels: sortedFeatures.map(f => f.attributes.lastupdatedon),
      comments: sortedFeatures.map(f => f.attributes.comments),
      datasets: featureKeys.map((k, i) => ({
        yAxisGroup: 'A',
        key: k,
        label: sectionId != null ? postKeyToLabel?.[sectionId]?.[k] ?? k : k,
        data: sortedFeatures.map(f => ({
          ...f.attributes,
          y: f.attributes[k],
          x: f.attributes.lastupdatedon,
        })),

        borderColor: `rgb(${Object.values(ipawsColorMap)[i]})`,
        // borderColor: 'rgb(255, 99, 132)',
        backgroundColor: `rgba(${Object.values(ipawsColorMap)[i]}, 0.5)`,
      })),
    };
  }, [sectionStats, disabledModels, sectionId]);

  React.useEffect(() => {
    if (data.datasets.length > 0) {
      setSelectedModels(data.labels.map(d => d));
    }
  }, [data]);

  const handleFilterChange = React.useCallback(
    (e: SelectChangeEvent<string | string[]>) => {
      const value = e.target.value;

      if (value != null) {
        setSelectedModels(models => {
          if (typeof value === 'string') {
            if (value === 'all') {
              if (models.length === data.labels.length) {
                return [];
              }
              return data.labels;
            }
            if (models.includes(value)) {
              return models.filter(m => m !== e.target.value);
            }
            return [...models, value];
          }

          if (value.includes('all')) {
            if (value.length - 1 === data.labels.length) {
              return [];
            }
            return data.labels;
          }

          return value;
        });
      }
    },
    [data.labels]
  );

  const imageRef = React.useRef<HTMLDivElement>(null);

  const defaultLabel = `${
    postKeyToLabel?.[sectionId ?? 'population']?.[yAttribute]
  }`;

  const handleDownload = React.useCallback(() => {
    const el = imageRef.current;
    if (el != null) {
      import('html2canvas').then(({ default: html2canvas }) => {
        html2canvas(el, {
          scrollX: 0,
          scrollY: -window.scrollY,
          useCORS: true,
          allowTaint: true,
          backgroundColor: 'white',
          ignoreElements: el => {
            if (el.getAttribute('data-print') === 'hidden') {
              return true;
            }
            return false;
          },
        }).then(canvas => {
          const a = document.createElement('a');
          a.href = canvas.toDataURL('image/png');
          a.download = `${defaultLabel} exposed over selected model times.png`;
          a.click();
        });
      });
    }
  }, [defaultLabel]);

  const handleCsvDownload = React.useCallback(() => {
    const el = imageRef.current;

    if (el != null) {
      const csvData = data.datasets
        .filter(d => d.label === defaultLabel)
        .map(d => {
          return {
            model: d.label,
            rows: d.data
              .filter(d => selectedModels.includes(d.x))
              .map(d => {
                return [[d.comments, new Date(d.x).toString(), d.y]];
              }),
          };
        });

      const headRow = ['Model', 'Model Time', csvData[0].model];

      const rows = [headRow, ...csvData[0].rows];
      let csvContent =
        'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');

      const a = document.createElement('a');
      a.href = encodeURI(csvContent);
      a.download = `${defaultLabel} exposed over selected model times.csv`;
      a.click();
    }
  }, [data.datasets, defaultLabel, selectedModels]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (sectionStats.state === 'loading' && sectionId) {
    return (
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '4px',
          p: 2,
          position: 'relative',
          minHeight: props.context === 'wowcast' ? chartHeight : 400,
          height: props.context === 'wowcast' ? chartHeight : 400,
          maxHeight: props.context === 'wowcast' ? chartHeight : 500,
          width: '100%',
        }}
      >
        <CircularProgress
          sx={{
            position: 'absolute',
            left: 'calc(50% - 20px)',
            top: 'calc(50% - 20px)',
            zIndex: 3,
          }}
          color="primary"
        />
        <Line
          options={{
            ...options,
            scales: {
              ...options.scales,
              y: {
                ...options?.scales?.y,
                title: {
                  ...options?.scales?.y?.title,
                  text:
                    postKeyToLabel[sectionId][yAttribute] ?? yAttribute ?? '',
                },
              },
            },
          }}
          data={{
            labels: [],
            datasets: [],
          }}
        />
      </Box>
    );
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      ref={imageRef}
      sx={{
        p: 1,
        pt: 0,
        '@media print': {
          pageBreakInside: 'avoid',
        },
      }}
    >
      <Typography
        color={props.context === 'wowcast' ? 'white' : ''}
        variant="h5"
        sx={{
          mb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {defaultLabel} exposed over selected model times
        <IconButton data-print="hidden" onClick={handleClick}>
          <Download />
        </IconButton>
      </Typography>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleDownload}>Download as PNG</MenuItem>
        <MenuItem onClick={handleCsvDownload}>Download as CSV</MenuItem>
      </Popover>
      <Box
        sx={{
          pt: 4,
          minHeight: props.context === 'wowcast' ? chartHeight : 400,
          maxHeight: props.context === 'wowcast' ? chartHeight : 500,
        }}
      >
        <Line
          options={{
            ...options,
            scales: {
              ...options.scales,
              y: {
                ...options?.scales?.y,
                title: {
                  ...options?.scales?.y?.title,
                  text:
                    postKeyToLabel[sectionId ?? 'population'][yAttribute] ??
                    yAttribute ??
                    '',
                },
              },
            },
          }}
          data={{
            labels: data.labels.filter(f => selectedModels.includes(f)),
            datasets: data.datasets
              .filter(d => d.key === yAttribute)
              .map(d => ({
                ...d,
                data: d.data.filter(f => {
                  return selectedModels.includes(f.x);
                }),
              })),
          }}
        />
      </Box>

      {props.context !== 'wowcast' && (
        <FormControl
          data-print="hidden"
          sx={{
            m: 1,
            maxWidth: 400,
            width: '100%',
            height: chartHeight,
            mt: 4,
            mb: 10,
            ml: 4,
            '@media print': { display: 'none' },
          }}
        >
          <InputLabel id="filter-models-label">Filter Models</InputLabel>
          <Select
            multiple
            input={<OutlinedInput label="Filter Models" />}
            MenuProps={MenuProps}
            placeholder="Filter Models"
            value={selectedModels}
            renderValue={selected => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map(value => (
                  <Chip
                    key={value}
                    label={new Date(value).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  />
                ))}
              </Box>
            )}
            onChange={handleFilterChange}
          >
            <MenuItem dense key="all" value="all">
              <Checkbox
                checked={selectedModels.length === data.labels.length}
              />
              <ListItemText primary="Select All/None" />
            </MenuItem>
            {data.labels.map((d, index) => (
              <MenuItem dense key={d} value={d}>
                <Checkbox checked={selectedModels.indexOf(d) > -1} />
                <ListItemText
                  primary={`${new Date(d).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: '2-digit',
                  })} ${data?.comments?.[index] ?? ''}`}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
});
