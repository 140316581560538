/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React from 'react';
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import { StatsBox } from '../components/StatsBox';
import {
  incidentCountiesSelector,
  selectedRunTimeAtom,
  incidentSectionStats,
} from './atoms';
import { IncidentSection } from '../pages/IncidentPage';
import { debouncedViewStateAtom } from '../map/atoms';
import { WebMercatorViewport } from 'deck.gl';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { _MapContext } from 'react-map-gl';
import { CenterFocusStrong, Clear } from '@mui/icons-material';
import { configSelector } from '../config/api';

// import { currentRegionState } from '../Regions/regionsSelector';
// import { regionState } from '../Regions/FemaRegions';
import {} from './atoms';

type Props = {
  section: IncidentSection;
  incidentId: string;
  viewType: string;
  hideFilters?: boolean;
};

export const filterByViewAtom = atom({
  key: 'filterByView',
  default: false,
});

export const filterByCountyAtom = atom<string[]>({
  key: 'filterByCounty',
  default: [],
});

export const filterByStateAtom = atom<string[]>({
  key: 'filterByState',
  default: [],
});

export const filterByRegionAtom = atom<string[]>({
  key: 'filterByRegion',
  default: [],
});

export const ExposureStats = React.memo(
  ({ section, incidentId, viewType, hideFilters }: Props) => {
    // const currentRegion = useRecoilValue(currentRegionState);
    // const femaRegionsJson = useRecoilValueLoadable(regionState);
    const context = React.useContext(_MapContext);
    const debounceViewState = useRecoilValue(debouncedViewStateAtom);
    // const regionFeature = femaRegionsJson.contents?.features?.find(
    //   (f: any) => f?.properties?.region === currentRegion
    // );

    const [filterByView, setFilterByView] = useRecoilState(filterByViewAtom);
    const [filterByCounty, setFilterByCounty] =
      useRecoilState(filterByCountyAtom);

    const [filterByState] = useRecoilState(filterByStateAtom);
    // const [filterByRegion, setFilterByRegion] =
    //   useRecoilState(filterByRegionAtom);

    const bounds = React.useMemo(() => {
      if (!filterByView) {
        return undefined;
      }

      // @ts-ignore
      const bbox = new WebMercatorViewport(debounceViewState)?.getBounds();

      return {
        xmin: bbox[0],
        ymin: bbox[1],
        xmax: bbox[2],
        ymax: bbox[3],
      };
    }, [debounceViewState, filterByView]);

    const appConfig = useRecoilValue(configSelector);
    const incidentConfig = appConfig.incidents.find(i => i.id === incidentId);

    const disabledModels = React.useMemo(
      () =>
        section
          ? [
              ...(incidentConfig?.globalSettings?.disabledModules ?? []),
              ...(incidentConfig?.sectionSettings?.[section]?.disabledModels ??
                []),
            ]
          : [],
      [section, incidentConfig]
    );

    const sectionStatistics = useRecoilValueLoadable(
      incidentSectionStats({
        sectionId: section,
        incidentId,
        geometry: bounds,
        counties: filterByCounty,
        states: filterByState,
        disabledModels,
      })
    );

    const range =
      useRecoilValue(selectedRunTimeAtom(`${incidentId}-${section}`))?.time ??
      0;

    const currentRangeStatistics = React.useMemo(() => {
      function calculateTrends(data: any) {
        const features = [...data.features];

        if (features.length === 1) {
          return [
            {
              ...features[0].attributes,
              originalValues: features[0].attributes,
            },
          ];
        }

        // Sort features by 'lastupdatedon' in ascending order
        features.sort(
          (a: any, b: any) =>
            a.attributes.lastupdatedon - b.attributes.lastupdatedon
        );

        // Initialize an empty array to store the trends
        let trends = [];

        // Loop through the features
        for (let i = 1; i < features.length; i++) {
          // Calculate the difference between the current and previous feature for each attribute
          let trend: any = {};
          for (let attribute in features[i].attributes) {
            if (attribute !== 'lastupdatedon' && attribute !== 'comments') {
              trend[attribute] =
                features[i].attributes[attribute] -
                features[i - 1].attributes[attribute];
            }
          }
          trend['lastupdatedon'] = features[i].attributes.lastupdatedon;
          trend['originalValues'] = features[i].attributes;
          trend['previousValues'] = features[i - 1].attributes;
          trends.push(trend);
        }

        return trends;
      }

      if (sectionStatistics.state === 'hasValue') {
        return calculateTrends(sectionStatistics.contents);
      }
      return null;
    }, [sectionStatistics]);

    const currentRangeStates = currentRangeStatistics?.find(
      s => s.lastupdatedon === range
    );

    const exposedCounties = useRecoilValueLoadable(
      incidentCountiesSelector({ incidentId, sectionId: section })
    );

    const handleChange = React.useCallback(
      (event: SelectChangeEvent<string | string[]>) => {
        const value = event.target.value as string[];
        setFilterByCounty(value);
      },
      [setFilterByCounty]
    );

    const { originalValues, previousValues } = currentRangeStates ?? {};

    const stats: Record<
      IncidentSection,
      {
        label: string;
        value: string | number;
        field: string;
        prevValue?: string | number;
      }[]
    > = {
      population: [
        {
          label: 'Exposed Population',
          value: Math.round(originalValues?.ls_night_pop ?? 0),
          prevValue: Math.round(previousValues?.ls_night_pop ?? 0),
          field: 'ls_night_pop',
        },
        {
          label: 'Exposed Housing Units',
          value: Math.round(originalValues?.housingunits ?? 0),
          prevValue: Math.round(previousValues?.housingunits ?? 0),
          field: 'housingunits',
        },
        {
          label: 'Exposed Population\n age 65 and Older',
          value: Math.round(originalValues?.pop65over ?? 0),
          prevValue: Math.round(previousValues?.pop65over ?? 0),
          field: 'pop65over',
        },
        {
          label: 'Exposed Households with\n Limited English Proficiency',
          value: Math.round(originalValues?.lep ?? 0),
          prevValue: Math.round(previousValues?.lep ?? 0),
          field: 'lep',
        },
        {
          label: 'Exposed Households with\n Food Stamps / SNAP',
          value: Math.round(originalValues?.householdswithfs ?? 0),
          prevValue: Math.round(previousValues?.householdswithfs ?? 0),
          field: 'householdswithfs',
        },
        {
          label: 'Exposed Households with\n Public Assistance',
          value: Math.round(originalValues?.householdswithpa ?? 0),
          prevValue: Math.round(previousValues?.householdswithpa ?? 0),
          field: 'householdswithpa',
        },
        // { label: 'Injuries Reported \n(To Date)', value: '27' },
        // { label: 'Casualties Reported \n(To Date)', value: '3' },
      ],
      residential: [
        {
          label: 'Exposed Residential Structures',
          value: Math.round(originalValues?.residential ?? 0),
          prevValue: Math.round(previousValues?.residential ?? 0),
          field: 'residential',
        },
      ],
      nonResidentialStructures: [
        {
          label: 'Exposed Agricultural',
          value: Math.round(originalValues?.agriculturalru ?? 0),
          prevValue: Math.round(previousValues?.agriculturalru ?? 0),
          field: 'agriculturalru',
        },
        {
          label: 'Exposed Commercial\n (Offices)',
          value: Math.round(originalValues?.commercialoff ?? 0),
          prevValue: Math.round(previousValues?.commercialoff ?? 0),
          field: 'commercialoff',
        },
        {
          label: 'Exposed Commercial\n (Retail)',
          value: Math.round(originalValues?.commercialret ?? 0),
          prevValue: Math.round(previousValues?.commercialret ?? 0),
          field: 'commercialret',
        },
        {
          label: 'Exposed Industrial',
          value: Math.round(originalValues?.industrialgen ?? 0),
          prevValue: Math.round(previousValues?.industrialgen ?? 0),
          field: 'industrialgen',
        },
        {
          label: 'Exposed Government',
          value: Math.round(originalValues?.exemptgovernm ?? 0),
          prevValue: Math.round(previousValues?.exemptgovernm ?? 0),
          field: 'exemptgovernm',
        },
        {
          label: 'Exposed Recreation',
          value: Math.round(originalValues?.recreational ?? 0),
          prevValue: Math.round(previousValues?.recreational ?? 0),
          field: 'recreational',
        },
        // { label: 'Non-Profit', value: '156,000' },
        // { label: 'Unclassified', value: '156,000' },
        // { label: 'Utility', value: '156,000' },
      ],
      communications: [
        {
          label: 'Cell Towers Out\n Power Outage',
          value: Math.round(originalValues?.cell_sites_out_due_to_power ?? 0),
          prevValue: Math.round(
            previousValues?.cell_sites_out_due_to_power ?? 0
          ),
          field: 'cell_sites_out_due_to_power',
        },
        {
          label: 'Cell Towers Out\n Transportation Outage',
          value: Math.round(
            originalValues?.cell_sites_out_due_to_transpor ?? 0
          ),
          prevValue: Math.round(
            previousValues?.cell_sites_out_due_to_transpor ?? 0
          ),
          field: 'cell_sites_out_due_to_transpor',
        },
        {
          label: 'Cell Towers Out\n Damage',
          value: Math.round(originalValues?.cell_sites_out_due_to_damage ?? 0),
          prevValue: Math.round(
            previousValues?.cell_sites_out_due_to_damage ?? 0
          ),
          field: 'cell_sites_out_due_to_damage',
        },
        {
          label: 'Population Affected\n by Cell Towers Out',
          value: Math.round(originalValues?.populationaffected ?? 0),
          prevValue: Math.round(previousValues?.populationaffected ?? 0),
          field: 'populationaffected',
        },
      ],

      // transportation: [
      // { label: 'Road Closures', value: road_closed },
      // { label: 'Number of Waze Alerts', value: totalnumalerts },
      // { label: 'Road Closures', value: '325,567' },
      // {
      //   label: 'Reported\n Weather Hazards',
      //   value: weatherhazard,
      // },
      // { label: 'Reported Accidents', value: accident },
      // { label: 'Reported\n Traffic Jams', value: jam },
      // ],
      // commerce: [{ label: 'Closed Businesses', value: '592' }],
      // foodWaterShelter: [
      //   { label: 'Open Shelters', value: SheltersOpen },
      //   { label: 'Closed Shelters', value: SheltersClosed },
      //   {
      //     label: 'Sheltered People',
      //     value: SUM_POPULATION_SERVED,
      //   },
      //   {
      //     label: 'Boil Water Advisory (BWA)\n Population',
      //     value: BoilWaterAffectedPopulation,
      //   },
      // ],
      // healthMedical: [
      //   { label: 'Hospitals without Power', value: Hospitals },
      //   {
      //     label: 'Dialysis Centers without Power',
      //     value: Dialysis_Center,
      //   },
      //   {
      //     label: 'Nursing Homes without Power',
      //     value: Nursing_Homes,
      //   },
      //   {
      //     label: 'Urgent Care Facilities without Power',
      //     value: Urgent_Care,
      //   },
      // ],
      declarations: [
        {
          label: 'Total Counties Affected',
          value: originalValues?.is_affected ?? 0,
          prevValue: previousValues?.is_affected ?? 0,
          field: 'is_affected',
        },
        {
          label: 'Total Counties Designated\n for Disaster Assistance',
          value: originalValues?.is_designated ?? 0,
          prevValue: previousValues?.is_designated ?? 0,
          field: 'is_designated',
        },
      ],
    };

    if (stats[section].length === 0) {
      return null;
    }

    const countiesByState = groupCountiesByState(
      exposedCounties.state === 'hasValue'
        ? [...exposedCounties.contents].sort()
        : []
    );

    const handleToggleState = (state: string) => {
      const stateCounties = countiesByState[state];
      const stateCountiesSelected = stateCounties.every(
        county => filterByCounty.indexOf(county) > -1
      );

      if (stateCountiesSelected) {
        setFilterByCounty(
          filterByCounty.filter(county => !stateCounties.includes(county))
        );
      } else {
        setFilterByCounty([...filterByCounty, ...stateCounties]);
      }
    };

    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: viewType === 'wowcast' ? 'nowrap' : 'wrap',
            flexDirection: viewType === 'map' ? 'column' : 'row',
            justifyContent: 'center',

            '@media (max-height: 768px)': {
              flexDirection: 'row',
              width: viewType === 'map' ? '410px' : '100%',
            },
            '@media print': {
              maxWidth: '1200px',
            },
          }}
        >
          {stats[section].map((stat, i) => (
            <StatsBox {...stat} key={i} />
          ))}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'white',
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'row',
            m: 1,
            mt: 0,
            borderRadius: 2,
            '@media print': {
              display: 'none',
            },
          }}
        >
          {!hideFilters && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  backgroundColor: 'white',
                  height: 'fit-content',
                  mt: 0,
                  borderRadius: 2,
                  '@media print': {
                    display: 'none',
                  },
                }}
              >
                <Tooltip title="Filter Stats Within View">
                  <ToggleButton
                    value="check"
                    sx={{
                      backgroundColor: 'white',
                      border: '4px solid',
                      height: 'fit-content',
                    }}
                    size="small"
                    selected={filterByView}
                    color="primary"
                    onChange={evt => {
                      context?.eventManager?.once?.(
                        'click',
                        (e: any) => e.stopPropagation(),
                        evt.target
                      );
                      setFilterByView(p => !p);
                    }}
                    aria-label="Filter Stats Within View"
                  >
                    {filterByView ? (
                      <CenterFocusStrong sx={{ mr: 0.5 }} />
                    ) : (
                      <CropFreeIcon sx={{ mr: 0.5 }} />
                    )}
                    Filter Within View
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="Filter Stats By County">
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: 180,
                      alignContent: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <InputLabel size="small" id="demo-multiple-name-label">
                      Filter by County
                    </InputLabel>
                    <Select
                      multiple
                      size="small"
                      onChange={handleChange}
                      input={<OutlinedInput label="Filter Models" />}
                      MenuProps={MenuProps}
                      renderValue={selected => selected.join(', ')}
                      value={filterByCounty}
                      sx={{ maxWidth: 275, minWidth: 180 }}
                    >
                      {Object.entries(countiesByState).map(
                        ([state, counties]) =>
                          [state, ...counties].map((stateOrCounty, index) =>
                            index === 0 ? (
                              <MenuItem
                                onClick={() => handleToggleState(stateOrCounty)}
                              >
                                <Checkbox
                                  onClick={() =>
                                    handleToggleState(stateOrCounty)
                                  }
                                  checked={counties.every(
                                    c => filterByCounty.indexOf(c) > -1
                                  )}
                                />
                                {stateOrCounty}
                              </MenuItem>
                            ) : (
                              <MenuItem
                                dense
                                key={stateOrCounty}
                                value={stateOrCounty}
                              >
                                <Checkbox
                                  sx={{
                                    ml: 2,
                                  }}
                                  checked={
                                    filterByCounty.indexOf(stateOrCounty) > -1
                                  }
                                />
                                {stateOrCounty}
                              </MenuItem>
                            )
                          )
                      )}
                    </Select>
                    {filterByCounty.length > 0 && (
                      <IconButton
                        size="small"
                        onClick={() => setFilterByCounty([])}
                      >
                        <Clear />
                      </IconButton>
                    )}
                  </FormControl>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  }
);

function groupCountiesByState(counties: string[]) {
  const stateCounties: { [key: string]: string[] } = {};
  counties.forEach(county => {
    const state = county?.split?.(',')?.[1]?.trim?.();
    if (state != null && state !== '') {
      if (!stateCounties[state]) {
        stateCounties[state] = [];
      }
      stateCounties[state].push(county);
    }
  });
  return stateCounties;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};
