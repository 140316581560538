/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { FlyToInterpolator } from '@deck.gl/core';
import {
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Feature, Polygon } from '@turf/helpers';
import { bbox, pointOnFeature, featureCollection, center } from '@turf/turf';
import React from 'react';
import { useMemo } from 'react';
import { FixedSizeList } from 'react-window';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { viewStateAtom } from '../map/atoms';
import { ipawsColorMap, selectedAlertAtom } from './IPAWSLayer';

import { ipawsChunksSelector } from './selectors';
import { Link, useSearchParams } from 'react-router-dom';
import { nifcDataChunksAtom } from '../NIFC/selectors';
import { NifcPerimeterProperties } from '../NIFC/types';
import { canopyChunksSelector } from '../canopy/selectors';
import { groupBy } from 'lodash';
import { WebMercatorViewport } from '@deck.gl/core';
import { wowChunksSelector } from '../wow/selectors';
import { WowProperties } from '../wow/types';
import { wowColorMap } from '../wow/WowLayer';
import { selectedFeatureAtom } from '../Incident/FeaturePopup';
import { Source } from '@mui/icons-material';

type Props = {
  selectedMap: 'ipaws' | 'nifc' | 'wow' | 'canopy';
  setSelectedMap: Function;
};

const viewport = new WebMercatorViewport({
  width: 600,
  height: 400,
  longitude: -122.45,
  latitude: 37.78,
  zoom: 12,
  pitch: 30,
  bearing: 15,
});

export const AlertsList = React.memo((props: Props) => {
  const wowData = useRecoilValueLoadable(
    wowChunksSelector({ type: 'perimeter', visible: true })
  );

  const wowAlerts = (wowData.state === 'hasValue' ? wowData.contents : [])
    .map(chunk => chunk.features.map(feature => feature))
    .flat()
    .sort((a, b) =>
      a.properties?.Shape__Area < b.properties?.Shape__Area ? 1 : -1
    );

  // IPAWS
  const ipawsData = useRecoilValueLoadable(ipawsChunksSelector(true));
  const alertListData = useMemo(() => {
    if (ipawsData.state !== 'hasValue') {
      return [];
    }
    const ipawsFeatures = ipawsData.contents
      .map(chunk => chunk.features.map(feature => feature))
      .flat();

    const alertsListData = [...(ipawsFeatures ?? [])].sort((a, b) =>
      a.properties.event > b.properties.event ? 1 : -1
    );

    return alertsListData;
  }, [ipawsData.contents, ipawsData.state]);

  // NIFC
  const nifcPerimeters = useRecoilValue(
    nifcDataChunksAtom<NifcPerimeterProperties>('perimeter')
  );
  const nifcAlerts = nifcPerimeters
    .map(chunk => chunk.features.map(feature => feature))
    .flat()
    .sort((a, b) =>
      a.properties.Shape__Area < b.properties.Shape__Area ? 1 : -1
    );

  const canopyData = useRecoilValueLoadable(canopyChunksSelector);
  const canopyListData = useMemo(() => {
    if (canopyData?.state !== 'hasValue') {
      return [];
    }

    const canopyFeatures = canopyData?.contents
      .map(chunk => chunk.features.map(feature => feature))
      .flat();

    const canopyDataList = Object.values(
      groupBy(canopyFeatures, 'properties.kml_id')
    );

    return canopyDataList || [];
  }, [canopyData?.contents, canopyData?.state]);

  const dataMap = {
    wow: wowAlerts,
    ipaws: alertListData,
    nifc: nifcAlerts,
    canopy: canopyListData,
  };

  const data = dataMap[props.selectedMap];

  function handleRow() {
    switch (props.selectedMap) {
      case 'wow':
        return WowRow;
      case 'canopy':
        return CanopyRow;
      default:
        return Row;
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 300,
      }}
    >
      <Box sx={{ width: '100%' }}>
        {/* @ts-ignore */}
        <FixedSizeList
          itemData={{ setSelectedMap: props.setSelectedMap, items: data }}
          height={300}
          width={290}
          itemSize={66}
          itemCount={data.length}
          overscanCount={5}
        >
          {handleRow()}
        </FixedSizeList>
      </Box>
    </Box>
  );
});

export const CanopyRow = React.memo(
  (props: {
    index: number;
    style: React.CSSProperties;
    data: {
      setSelectedMap: Function;
      items: Array<
        Feature<
          Polygon,
          {
            area?: string;
            event?: string;
            priority_rank: number;
            ef_rank: number;
            IncidentName?: string;
            county?: string;
            event_name?: string;
            kml_id: string;
          }
        >[]
      >;
    };
  }) => {
    const { index, style, data } = props;
    const setViewState = useSetRecoilState(viewStateAtom);
    const features = data.items[index];
    const { ef_rank, event_name, county, kml_id } = features[0].properties;
    const eventType = `tornado_rank_${ef_rank}`;
    const combinedFeatures = featureCollection(features);
    const searchParams = useSearchParams();

    return (
      <>
        <ListItem
          style={style}
          key={index}
          component="div"
          disablePadding
          disableGutters
          sx={{
            color: '#565c65',
            overflow: 'hidden',
            borderLeft: `6px solid rgba(${ipawsColorMap[eventType]}, 0.7)`,
            borderBottom: '1px solid #cfe2f0',
            '&:hover': {
              color: '#005288',
              backgroundColor: 'rgb(233, 240, 245)',
              borderLeft: `6px solid rgb(${borderColor(eventType)})`,
            },
          }}
        >
          <ListItemButton
            sx={{ overflow: 'hidden', paddingTop: 2, paddingBottom: 2 }}
            onClick={() => {
              const centerPoint = center(combinedFeatures);
              const [minLng, minLat, maxLng, maxLat] = bbox(combinedFeatures);

              const viewstate = viewport.fitBounds(
                [
                  [minLng, minLat],
                  [maxLng, maxLat],
                ],
                {
                  padding: 20,
                }
              );

              data.setSelectedMap();
              searchParams[1]({
                canopy_kml_id: kml_id,
              });
              setViewState(viewState => ({
                ...viewState,
                longitude: centerPoint.geometry.coordinates[0],
                latitude: centerPoint.geometry.coordinates[1],
                zoom: viewstate.zoom,
                // @ts-expect-error
                pitch: viewstate.pitch,
                // @ts-expect-error
                bearing: viewstate.bearing,
                transitionInterpolator: new FlyToInterpolator({ speed: 3.0 }),
                transitionDuration: 1000,
              }));
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
            >
              {county !== null && (
                <Typography variant="caption">{county}</Typography>
              )}
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {event_name}
              </span>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </>
    );
  }
);

const Row = React.memo(
  (props: {
    index: number;
    style: React.CSSProperties;
    data: {
      setSelectedMap: Function;
      items: Feature<
        Polygon,
        {
          area?: string;
          event?: string;
          priority_rank?: number;
          IncidentName?: string;
          county?: string;
          event_name?: string;
          CreateDate?: string;
        }
      >[];
    };
  }) => {
    const { index, style, data } = props;
    const {
      area,
      event,
      priority_rank,
      county,
      event_name,
      IncidentName,
      CreateDate,
    } = props.data.items[index].properties;

    const eventType = priority_rank
      ? `tornado_rank_${priority_rank}`
      : event ?? 'nifc';
    const setViewState = useSetRecoilState(viewStateAtom);
    const setSelectedAlert = useSetRecoilState(selectedAlertAtom);

    return (
      <>
        <ListItem
          style={style}
          key={index}
          component="div"
          disablePadding
          disableGutters
          sx={{
            color: '#565c65',
            overflow: 'hidden',
            borderLeft: `6px solid rgba(${ipawsColorMap[eventType]}, 0.7)`,
            borderBottom: '1px solid #cfe2f0',
            '&:hover': {
              color: '#005288',
              backgroundColor: 'rgb(233, 240, 245)',
              borderLeft: `6px solid rgb(${borderColor(eventType)})`,
            },
          }}
        >
          <ListItemButton
            sx={{ overflow: 'hidden', paddingTop: 2, paddingBottom: 2 }}
            onClick={() => {
              const items = data.items;

              if (
                items[index] != null &&
                items[index].properties.event != null
              ) {
                setSelectedAlert(items[index]);
              }
              data.setSelectedMap();

              const centerPoint = pointOnFeature(items[index]);

              const [minLng, minLat, maxLng, maxLat] = bbox(items[index]);

              const viewstate = viewport.fitBounds(
                [
                  [minLng, minLat],
                  [maxLng, maxLat],
                ],
                {
                  padding: 20,
                }
              );

              setViewState(viewState => ({
                ...viewState,
                longitude: centerPoint.geometry.coordinates[0],
                latitude: centerPoint.geometry.coordinates[1],
                zoom: viewstate.zoom,
                // @ts-expect-error
                pitch: viewstate.pitch,
                // @ts-expect-error
                bearing: viewstate.bearing,
                transitionInterpolator: new FlyToInterpolator({ speed: 3.0 }),
                transitionDuration: 1000,
              }));
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
            >
              {event != null && (
                <Typography variant="caption">{event}</Typography>
              )}
              {county !== null && (
                <Typography variant="caption">{county}</Typography>
              )}
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {area ?? IncidentName ?? event_name}
              </span>
              {CreateDate != null && (
                <Typography variant="caption">
                  {new Date(CreateDate).toLocaleDateString()}
                </Typography>
              )}
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </>
    );
  }
);

const WowRow = React.memo(
  (props: {
    index: number;
    style: React.CSSProperties;
    data: {
      setSelectedMap: Function;
      items: Feature<Polygon, WowProperties>[];
    };
  }) => {
    const { index, style, data } = props;
    const { Threat, ThreatLevel, CategoryID, DataSource } =
      props.data.items[index].properties;
    const setViewState = useSetRecoilState(viewStateAtom);
    const setSelectedFeature = useSetRecoilState(selectedFeatureAtom);

    return (
      <>
        <ListItem
          style={style}
          key={index}
          component="div"
          disablePadding
          disableGutters
          sx={{
            color: '#565c65',
            overflow: 'hidden',
            borderLeft: `6px solid rgba(${wowColorMap[CategoryID]}, 0.7)`,
            borderBottom: '1px solid #cfe2f0',
            '&:hover': {
              color: '#005288',
              backgroundColor: 'rgb(233, 240, 245)',
              borderLeft: `6px solid rgb(${borderColor(CategoryID)})`,
            },
          }}
        >
          <ListItemButton
            sx={{ overflow: 'hidden', paddingTop: 2, paddingBottom: 2 }}
            onClick={() => {
              const items = data.items;

              if (
                items[index] != null &&
                items[index].properties.Threat != null
              ) {
                setSelectedFeature({
                  ...items[index],
                  section: 'wow-perimeter',
                });
              }
              data.setSelectedMap();

              const centerPoint = pointOnFeature(items[index]);

              const [minLng, minLat, maxLng, maxLat] = bbox(items[index]);

              const viewstate = viewport.fitBounds(
                [
                  [minLng, minLat],
                  [maxLng, maxLat],
                ],
                {
                  padding: 20,
                }
              );

              setViewState(viewState => ({
                ...viewState,
                longitude: centerPoint.geometry.coordinates[0],
                latitude: centerPoint.geometry.coordinates[1],
                zoom: viewstate.zoom,
                // @ts-expect-error
                pitch: viewstate.pitch,
                // @ts-expect-error
                bearing: viewstate.bearing,
                transitionInterpolator: new FlyToInterpolator({ speed: 3.0 }),
                transitionDuration: 1000,
              }));
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
            >
              {Threat != null && (
                <Typography
                  variant="caption"
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    overflow: 'hidden',
                    fontWeight: 'bold',
                  }}
                >
                  {`${Threat}: ${ThreatLevel}`}
                </Typography>
              )}
              {DataSource != null && (
                <Typography
                  variant="caption"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Source sx={{ mr: 1, width: 16 }} /> {DataSource}
                </Typography>
              )}
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </>
    );
  }
);

const borderColor = (event: string) => {
  if (ipawsColorMap[event] != null) {
    return `${ipawsColorMap[event].join(',')}`;
  }
  return '#000';
};

type RowProps = {
  eventType: string;
  event: string;
  incidentId: string;
};

export const IncidentRow = React.memo((props: RowProps) => {
  return (
    <>
      <ListItem
        component="div"
        disablePadding
        sx={{
          color: '#565c65',
          overflow: 'hidden',
          borderBottom: '1px solid #cfe2f0',
          '&:hover': {
            color: '#005288',
            backgroundColor: '#E7F0F6',
          },
        }}
      >
        <StyledLink
          to={`/${props.incidentId}/population/map`}
          state={{
            internal: true,
          }}
        >
          <ListItemButton
            sx={{ overflow: 'hidden', paddingTop: 2, paddingBottom: 2 }}
          >
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
            >
              <Typography variant="caption">{props.eventType} </Typography>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {props.event}
              </span>
            </ListItemText>
          </ListItemButton>
        </StyledLink>
      </ListItem>
    </>
  );
});

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: 100%;
`;
