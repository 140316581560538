import * as React from 'react';
import { useState } from 'react';
import { UserSession } from '@esri/arcgis-rest-auth';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
} from '@mui/material';
import { IUser } from '@esri/arcgis-rest-types';
import { useRecoilState } from 'recoil';
import { userAtom } from './userAtoms';

interface ArcGISAuthProps {
  user?: IUser | null;
  onLogin: (userSession: UserSession) => void;
}

const ArcGISAuth: React.FC<ArcGISAuthProps> = ({ onLogin, user }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [, setUser] = useRecoilState(userAtom);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = async () => {
    try {
      const userSession = await UserSession.beginOAuth2({
        clientId: process.env.REACT_APP_ARCGIS_CLIENT_ID ?? '',
        redirectUri: process.env.REACT_APP_ARCGIS_REDIRECT_URI ?? '',
        portal: 'https://www.arcgis.com/sharing/rest',
        expiration: 20160,
        popup: false,
      });

      if (userSession != null) {
        onLogin(userSession);
      }

      handleClose();
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setUser(null);
    window.localStorage.removeItem('arcgisOAuth');
    window.location.reload(); // Refresh the page to clear the user session
  };

  return (
    <>
      {user != null && (
        <>
          <Button color="secondary" onClick={handleMenuClick}>
            {user.username}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </Menu>
        </>
      )}

      {user == null && (
        <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
          Log in with ArcGIS Online
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Log in</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To access ArcGIS Online, please click the login button to proceed
            with OAuth 2.0.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleLogin}>Log in</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArcGISAuth;
