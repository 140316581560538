/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React, { MouseEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Position } from 'deck.gl';
import { PopupContainer } from '../PopupContainer';
import { FeatureInfo } from '../../Incident/FeaturePopup';
import { IPAWSAlertInfo } from '../../Ipaws/IPAWSAlertPopup';

export type ClickInfo = {
  x: number;
  y: number;
  coordinate: Position | undefined;
  objects: {
    layer: { id: string };
    object: {
      properties: Record<string, unknown>;
      geometry: { coordinates: [number, number] };
    };
  }[];
};

interface LayerInfoPopupProps {
  clickInfo: ClickInfo | null;
  activeObjectIndex: number;
  handlePrevClick: (event: MouseEvent<HTMLButtonElement>) => void;
  handleNextClick: (event: MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
}

const LayerInfoPopup: React.FC<LayerInfoPopupProps> = ({
  clickInfo,
  activeObjectIndex,
  handlePrevClick,
  handleNextClick,
  onClose,
}) => {
  if (!clickInfo || !clickInfo.objects) {
    return null;
  }

  const [longitude, latitude] = clickInfo?.coordinate ?? [0, 0];

  function handleLayerName(layerId: string) {
    switch (true) {
      case layerId.includes('ipaws-post'):
        return 'IPAWS-POST';
      case layerId.includes('ipaws'):
        return 'IPAWS Boundary';
      case layerId.includes('nifc-post'):
        return 'NIFC-POST';
      case layerId.includes('nifc'):
        return 'NIFC Boundary';
      case layerId.includes('wow-post'):
        return 'WOW-POST';
      case layerId.includes('wow'):
        return 'WOW Boundary';
      case layerId.includes('canopy-post'):
        return 'Canopy-POST';
      case layerId.includes('population'):
        return 'Population';
      case layerId.includes('residential'):
        return 'Residential';
      case layerId.includes('nonResidential'):
        return 'Non-Residential';
      case layerId.includes('energy'):
        return 'Energy';
      case layerId.includes('transportation'):
        return 'Transportation';
      case layerId.includes('water'):
        return 'Food, Water, and Shelter';
      case layerId.includes('medical'):
        return 'Health & Medical';
      case layerId.includes('communication'):
        return 'Communications';
      case layerId.includes('canopy-swath'):
        return 'Tornado Swath';
      case layerId.includes('usa-structures'):
        return 'USA Structures';
      default:
        return layerId;
    }
  }
  const feature = clickInfo.objects[activeObjectIndex].object;
  const layerId = clickInfo.objects[activeObjectIndex].layer.id;
  const features = clickInfo.objects.map(o => o.object);

  return (
    <PopupContainer longitude={longitude} latitude={latitude} onClose={onClose}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {features.length > 1 && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="previous"
              onClick={handlePrevClick}
              disabled={activeObjectIndex === 0}
            >
              <NavigateBeforeIcon />
            </IconButton>
          )}
          <h5>
            Layer: {handleLayerName(layerId)}{' '}
            {features.length > 1 &&
              `(${activeObjectIndex + 1} / ${features.length})`}
          </h5>
          {features.length > 1 && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="next"
              onClick={handleNextClick}
              disabled={activeObjectIndex === clickInfo.objects.length - 1}
            >
              <NavigateNextIcon />
            </IconButton>
          )}
        </div>
        {layerId.includes('ipaws') && !layerId.includes('ipaws-post') && (
          <IPAWSAlertInfo {...feature.properties} />
        )}

        {/* @ts-ignore */}
        <FeatureInfo {...feature} />
      </div>
    </PopupContainer>
  );
};

export default LayerInfoPopup;
