/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import {
  atom,
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import { Feature, Polygon } from '@turf/turf';
import { Legend } from '../components/Legend';
import { allPostChunksSelector } from './selectors';
import { POSTProperties } from './types';
import { RGBAColor } from '@deck.gl/core/utils/color';
import { useGridCellLayer } from '../map/GridCellLayer';
import React from 'react';
import { WowPostProperties } from '../wow/types';
import { useMatch } from 'react-router-dom';
import { selectedElevationKeyAtom } from '../map/atoms';
import { PrimaryLayer } from '../pages/MapPage';
import { selectedFeatureAtom } from '../Incident/FeaturePopup';

type Props = {
  extruded: boolean;
  visible: boolean;
};

export const postThreeDAtom = atom({
  key: 'postThreeD',
  default: false,
});

// export const selectedFeatureAtom = atom<Feature<
//   Polygon,
//   Partial<POSTProperties> &
//     Partial<CanopyProperties> &
//     Partial<WowPostProperties> &
//     Partial<NifcPostProperties>
// > | null>({
//   key: 'selectedFeature',
//   default: null,
// });

export const defaultSectionKey = (section: string) => {
  switch (section) {
    case 'nifc-post':
    case 'wow-post':
    case 'canopy-post':
    case 'ipaws-post':
    case 'post':
      return 'priority_rank';
    case 'energy':
      return 'nf_np_impact';
    case 'population':
      return 'exposurescore';
    case 'commodities':
      return 'peopleinneed';
    case 'communications':
      return 'percentout';
    case 'residential':
      return 'exposurescoreformap';
    case 'nonResidentialStructures':
      return 'exposurescore_allstructures';
    case 'healthMedical':
      return 'impactscore';
    case 'transportation':
      return 'impactscore';
    case 'foodWaterShelter':
      return 'sheltercapacitypercent';
    case 'declarations':
      return 'designation_type';
    default:
      return 'priority_rank';
  }
};

export const primaryKeyForSection = atomFamily<string, string>({
  key: 'primaryKeyForSection',
  default: selectorFamily({
    key: 'primaryKeyForSection/Default',
    get: param => () => {
      return defaultSectionKey(param);
    },
  }),
});

export const usePostLayer = (props: Props) => {
  const match = useMatch({ path: '/' });
  const allPostData = useRecoilValueLoadable(
    allPostChunksSelector(match != null)
  );

  const [selectedPost] = useRecoilState(selectedFeatureAtom);
  const [selectedElevationKey] = useRecoilState<keyof POSTProperties>(
    selectedElevationKeyAtom
  );
  const primaryKey = useRecoilValue(primaryKeyForSection('ipaws-post'));

  const getFillColor = React.useCallback(
    (d: Feature<Polygon, POSTProperties>): RGBAColor => {
      return getPostFillColor(d);
    },
    []
  );

  const fillColor = React.useMemo(
    () => (primaryKey === 'priority_rank' ? getFillColor : undefined),
    [primaryKey, getFillColor]
  );

  const getElevation = React.useCallback(
    (d: Feature<Polygon, POSTProperties>): number => {
      const value = d?.properties?.[selectedElevationKey];
      if (typeof value === 'number') {
        return value;
      }
      return 0;
    },
    [selectedElevationKey]
  );

  // const handleClick = React.useCallback(
  //   (info: any) => {
  //     if (info?.object) {
  //       setSelectedPost(info.object);
  //     }
  //   },
  //   [setSelectedPost]
  // );

  const GridCellLayer = useGridCellLayer<
    Feature<Polygon, POSTProperties & Partial<WowPostProperties>>
    // @ts-ignore
  >({
    // @ts-ignore
    id: 'ipaws-post',
    // @ts-ignore
    data: allPostData.state === 'hasValue' ? allPostData.contents : [],
    getFillColor: fillColor,
    highlightId: selectedPost?.id,
    getElevation,
    extruded: props.extruded,
    visible: props.visible,
    // @ts-ignore
    // onClick: handleClick,
  });

  return GridCellLayer;
};

export const postColorRange: RGBAColor[] = [
  [181, 9, 9, 190], // #B50909
  [219, 129, 0, 190], // #DB8100
  [226, 178, 0, 190], // #E2B200
  [143, 195, 201, 190], // #8FC3C9
  [95, 133, 188, 190], // #5F85BC
];

export const getRankColor = (rank?: number): RGBAColor => {
  switch (rank) {
    case 1:
      return postColorRange[0];
    case 2:
      return postColorRange[1];
    case 3:
      return postColorRange[2];
    case 4:
      return postColorRange[3];
    case 5:
      return postColorRange[4];
    default:
      return [0, 0, 0, 0];
  }
};

export const getPercentColor = (percent: number | null): RGBAColor => {
  if (percent == null) {
    return [0, 0, 0, 0];
  }

  if (percent <= 15) {
    return postColorRange[4];
  }

  if (percent <= 30) {
    return postColorRange[3];
  }

  if (percent <= 45) {
    return postColorRange[2];
  }

  if (percent <= 60) {
    return postColorRange[1];
  }
  if (percent <= 100 || percent > 100) {
    return postColorRange[0];
  }

  return postColorRange[4];
};

export const getPostFillColor = (
  d: Feature<Polygon, Partial<POSTProperties>>
): RGBAColor => {
  const { priority_rank } = d.properties;
  return getRankColor(priority_rank);
};

const PRIORITY_LEVELS = [
  'Highest Priority',
  'High Priority',
  'Medium Priority',
  'Low Priority',
  'Lowest Priority',
];

export const PostLegend = (props: { selectedMap: PrimaryLayer }) => {
  const keys = elevationKeys.map(key => ({
    key,
    label: postKeyToLabel[key],
  }));
  const id =
    props.selectedMap === 'post' ? 'ipaws-post' : `${props.selectedMap}-post`;
  const primaryKey = useRecoilValue(
    primaryKeyForSection(id)
  ) as keyof typeof postKeyToLabel;

  return (
    <Legend
      id={id}
      disableZoomToExtent
      title={
        primaryKey != null
          ? primaryKey === 'priority_rank'
            ? 'POST Priority Levels'
            : postKeyToLabel?.[primaryKey]
          : undefined
      }
      primaryProperties={[
        { key: 'priority_rank', label: 'POST Priority Levels' },
        ...keys,
      ]}
      elevationProperties={keys}
      items={postColorRange.map((color, index) => ({
        color: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
        label: `${PRIORITY_LEVELS[index]} ${index + 1}`,
      }))}
    />
  );
};

const elevationKeys = [
  'pop',
  'ls_day_pop',
  'ls_night_pop',
  'households',
  'housingunits',
  'pop65over',
  'pop16overunemployed',
  'pop16overnotinlaborforce',
  'popamindianalaskan',
  'householdsinpoverty',
  'householdsdsfs',
  'householdsdsnofs',
  'householdswithfs',
  'householdswithpa',
  'housingunitsmobilehomes',
  'lep',
] as const;

export const postKeyToLabel = {
  event_id: 'Grouped IPAWS event',
  usng5km: '5km USNG cell',
  state: 'State',
  county: 'County',
  fips: 'County FIPS code',
  ls_day_pop: 'LandScan Daytime Population',
  ls_night_pop: 'LandScan Nightime Population',
  pop: 'Population',
  households: 'Number of Households',
  housingunits: 'Number of Housing units',
  pop65over: 'Population age 65 and over',
  pop16overunemployed: 'Population age 16+ and unemployed',
  pop16overnotinlaborforce: 'Population 16+ not in labor force',
  popamindianalaskan: 'Population American Indian or Alaska Native',
  householdsinpoverty: 'Number of Households in poverty',
  householdsdsfs: 'Number of Households on disability and food stamps',
  householdsdsnofs: 'Number of Households on disability no food stamps',
  householdswithfs: 'Number of Households with food stamps/SNAP',
  householdswithpa: 'Number of Households with public assistance',
  housingunitsmobilehomes: 'Number of Housing units that are mobile homes',
  lep: 'Number of Households with Limited English Proficiency',
  event: 'IPWAS event type',
  effective: 'Effective',
  expires: 'Expires',
  quantile_sum: 'Rank Sum',
  final_rank: 'Unscaled Rank',
  priority_rank: 'POST Priority',
  run_time: 'POST run time',
  CommercialOfficeImpacts: 'Commercial Office Impact',
  ExposureScore: 'Exposure Score',
  ImpactScore: 'Impact Score',
  PercentOut: 'Percent Out',
  RankPotentialPowerOutageImpact: 'Rank Potential Power Outage Impact',
  IncidentName: 'Incident Name',
  event_name: 'Event Name',
  ef_rank: 'Enhanced Fujita Rank',
  canopy_published_date: 'Canopy Published Date',
  state_intersect: 'State intersect',
  county_intersect: 'County intersect',
  fips_intersect: 'FIPS intersect',
  structures_exposed: 'Structures Exposed',
  time_category: 'Time Category',
} as const;
