import { FeatureCollection, Polygon } from '@turf/helpers';
import { atom, atomFamily, selector, useRecoilCallback } from 'recoil';
import { esriParamsObject } from '../esri';
// @ts-ignore
import arcgisPbfDecode from 'arcgis-pbf-parser';

const SWATHS =
  'https://services.arcgis.com/XG15cJAlne2vxtgt/arcgis/rest/services/a12555/FeatureServer/0/query';

export async function asyncGetSwathData(
  offset = 0,
  token = '',
  kmls?: string[]
): Promise<FeatureCollection<Polygon, SwathData>> {
  const limit = 2000;

  let searchParams = new URLSearchParams(esriParamsObject);

  if (token != null && token !== '') {
    searchParams.set('token', token);
  }

  if (kmls != null && kmls.length > 0) {
    searchParams.set('where', `kml_id in ('${kmls.join("','")}')`);
  }

  const response = await fetch(
    `${SWATHS}?${searchParams.toString()}&resultRecordCount=${limit}&resultOffset=${offset}`
  );
  const buffer = await response.arrayBuffer();
  const data = arcgisPbfDecode(new Uint8Array(buffer));

  return {
    ...data.featureCollection,
    properties: { exceededTransferLimit: data?.exceededTransferLimit ?? false },
  };
}

export const swathDataChunksAtom = atomFamily<
  FeatureCollection<Polygon, SwathData>,
  string
>({
  key: 'canopySwathDataChunks',
});

export const swathDataChunkIdsAtom = atom<string[]>({
  key: 'canopySwathDataChunkIds',
  default: [],
});

type SwathData = {
  canopy_published_date: string;
  structures_exposed: number;
  county_intersect: string;
  state_intersect: string;
  fips_intersect: string;
};

export const useSetSwathDataChunksUsers = (
  swathDataChunks: FeatureCollection<Polygon, SwathData>[],
  token?: string | null,
  kmls?: string[]
) => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        if (token == null) {
          return;
        }

        const page = swathDataChunks.length * 2000;
        // @ts-ignore
        const data = await asyncGetSwathData(page, token, kmls);
        set(swathDataChunksAtom(`canopy-swath-${page}`), data);

        set(swathDataChunkIdsAtom, current =>
          Array.from(new Set([...current, `canopy-swath-${page}`]))
        );
      },
    [swathDataChunks, token]
  );
};

export const swathChunksSelector = selector({
  key: 'canopySwathChunks',
  get: ({ get }) => {
    const swathDataChunkIds = get(swathDataChunkIdsAtom);
    const swathDataChunks = swathDataChunkIds.map(id =>
      get(swathDataChunksAtom(id))
    );
    return swathDataChunks ?? [];
  },
});
