/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { FeatureCollection, Polygon, Properties } from '@turf/helpers';
import { selectorFamily, atom, useSetRecoilState } from 'recoil';
import { esriParamsObject } from '../esri';
import React from 'react';

// @ts-ignore
import arcgisPbfDecode from 'arcgis-pbf-parser';
import { WowProperties } from './types';

const WOW_PERIMETER_SERVER =
  'https://services.arcgis.com/XG15cJAlne2vxtgt/ArcGIS/rest/services/WOW_threats/FeatureServer/0/query';

const POST_FEATURE_SERVER =
  'https://services.arcgis.com/XG15cJAlne2vxtgt/ArcGIS/rest/services/WOW_POST_USNG1KM/FeatureServer/0/query';

export async function asyncGetWowData<P = Properties>(
  type: 'perimeter' | 'post',
  offset = 0
): Promise<FeactureCollectionChunk<Polygon, P>> {
  const limit = 2000;
  let searchParams = new URLSearchParams(esriParamsObject);
  const BASE_URL =
    type === 'perimeter' ? WOW_PERIMETER_SERVER : POST_FEATURE_SERVER;

  const response = await fetch(
    `${BASE_URL}?${searchParams.toString()}&resultRecordCount=${limit}&resultOffset=${offset}`
  );
  const buffer = await response.arrayBuffer();
  const data = arcgisPbfDecode(new Uint8Array(buffer));

  return {
    ...data.featureCollection,
    properties: { exceededTransferLimit: data?.exceededTransferLimit ?? false },
  };
}
type FeactureCollectionChunk<G, P> = FeatureCollection<G, P> & {
  properties?: {
    exceededTransferLimit?: boolean;
  };
};

export const refreshTriggerAtom = atom({
  key: 'refreshTrigger',
  default: 0, // Initial value
});

export const wowChunksSelector = selectorFamily<
  FeatureCollection<any, WowProperties>[],
  { type: 'post' | 'perimeter'; visible: boolean }
>({
  key: 'wowChunks',
  get:
    ({ type, visible }) =>
    async ({ get }) => {
      get(refreshTriggerAtom);

      let results: FeactureCollectionChunk<any, any>[] = [];

      const getChunks = async (type: 'post' | 'perimeter', offset: number) => {
        const data = await asyncGetWowData(type, offset);

        if (data.properties?.exceededTransferLimit) {
          await getChunks(type, offset + 2000);
        }
        results = [...results, data];
      };

      if (visible) {
        await getChunks(type, 0);
      }
      return results;
    },
});

export const usePeriodicWowDataRefresh = () => {
  const setRefreshTrigger = useSetRecoilState(refreshTriggerAtom);

  React.useEffect(() => {
    const interval = setInterval(() => {
      // Update the refresh trigger to re-fetch data
      setRefreshTrigger(currentValue => currentValue + 1);
    }, 900000); // 15 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  }, [setRefreshTrigger]);
};
