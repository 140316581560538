import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { Feature, Polygon } from '@turf/turf';
import { CanopyProperties } from './types';
import { useGridCellLayer } from '../map/GridCellLayer';
import React from 'react';
import { viewStateAtom } from '../map/atoms';
import { swathChunksSelector, useSetSwathDataChunksUsers } from './api';
import { userAtom } from '../auth/userAtoms';
import { canopyChunksSelector } from './selectors';

type Props = {
  extruded: boolean;
  visible: boolean;
};

export const useCanopySwathLayer = (props: Props) => {
  const [user] = useRecoilState(userAtom);
  const postData = useRecoilValue(canopyChunksSelector);
  const availableKmls = Array.from(
    new Set(
      postData?.flatMap(c => c.features.map(f => f.properties.kml_id)) ?? []
    )
  );

  const swathData = useRecoilValueLoadable(swathChunksSelector);
  const callback = useSetSwathDataChunksUsers(
    swathData?.contents ?? [],
    user?.token,
    availableKmls
  );
  const setViewState = useSetRecoilState(viewStateAtom);

  React.useEffect(() => {
    const contents = swathData.contents;
    if (swathData.state === 'hasValue' && user != null) {
      if (
        contents.length === 0 ||
        contents?.[contents.length - 1]?.properties?.exceededTransferLimit
      ) {
        callback();
      }
    }
  }, [swathData, callback, props.visible, setViewState, user]);

  const GridCellLayer = useGridCellLayer<Feature<Polygon, CanopyProperties>>({
    id: 'canopy-swath-post',
    // @ts-ignore
    data: swathData.state === 'hasValue' ? swathData.contents : [],
    getFillColor: () => [0, 0, 0, 100],
    getLineColor: () => [181, 9, 9, 200],
    pickable: true,
    extruded: props.extruded,
    stroked: true,
    visible: props.visible && user != null,
  });

  if (swathData.contents.length === 0) {
    return null;
  }

  return GridCellLayer;
};
