/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { CountyFeatureCollection } from './types';
import {
  countyChartDataAtom,
  getOutputStatisticsBySectionId,
  incidentChunksSelector,
  useSetIncidentDataChunks,
} from './atoms';
import { FlyToInterpolator } from '@deck.gl/core';
import { GeoJsonLayer, TextLayer } from '@deck.gl/layers';
import { selectedFeatureAtom } from './FeaturePopup';
import {
  useRecoilValue,
  useRecoilState,
  useSetRecoilState,
  atom,
} from 'recoil';
import { viewStateAtom } from '../map/atoms';
import React from 'react';
import { useMatch } from 'react-router-dom';
import { PathStyleExtension } from '@deck.gl/extensions';
import { centerOfMass } from '@turf/turf';
import { getPercentColor } from '../Post/PostLayer';
import { Legend } from '../components/Legend';
import { RGBAColor } from 'deck.gl';
import { generateCountyChartTitle } from './IncidentCountyChart';
import { layerVisibility } from '../map/atoms';
import { mapStyleAtom } from '../map/MapStylePicker';

type Props = {
  autoHighlight: boolean;
};

export const viewCountiesAtom = atom<boolean>({
  key: 'countyBoundaries',
  default: false,
});

export const useCountyLayer = (props: Props) => {
  const mapStyle = useRecoilValue(mapStyleAtom);

  const [selectedFeature, setSelectedFeature] =
    useRecoilState(selectedFeatureAtom);
  const setViewState = useSetRecoilState(viewStateAtom);
  const viewCountiesBoundary = useRecoilValue(viewCountiesAtom);
  const hightlightCounties = useRecoilValue(
    layerVisibility('county-highlights')
  );

  const {
    params: { incidentId, sectionId },
  } = useMatch({ path: '/:incidentId/:sectionId/:viewType/:attributeId?' }) ?? {
    params: {},
  };

  const data = useRecoilValue(
    incidentChunksSelector({
      incidentId: 'county',
      sectionId: 'county',
      visible: true,
    })
  );

  const countyIncidentStatsData = useRecoilValue(
    countyChartDataAtom({ incidentId, sectionId })
  );

  const countyData = React.useMemo(() => data.flatMap(a => a.data), [data]);
  const countyDataChunkCallback = useSetIncidentDataChunks(
    'county',
    'county',
    0
  );

  const noData = countyData.length === 0;

  React.useEffect(() => {
    let ignore = false;

    if (!ignore && noData) {
      countyDataChunkCallback();
    }
    return () => {
      ignore = true;
    };
  }, [countyDataChunkCallback, noData]);

  const countyFieldName = getOutputStatisticsBySectionId(
    sectionId ?? 'population'
  ).find(
    fields => fields.onStatisticField === countyIncidentStatsData?.yAttribute
  )?.countyFieldName;

  const handleFillColor = React.useCallback(
    (d: any): any => {
      if (
        hightlightCounties &&
        countyIncidentStatsData != null &&
        countyIncidentStatsData.labels.includes(d.properties.county_name)
      ) {
        const index = countyIncidentStatsData.labels.indexOf(
          d.properties.county_name
        );
        const value =
          countyIncidentStatsData.data[index][
            countyIncidentStatsData.yAttribute
          ];

        if (
          countyIncidentStatsData.chartDataType === 'percent' &&
          countyFieldName
        ) {
          const countyTotal = d.properties[countyFieldName];
          const percent = value / countyTotal;
          return [...getPercentColor(percent * 100).slice(0, 3), 120];
        }

        const percentRank = () => {
          const sorted = countyIncidentStatsData.data
            .map((d: any) => d[countyIncidentStatsData.yAttribute])
            .sort((a: any, b: any) => a - b);
          const rank = sorted.indexOf(value);
          return rank / sorted.length;
        };

        return [...getPercentColor(percentRank() * 100).slice(0, 3), 120];
      }
      return d.properties.id === selectedFeature?.properties?.id
        ? [0, 206, 265, 50]
        : [0, 0, 0, 0];
    },
    [
      countyFieldName,
      selectedFeature?.properties?.id,
      countyIncidentStatsData,
      hightlightCounties,
    ]
  );

  const handleClick = React.useCallback(
    (info: any) => {
      if (info?.object) {
        setSelectedFeature({ ...info?.object, section: 'county' });
        setViewState((view: any) => ({
          ...view,
          longitude: info?.coordinate?.[0],
          latitude: info?.coordinate?.[1],
          transitionInterpolator: new FlyToInterpolator({ speed: 3.0 }),
          transitionDuration: 1000,
        }));
      }
    },
    [setSelectedFeature, setViewState]
  );

  const textData = React.useMemo(() => {
    return countyData
      .flatMap(chunk => chunk.features)
      .filter((d: any) => {
        return countyIncidentStatsData?.labels.includes(
          d.properties.county_name
        );
      })
      .map(f => ({
        ...f,
        properties: {
          ...f.properties,
          incidentStats:
            countyIncidentStatsData?.data[
              countyIncidentStatsData?.labels.indexOf(
                f?.properties?.county_name
              )
            ],
        },
      }));
  }, [countyData, countyIncidentStatsData]);

  function removeStateFromCountyName(countyName: string) {
    const countyNameParts = countyName.split(',');
    if (countyNameParts.length > 1) {
      return countyNameParts
        .slice(0, -1)
        .join(' ')
        .split(' ')
        .slice(0, -1)
        .join(' ');
    }
    return countyName;
  }

  const textLayer = React.useMemo(() => {
    return new TextLayer({
      id: 'county-text-layer',
      data: textData,
      getColor: () =>
        mapStyle.match('Dark') || mapStyle.match('Night')
          ? [255, 255, 255]
          : [0, 62, 103],
      pickable: false,
      // @ts-ignore
      getPosition: (d: any) => {
        const post = centerOfMass(d).geometry.coordinates;
        return [post[0], post[1]];
      },
      getText: (d: any) => {
        const text = d.properties.county_name;

        return removeStateFromCountyName(text);
      },
      fontFamily:
        '"ITC Franklin Gothic", "Source Sans Pro", Open Sans, Arial, Helvetica, sans-serif',
      background: true,
      getBackgroundColor: [255, 255, 255, 20],
      backgroundPadding: [4, 4],
      billboard: false,
      getSize: 4000,
      fontSettings: {
        sdf: true,
      },
      outlineColor: [0, 62, 103, 50],
      outlineWidth: 0.3,
      sizeScale: 2,
      sizeUnits: 'meters',
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center',
      visible: hightlightCounties,
      updateTriggers: {
        getText: [countyIncidentStatsData],
        getColor: [mapStyle],
      },
    });
  }, [countyIncidentStatsData, textData, hightlightCounties, mapStyle]);

  const layers = React.useMemo(() => {
    return countyData.map((chunk, index) => {
      return new GeoJsonLayer<CountyFeatureCollection['features'][number]>({
        id: `county-chunk-${index}`,
        // @ts-ignore
        data: chunk,
        getFillColor: handleFillColor,
        getLineColor: [100, 120, 185],
        autoHighlight: true,
        highlightColor: [0, 206, 265, 50],
        getLineWidth: 2,
        lineWidthMinPixels: 1,
        pickable: viewCountiesBoundary,
        filled: true,
        wireframe: false,
        stroked: viewCountiesBoundary,
        material: false,
        visible: true,
        getDashArray: [20, 10],
        dashJustified: true,
        lineWidthScale: 2,
        extensions: [new PathStyleExtension({ dash: true })],
        updateTriggers: {
          getFillColor: [selectedFeature, handleFillColor],
        },

        onClick: handleClick,
      });
    });
  }, [
    countyData,
    handleFillColor,
    handleClick,
    selectedFeature,
    viewCountiesBoundary,
  ]);

  return [layers, textLayer];
};

const colorRange: RGBAColor[] = [
  [181, 9, 9, 190], // #B50909
  [219, 129, 0, 190], // #DB8100
  [226, 178, 0, 190], // #E2B200
  [143, 195, 201, 190], // #8FC3C9
  [95, 133, 188, 190], // #5F85BC
];

const percentlegendItems = [
  { label: '60% - 100%', color: `rgb(${colorRange[0]})` },
  { label: '45% - 60%', color: `rgb(${colorRange[1]})` },
  { label: '30% - 45%', color: `rgb(${colorRange[2]})` },
  { label: '15% - 30%', color: `rgb(${colorRange[3]})` },
  { label: '0% - 15%', color: `rgb(${colorRange[4]})` },
];

export const CountyLegend = () => {
  const {
    params: { incidentId, sectionId },
  } = useMatch({ path: '/:incidentId/:sectionId/:viewType/:attributeId?' }) ?? {
    params: {},
  };

  const countyIncidentStatsData = useRecoilValue(
    countyChartDataAtom({ incidentId, sectionId })
  );

  function findPercentileRanges(
    data: number[],
    percentiles: number[]
  ): number[] {
    const sortedData = [...data].sort((a, b) => a - b);
    return percentiles.map(percentile => {
      const index = Math.floor((sortedData.length - 1) * (percentile / 100));
      return sortedData[index];
    });
  }

  const rangeValues = findPercentileRanges(
    countyIncidentStatsData?.data.map(
      p => p[countyIncidentStatsData.yAttribute]
    ) ?? [],
    [100, 60, 45, 30, 15]
  );

  const sumLegendItems = rangeValues
    ? rangeValues.filter(Boolean).map((value, index) => ({
        label: `${((rangeValues?.[index + 1] ?? 0) + 1)?.toLocaleString?.(
          'en-US',
          {
            maximumSignificantDigits: 3,
            maximumFractionDigits: 2,
            compactDisplay: 'short',
            notation: 'compact',
          }
        )} - ${value?.toLocaleString?.('en-US', {
          maximumSignificantDigits: 3,
          maximumFractionDigits: 2,
          compactDisplay: 'short',
          notation: 'compact',
        })} `,
        color: `rgb(${colorRange[index][0]}, ${colorRange[index][1]}, ${colorRange[index][2]})`,
      }))
    : [];

  return (
    <Legend
      id="county-highlights"
      title={
        generateCountyChartTitle(
          sectionId ?? 'population',
          countyIncidentStatsData?.yAttribute ?? 'ls_night_pop',
          countyIncidentStatsData?.chartDataType ?? 'sum'
        ) + ' by County'
      }
      disableZoomToExtent
      items={
        countyIncidentStatsData?.chartDataType === 'sum'
          ? sumLegendItems
          : percentlegendItems
      }
    />
  );
};
