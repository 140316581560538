import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import debounce from 'lodash.debounce';
import {
  IncidentDataKeys,
  useSetIncidentDataChunks,
  selectedRunTimeAtom,
  incidentRuntimeAtom,
  firstRunAtom,
  extentStateAtom,
} from './atoms';
import { useSetRecoilState } from 'recoil';
import { viewStateAtom, visualModeAtom } from '../map/atoms';
import { useMatch, useLocation, useSearchParams } from 'react-router-dom';
import { useCommunicationsLayer } from '../Incident/CommunicationsLayer';
import { usePopulationLayer } from '../Incident/PopulationLayer';
import { useNonResidentialStructureLayer } from '../Incident/NonResidentialLayer';
import { useResidentialStructureLayer } from '../Incident/ResidentialLayer';
import { bbox } from '@turf/turf';
import { WebMercatorViewport, FlyToInterpolator } from '@deck.gl/core';
import { useDeclarationsLayer } from '../Incident/DeclarationsLayer';

export const useIncidentRuntimeDataSync = ({
  incidentId,
  sectionId,
  sync,
}: {
  incidentId: string;
  sectionId: IncidentDataKeys;
  sync: boolean;
}) => {
  const [dataStatus, setDataStatus] = React.useState<
    'init' | 'loading' | 'loaded'
  >('init');

  const { time, comments } =
    useRecoilValue(selectedRunTimeAtom(`${incidentId}-${sectionId}`)) ?? {};

  const callback = useSetIncidentDataChunks(
    incidentId,
    sectionId,
    time,
    comments,
    () => {
      setDataStatus('loaded');
    }
  );

  const debouncedCallback = React.useMemo(
    () => debounce(callback, 300),
    [callback]
  );

  const runtimeData = useRecoilValue(
    incidentRuntimeAtom({
      incidentId,
      sectionId,
      lastUpdatedOn: time,
    })
  );

  const hasRuntimeData = (runtimeData?.data ?? []).length > 0;

  React.useEffect(() => {
    let ignore = false;
    if (!ignore && sync && !hasRuntimeData && time != null) {
      setDataStatus('loading');
      debouncedCallback();
    }

    return () => {
      debouncedCallback.cancel();
      ignore = true;
    };
  }, [sync, hasRuntimeData, debouncedCallback, time]);

  return { dataStatus, hasRuntimeData, runtimeData };
};

export const useIncidentLayers = () => {
  const verifySection = (section?: string): IncidentDataKeys => {
    // if (incidentPageSections.every(s => s.key !== section)) {
    //   return 'population';
    // }
    return section as IncidentDataKeys;
  };

  const setViewState = useSetRecoilState(viewStateAtom);
  const visualMode = useRecoilValue(visualModeAtom);
  const threeD = visualMode === '3d';
  const match = useMatch({
    path: '/:incidentId/:sectionId/:viewType/:attributeId?',
  });
  const matchNrcc = useMatch({ path: '/wowcast/:incidentId/:sectionId' });
  const params = {
    ...(match?.params ?? {}),
    ...(matchNrcc?.params ?? {}),
  };
  const isMatch = match != null || matchNrcc != null;
  const location = useLocation();

  const selectedSection =
    verifySection(params?.sectionId ?? 'population') ?? 'population';
  const incidentId = params?.incidentId ?? 'default';
  const [firstRun, setFirstRun] = useRecoilState(firstRunAtom);
  const [extentState, setExtentState] = useRecoilState(extentStateAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  const { dataStatus, runtimeData, hasRuntimeData } =
    useIncidentRuntimeDataSync({
      incidentId,
      sectionId: selectedSection,
      sync: isMatch,
    });

  const { dataStatus: populationDataStatus } = useIncidentRuntimeDataSync({
    incidentId,
    sectionId: 'population',
    sync: selectedSection === 'declarations',
  });

  const firstChunk = runtimeData?.data?.[0];

  const initialBbox = React.useMemo(() => {
    if (
      !hasRuntimeData ||
      firstChunk == null ||
      firstChunk.features.length === 0
    ) {
      return null;
    }
    return bbox(firstChunk);
  }, [firstChunk, hasRuntimeData]);

  const handleViewToExtent = React.useCallback(() => {
    if (initialBbox != null) {
      const [minLng, minLat, maxLng, maxLat] = initialBbox;

      setViewState((prevViewState: any) => {
        const viewport = new WebMercatorViewport({
          width: prevViewState.width,
          height: prevViewState.height,
          longitude: prevViewState.longitude,
          latitude: prevViewState.latitude,
          zoom: prevViewState.zoom,
          pitch: prevViewState.pitch,
          bearing: prevViewState.bearing,
          maxPitch: 85,
        });

        const viewstate = viewport.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: 20,
          }
        );
        return {
          // @ts-expect-error
          latitude: viewstate.latitude,
          // @ts-expect-error
          longitude: viewstate.longitude,
          zoom: viewstate.zoom,
          pitch: prevViewState.pitch,
          // @ts-expect-error
          bearing: viewstate.bearing,
          width: prevViewState.width,
          height: prevViewState.height,
          transitionInterpolator: new FlyToInterpolator(),
          transitionDuration: 1000,
        };
      });
    }
  }, [setViewState, initialBbox]);

  const internalLink = location?.state?.internal;
  const hasBbox = searchParams.has('bbox');

  React.useEffect(() => {
    if (extentState === 'zoom-to-extent') {
      handleViewToExtent();
      setExtentState('idle');
    }
  }, [extentState, setExtentState, handleViewToExtent]);

  React.useEffect(() => {
    if (
      isMatch &&
      ((firstRun && !hasBbox) || (firstRun && internalLink && hasBbox))
    ) {
      if (hasRuntimeData && initialBbox != null) {
        handleViewToExtent();
        setFirstRun(false);
        setSearchParams(
          {
            bbox: initialBbox.join(','),
          },
          {
            state: {
              internal: false,
            },
            replace: true,
          }
        );
      }
    }

    if (!isMatch) {
      setFirstRun(true);
    }
  }, [
    internalLink,
    isMatch,
    firstRun,
    hasRuntimeData,
    initialBbox,
    handleViewToExtent,
    setFirstRun,
    hasBbox,
    setSearchParams,
  ]);

  const populationLayer = usePopulationLayer({
    visible: selectedSection === 'population',
    extruded: threeD,
    incidentId,
  });
  const communicationsLayer = useCommunicationsLayer({
    visible: selectedSection === 'communications',
    extruded: threeD,
    incidentId,
  });

  const residentialLayer = useResidentialStructureLayer({
    visible: selectedSection === 'residential',
    extruded: threeD,
    incidentId,
  });

  const nonResidentialStructuresLayer = useNonResidentialStructureLayer({
    visible: selectedSection === 'nonResidentialStructures',
    extruded: threeD,
    incidentId,
  });

  const declarationsLayer = useDeclarationsLayer({
    visible: selectedSection === 'declarations',
    extruded: threeD,
    incidentId,
  });

  const layers = React.useMemo(
    () => [
      populationLayer,
      communicationsLayer,
      residentialLayer,
      nonResidentialStructuresLayer,
      declarationsLayer,
    ],
    [
      populationLayer,
      communicationsLayer,
      residentialLayer,
      nonResidentialStructuresLayer,
      declarationsLayer,
    ]
  );

  return {
    layers,
    status: populationDataStatus !== 'init' ? populationDataStatus : dataStatus,
  };
};
