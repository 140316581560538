/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { Feature, FeatureCollection } from '@turf/turf';
import { FlyToInterpolator, RGBAColor } from '@deck.gl/core';
import { GeoJsonLayer } from '@deck.gl/layers';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedFeatureAtom } from '../Incident/FeaturePopup';
import { primaryKeyForSection } from '../Post/PostLayer';
import { viewStateAtom } from './atoms';
import { DataFilterExtension } from '@deck.gl/extensions';
import { selectedRunTimeAtom } from '../Incident/atoms';
import React from 'react';
import {
  layerVisibility,
  selectedElevationKeyAtom,
  selectedElevationScaleAtom,
} from './atoms';

type LayerInputHandler = any;

type Props<T> = {
  id: string;
  incidentId?: string;
  highlightId?: string | number;
  data: T[];
  extruded: boolean;
  getFillColor?: ((d: T) => RGBAColor) | RGBAColor;
  getElevation?: (d: T) => number;
  getLineColor?: ((d: T) => RGBAColor) | RGBAColor;
  getFilterValue?: (d: T) => number;
  stroked: boolean;
  pickable?: boolean;
  visible: boolean;
  primaryKey: string;
  elevationKey?: string;
  onClick?: LayerInputHandler;
};

export function useGridCellLayer<T extends FeatureCollection | Feature>(
  props: Props<T>
) {
  const {
    data,
    visible,
    onClick,
    id,
    incidentId,
    pickable,
    extruded,
    stroked,
    getFillColor,
    getFilterValue,
    getLineColor,
    highlightId,
    ...rest
  } = props;

  const [selectedFeature] = useRecoilState(selectedFeatureAtom);
  const setViewState = useSetRecoilState(viewStateAtom);
  const elevationScale = useRecoilValue(selectedElevationScaleAtom);
  const primaryKey = useRecoilValue(primaryKeyForSection(id));
  const selectedElevationKey = useRecoilValue(selectedElevationKeyAtom);
  const range =
    useRecoilValue(selectedRunTimeAtom(`${incidentId}-${id}`))?.time ?? 0;

  const visibleByUser = useRecoilValue(layerVisibility(id));

  const percentileData = useMemo(() => {
    if (primaryKey == null || getFillColor != null) {
      return [];
    }

    const result = [];
    for (const chunk of data) {
      if (chunk.type === 'FeatureCollection') {
        for (const f of chunk.features) {
          // @ts-ignore
          const { lastupdatedon, LastUpdatedOn } = f.properties;
          const primary = f?.properties?.[primaryKey];
          const lastUpdatedOn = lastupdatedon ?? LastUpdatedOn;

          if (id !== 'wow-post') {
            if (
              primary == null ||
              (lastUpdatedOn != null && lastUpdatedOn !== range)
            ) {
              continue;
            }
          }
          result.push(f?.properties?.[primaryKey] ?? 0);
        }
      } else if (chunk.type === 'Feature') {
        result.push(chunk.properties?.[primaryKey] ?? 0);
      }
    }
    result.sort((a, b) => a - b);

    const percentRankings = result.reduce((acc, cur, i) => {
      if (acc[cur] == null) {
        acc[cur] = i / result.length;
      }
      return acc;
    }, {});

    return percentRankings;
  }, [data, primaryKey, range, getFillColor, id]);

  const getPercentileRankFillColor = useCallback(
    (d: T): RGBAColor => {
      if (d.type === 'Feature' && visible && primaryKey) {
        if (d.properties?.[primaryKey] == null) {
          return [0, 0, 0, 0];
        }

        const percentileRank = percentileData[d?.properties?.[primaryKey] ?? 0];

        if (percentileRank === 0 || percentileRank == null) {
          return [0, 0, 0, 0];
        }
        if (percentileRank < 0.2) {
          return colorRange[4];
        }
        if (percentileRank < 0.4) {
          return colorRange[3];
        }
        if (percentileRank < 0.6) {
          return colorRange[2];
        }
        if (percentileRank < 0.8) {
          return colorRange[1];
        }
        if (percentileRank < 1) {
          return colorRange[0];
        }
        return colorRange[0];
      }
      return [0, 0, 0, 0];
    },
    [percentileData, primaryKey, visible]
  );

  const handleLineColor = React.useCallback(
    (d: any) => {
      if (getLineColor != null) {
        if (typeof getLineColor === 'function') {
          return getLineColor(d);
        }
        return getLineColor;
      }
      // @ts-ignore
      return d?.id === (props?.highlightId ?? selectedFeature?.id)
        ? [255, 255, 0, 100]
        : [0, 0, 0, 0];
    },
    [props.highlightId, selectedFeature?.id, getLineColor]
  );

  const handleClick = React.useCallback(
    (info: any, event: any) => {
      if (visible && info?.object) {
        if (onClick) {
          onClick(info, event);
        }
        setViewState(view => ({
          ...view,
          maxPitch: 85,
          longitude: info?.coordinate?.[0],
          latitude: info?.coordinate?.[1],
          transitionInterpolator: new FlyToInterpolator({ speed: 3.0 }),
          transitionDuration: 1000,
        }));
      }
    },
    [visible, setViewState, onClick]
  );

  const handleElevation = React.useCallback(
    (d: any) => {
      // if (getElevation) {
      //   return getElevation(d);
      // }

      const value = d.properties[selectedElevationKey];
      if (typeof value === 'number') {
        return value;
      }
      return 0;
    },
    [selectedElevationKey]
  );

  const handleFilterValue = React.useCallback(
    (f: any) => {
      if (getFilterValue) {
        return getFilterValue(f);
      } else {
        if (id !== 'wow-post') {
          return new Date(
            f.properties?.lastupdatedon ?? f.properties?.LastUpdatedOn ?? 0
          ).valueOf();
        }
      }
    },
    [getFilterValue, id]
  );

  const handleLineWidth = React.useCallback(
    (d: any | null): number => {
      if (d?.id === selectedFeature?.id) {
        return 100;
      }
      return 100;
    },
    [selectedFeature]
  );

  const layers = React.useMemo(() => {
    return data.map((geojson, index) => {
      return new GeoJsonLayer<T>({
        // @ts-ignore
        id: `${id}-${index}-${geojson?.features?.length ?? 0}-layer`,
        autoHighlight: false,
        data: geojson,
        elevationScale: elevationScale,
        extruded,
        filled: true,
        // @ts-ignore

        getLineColor: handleLineColor,
        getLineWidth: handleLineWidth,
        getElevation: handleElevation,
        getFillColor: getFillColor ?? getPercentileRankFillColor,
        highlightColor: [255, 255, 0, 50],
        material: true,
        pickable: pickable ?? true,
        stroked: true,
        visible: visible && visibleByUser,
        // ...filterProps,
        getFilterValue: handleFilterValue,
        filterRange: [range ?? 0, range ?? 0],
        extensions: [new DataFilterExtension({ filterSize: 1 })],
        wireframe: true,
        ...rest,
        updateTriggers: {
          onClick: visible,
          getElevation: [extruded, handleElevation],
          getFillColor: [primaryKey, highlightId],
          getLineColor: [highlightId, selectedFeature?.id],
          getLineWidth: [highlightId, selectedFeature?.id],
          elevationScale: [extruded, elevationScale],
        },
        onClick: handleClick,
      });
    });
  }, [
    data,
    handleFilterValue,
    handleLineWidth,
    elevationScale,
    extruded,
    handleElevation,
    getFillColor,
    getPercentileRankFillColor,
    handleClick,
    highlightId,
    id,
    pickable,
    primaryKey,
    range,
    selectedFeature?.id,
    visible,
    visibleByUser,
    rest,
    handleLineColor,
  ]);

  return layers;
}

const colorRange: RGBAColor[] = [
  [181, 9, 9, 190], // #B50909
  [219, 129, 0, 190], // #DB8100
  [226, 178, 0, 190], // #E2B200
  [143, 195, 201, 190], // #8FC3C9
  [95, 133, 188, 190], // #5F85BC
];
