/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { bbox, Feature, featureCollection, Polygon } from '@turf/turf';
import { canopyChunksSelector, useSetPostDataChunksUsers } from './selectors';
import { CanopyProperties } from './types';
import { FlyToInterpolator, RGBAColor } from '@deck.gl/core';
import { useGridCellLayer } from '../map/GridCellLayer';
import React from 'react';
import { primaryKeyForSection } from '../Post/PostLayer';
import { viewStateAtom } from '../map/atoms';
import { useSearchParams } from 'react-router-dom';
import { WebMercatorViewport } from 'react-map-gl';
import { selectedFeatureAtom } from '../Incident/FeaturePopup';

const viewport = new WebMercatorViewport({
  width: 600,
  height: 400,
  longitude: -122.45,
  latitude: 37.78,
  zoom: 12,
  pitch: 30,
  bearing: 15,
});

type Props = {
  extruded: boolean;
  visible: boolean;
};

export const useCanopyLayer = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const postData = useRecoilValueLoadable(canopyChunksSelector);
  const callback = useSetPostDataChunksUsers(postData?.contents ?? []);
  const [selectedPost] = useRecoilState(selectedFeatureAtom);
  const setViewState = useSetRecoilState(viewStateAtom);
  const primaryKey = useRecoilValue(primaryKeyForSection('canopy-post'));
  const canopyKmlId = searchParams.get('canopy_kml_id');

  React.useEffect(() => {
    const contents = postData.contents;
    if (postData.state === 'hasValue') {
      if (
        contents.length === 0 ||
        contents?.[contents.length - 1]?.properties?.exceededTransferLimit
      ) {
        callback();
      } else {
        if (props.visible && canopyKmlId == null) {
          const [minLng, minLat, maxLng, maxLat] = bbox(contents[0]);

          const viewstate = viewport.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            {
              padding: 20,
            }
          );

          setViewState({
            latitude: viewstate.latitude,
            longitude: viewstate.longitude,
            zoom: viewstate.zoom,
            bearing: viewstate.bearing,
            transitionInterpolator: new FlyToInterpolator(),
            transitionDuration: 1000,
          });
        }
      }
    }
  }, [postData, callback, props.visible, setViewState, canopyKmlId]);

  const canopyFeature = React.useMemo(() => {
    if (postData.state === 'hasValue' && postData.contents.length) {
      const canopyFeatures = postData.contents
        .flatMap(chunk => chunk.features.map(feature => feature))
        .filter(feature => {
          return feature.properties.kml_id === canopyKmlId;
        });

      return featureCollection(canopyFeatures);
    }

    return featureCollection([]);
  }, [postData, canopyKmlId]);

  React.useEffect(() => {
    if (canopyFeature.features.length > 0 && canopyKmlId != null) {
      const [minLng, minLat, maxLng, maxLat] = bbox(canopyFeature);

      searchParams.set('canopy_kml_id', canopyKmlId);
      searchParams.set('bbox', `${minLng},${minLat},${maxLng},${maxLat}`);
      setSearchParams(searchParams, {
        replace: true,
        state: {
          internal: true,
        },
      });
    }
  }, [searchParams, canopyFeature, setViewState, canopyKmlId, setSearchParams]);

  const getFillColor = React.useCallback(
    (d: Feature<Polygon, CanopyProperties>): RGBAColor => {
      if (selectedPost?.id === d.id) {
        return [0, 255, 100, 180];
      }

      return getPostFillColor(d);
    },
    [selectedPost]
  );

  const GridCellLayer = useGridCellLayer<Feature<Polygon, CanopyProperties>>({
    id: 'canopy-post',
    // @ts-ignore
    data: postData.state === 'hasValue' ? postData.contents : [],
    getFillColor: primaryKey === 'priority_rank' ? getFillColor : undefined,
    extruded: props.extruded,
    visible: props.visible,
  });

  if (postData.contents.length === 0) {
    return null;
  }

  return GridCellLayer;
};

const postColorRange: RGBAColor[] = [
  [181, 9, 9, 190], // #B50909
  [219, 129, 0, 190], // #DB8100
  [226, 178, 0, 190], // #E2B200
  [143, 195, 201, 190], // #8FC3C9
  [95, 133, 188, 190], // #5F85BC
];

export const getPostFillColor = (
  d: Feature<Polygon, CanopyProperties>
): RGBAColor => {
  const { priority_rank } = d.properties;
  switch (priority_rank) {
    case 1:
      return postColorRange[0];
    case 2:
      return postColorRange[1];
    case 3:
      return postColorRange[2];
    case 4:
      return postColorRange[3];
    case 5:
      return postColorRange[4];
    default:
      return [0, 0, 0, 0];
  }
};

export const postKeyToLabel = {
  event_id: 'Grouped IPAWS event',
  usng5km: '5km USNG cell',
  state: 'State',
  county: 'County',
  fips: 'County FIPS code',
  ls_day_pop: 'LandScan Daytime Population',
  ls_night_pop: 'LandScan Nightime Population',
  pop: 'Population',
  households: 'Number of Households',
  housingunits: 'Number of Housing units',
  pop65over: 'Population age 65 and over',
  pop16overunemployed: 'Population age 16+ and unemployed',
  pop16overnotinlaborforce: 'Population 16+ not in labor force',
  popamindianalaskan: 'Population American Indian or Alaska Native',
  householdsinpoverty: 'Number of Households in poverty',
  householdsdsfs: 'Number of Households on disability and food stamps',
  householdsdsnofs: 'Number of Households on disability no food stamps',
  householdswithfs: 'Number of Households with food stamps/SNAP',
  householdswithpa: 'Number of Households with public assistance',
  housingunitsmobilehomes: 'Number of Housing units that are mobile homes',
  lep: 'Number of Households with Limited English Proficiency',
  event: 'IPWAS event type',
  effective: 'Effective',
  expires: 'Expires',
  quantile_sum: 'Rank Sum',
  final_rank: 'Unscaled Rank',
  priority_rank: 'POST Priority',
  run_time: 'POST run time',
  CommercialOfficeImpacts: 'Commercial Office Impact',
  ExposureScore: 'Priority Rank',
  ImpactScore: 'Impact Score',
  PercentOut: 'Percent Out',
  RankPotentialPowerOutageImpact: 'Rank Potential Power Outage Impact',
  ef_rank: 'Enhanced Fujita Rank',
  state_intersect: 'State Intersect',
} as const;
