/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React from 'react';
import {
  AppBar,
  Breadcrumbs,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link, useMatch } from 'react-router-dom';
// import { ReactComponent as Logo } from '../images/fema-logo-blue.svg';
import { ReactComponent as TempoLogo } from '../images/tempo_full_color_DKGray.svg';
import { incidentPageSections } from '../pages/IncidentPage';
import { DrawerButton } from './MapDrawer';
import { configSelector } from '../config/api';
import { useRecoilState, useRecoilValue } from 'recoil';
import ArcGISAuth from '../auth/ArcGISAuth';
import { userAtom, userSessionAtom } from '../auth/userAtoms';
import { UserSession } from '@esri/arcgis-rest-auth';

const Header = () => {
  const match = useMatch({
    path: ':incidentId/:sectionId/:viewType/:attributeId?',
  });
  const matches = useMediaQuery('(min-width:1000px)');
  const desktop = useMediaQuery('(min-width: 769px)');
  const section = incidentPageSections.find(
    section => match?.params.sectionId === section.key
  );
  const config = useRecoilValue(configSelector);
  const currentIncident = config.incidents.find(
    incident => incident.id === match?.params.incidentId
  );
  const [user, setUser] = useRecoilState(userAtom);
  const [, setUserSession] = useRecoilState(userSessionAtom);

  const handleLogin = React.useCallback(
    async (token: string) => {
      // Complete the OAuth 2.0 flow and create a UserSession
      const session = new UserSession({
        token: token,
        portal: 'https://www.arcgis.com/sharing/rest',
        clientId: process.env.REACT_APP_ARCGIS_CLIENT_ID ?? '',
      });

      const user = await session.getUser();
      if (user) {
        setUser({ ...user, token: token });
      }

      setUserSession(session);
    },
    [setUser, setUserSession]
  );

  React.useEffect(() => {
    // Check for OAuth 2.0 response parameters in localStorage
    const oauthParams = localStorage.getItem('arcgisOAuth');
    const token = JSON.parse(oauthParams ?? '{}').access_token;

    if (token) {
      handleLogin(token);
      // Remove OAuth 2.0 response parameters from localStorage
      // localStorage.removeItem('arcgisOAuth');
    }
  }, [handleLogin]);

  return (
    <AppBar
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
        position: 'sticky',
        boxShadow: 'none',
        top: 0,
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#fff',
          color: '#005288',
          padding: '0.2rem 0',
          height: '100%',
        }}
      >
        <StyledLink
          color="inherit"
          to="/"
          sx={{
            height: '50px',
            '@media (max-height: 900px)': {
              height: '50px',
            },
          }}
        >
          <TempoLogo
            height={'100%'}
            style={{ paddingRight: 10 }}
            aria-label="TEMPO"
          />
        </StyledLink>
        <Typography
          variant="body1"
          style={{
            flexGrow: 1,
            color: 'rgb(196, 18, 48)',
            fontWeight: 'bold',
          }}
        >
          In Development
        </Typography>
        {matches && (
          <>
            <Spacer />
            <Typography variant="h6">
              Tool for Emergency Management Prioritization & Operations
            </Typography>
          </>
        )}
      </Toolbar>
      <Toolbar variant="dense">
        <DrawerButton />
        <Breadcrumbs
          sx={{ color: 'white', fontSize: desktop ? '17px' : '14px' }}
          aria-label="breadcrumb"
        >
          <StyledLink color="inherit" to="/">
            Dashboard
          </StyledLink>
          {match?.params.incidentId && (
            <StyledLink
              color="inherit"
              to={`/${match.params.incidentId}/population/map`}
            >
              {currentIncident?.name ?? match.params.incidentId}
            </StyledLink>
          )}
          {match != null && (
            <Typography
              color="white"
              sx={{ fontSize: desktop ? '17px' : '14px' }}
            >
              {section?.title}
            </Typography>
          )}
        </Breadcrumbs>
        <Spacer />
        <Link
          color="inherit"
          to="/wowcast"
          style={{
            color: 'white',
            backgroundColor: 'rgb(0, 120, 174)',
            padding: '0.5rem 1rem',
            marginRight: '1rem',
            borderRadius: 2,
            textDecoration: 'none',
          }}
        >
          WOWcast
        </Link>
        <Link
          to="/subscriptions"
          style={{
            color: 'white',
            backgroundColor: 'rgb(0, 120, 174)',
            padding: '0.5rem 1rem',
            marginRight: '1rem',
            borderRadius: 2,
            textDecoration: 'none',
          }}
        >
          TEMPO Alerts
        </Link>

        <ArcGISAuth
          user={user}
          onLogin={userSession => {
            setUserSession(userSession);
            // setUser(user);
          }}
        />
      </Toolbar>
    </AppBar>
  );
};

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Spacer = styled('div')`
  flex-grow: 1;
`;

export default Header;
