/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { centroid, Feature, Polygon } from '@turf/turf';
import { nifcDataChunksAtom, useSetNifcDataChunks } from './selectors';
import { NifcPerimeterProperties, NifcPostProperties } from './types';
import { RGBAColor } from '@deck.gl/core/utils/color';
import { primaryKeyForSection } from '../Post/PostLayer';
import { useGridCellLayer } from '../map/GridCellLayer';
import { debouncedViewStateAtom } from '../map/atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import React from 'react';
import { useMatch } from 'react-router-dom';
import { TextLayer } from '@deck.gl/layers';
import { selectedFeatureAtom } from '../Incident/FeaturePopup';

type Props = {
  extruded: boolean;
  visible: boolean;
};

const VISIBLE_ZOOM = 7;

export const useNifcLayer = (props: Props) => {
  const [perimeterStatus, setPerimeterStatus] = React.useState('init');
  const [viewState] = useRecoilState(debouncedViewStateAtom);
  const [selectedPost] = useRecoilState(selectedFeatureAtom);
  const primaryKey = useRecoilValue(primaryKeyForSection('nifc-post'));

  const nifcPerimeters = useRecoilValue(
    nifcDataChunksAtom<NifcPerimeterProperties>('perimeter')
  );
  const perimetersCallback = useSetNifcDataChunks('perimeter', nifcPerimeters);
  const perimeterExceededTransferLimit =
    nifcPerimeters?.[nifcPerimeters.length - 1]?.properties
      ?.exceededTransferLimit;
  const match = useMatch({ path: '/' });

  React.useEffect(() => {
    let ignore = false;
    if (!ignore && match) {
      switch (perimeterStatus) {
        case 'init':
          setPerimeterStatus('loading');
          return;
        case 'loading':
          perimetersCallback();
          setPerimeterStatus('loaded');
          return;
        case 'loaded':
          if (perimeterExceededTransferLimit) {
            setPerimeterStatus('loading');
          }
          return;
        default:
          return;
      }
    }
    return () => {
      ignore = true;
    };
  }, [
    perimeterStatus,
    perimetersCallback,
    perimeterExceededTransferLimit,
    match,
  ]);

  const [postStatus, setPostStatus] = React.useState('init');
  const nifcPost = useRecoilValue(
    nifcDataChunksAtom<NifcPostProperties>('post')
  );
  const postCallback = useSetNifcDataChunks('post', nifcPost);
  const postExceededTransferLimit =
    nifcPost?.[nifcPost.length - 1]?.properties?.exceededTransferLimit;

  React.useEffect(() => {
    let ignore = false;
    if (!ignore && props.visible) {
      switch (postStatus) {
        case 'init':
          setPostStatus('loading');
          return;
        case 'loading':
          postCallback();
          setPostStatus('loaded');
          return;
        case 'loaded':
          if (postExceededTransferLimit) {
            setPostStatus('loading');
          }
          return;
        default:
          return;
      }
    }
    return () => {
      ignore = true;
    };
  }, [postStatus, postCallback, postExceededTransferLimit, props.visible]);

  const fillColor = React.useMemo(
    () => (primaryKey === 'priority_rank' ? getPostFillColor : undefined),
    [primaryKey]
  );

  const NifcGridCellLayer = useGridCellLayer<
    Feature<Polygon, NifcPostProperties>
  >({
    id: 'nifc-post',
    // @ts-ignore
    data: nifcPost,
    getFillColor: fillColor,
    extruded: props.extruded,
    visible: props.visible,
    highlightId: selectedPost?.id,
  });

  const NifcPerimeterLayer = useGridCellLayer<
    Feature<Polygon, NifcPerimeterProperties>
  >({
    id: 'nifc-perimeter',
    // @ts-ignore
    data: nifcPerimeters,
    pickable: true,
    getFillColor: perimeterFillColor,
    extruded: false,
    visible: props.visible,
  });

  const nifcLabelLayer = new TextLayer<
    Feature<Polygon, NifcPerimeterProperties>
  >({
    id: 'nifc-text-layer',
    // @ts-ignore
    data: nifcPerimeters.flatMap(p => p.features),
    pickable: false,
    getPosition: d => {
      const post = centroid(d).geometry.coordinates;
      return [post[0], post[1]];
    },
    getText: d => {
      return d.properties?.IncidentName;
    },
    billboard: true,
    getSize: 12,
    getAngle: 0,
    getTextAnchor: 'middle',
    getAlignmentBaseline: 'center',
    visible:
      viewState?.zoom && viewState.zoom > VISIBLE_ZOOM ? props.visible : false,
  });

  return [NifcPerimeterLayer, NifcGridCellLayer, nifcLabelLayer];
};
const perimeterFillColor = [181, 9, 9, 130] as RGBAColor;
// const perimeterLineColor = [181, 9, 9, 200] as RGBAColor;

const postColorRange: RGBAColor[] = [
  [181, 9, 9, 190], // #B50909
  [219, 129, 0, 190], // #DB8100
  [226, 178, 0, 190], // #E2B200
  [143, 195, 201, 190], // #8FC3C9
  [95, 133, 188, 190], // #5F85BC
];

export const getPostFillColor = (
  d: Feature<Polygon, NifcPostProperties>
): RGBAColor => {
  const { priority_rank } = d.properties;
  switch (priority_rank) {
    case 1:
      return postColorRange[0];
    case 2:
      return postColorRange[1];
    case 3:
      return postColorRange[2];
    case 4:
      return postColorRange[3];
    case 5:
      return postColorRange[4];
    default:
      return [0, 0, 0, 0];
  }
};
