/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import {
  atom,
  atomFamily,
  DefaultValue,
  noWait,
  selectorFamily,
  useRecoilCallback,
} from 'recoil';
import { esriParamsObject, esriListConfigParams } from '../esri';
import { IncidentConfig } from '../types';
import { IncidentState } from './types';
// @ts-ignore
import arcgisPbfDecode from 'arcgis-pbf-parser';
import { FeatureCollection } from '@turf/helpers';
import { featureCollection, Geometry } from '@turf/turf';
import { useSetRecoilState } from 'recoil';
import React from 'react';

export const countyChartDataAtom = atomFamily<
  {
    labels: string[];
    data: any[];
    comments?: string[];
    chartDataType: 'sum' | 'percent';
    yAttribute: string;
  } | null,
  { incidentId?: string; sectionId?: string }
>({
  key: 'countyChartStatsData',
  default: {
    yAttribute: '',
    labels: [],
    data: [],
    chartDataType: 'sum',
  },
});

export type IncidentDataKeys =
  | typeof atRiskSections[number]['key']
  | typeof resourceTrackerSections[number]['key']
  | typeof communityLifeSections[number]['key']
  | 'county';

const BASE_URL =
  'https://gis.fema.gov/arcgis/rest/services/TEMPO/TEMPO/FeatureServer/{0}/query';

const INCIDENT_FEATURESERVERS: Record<IncidentDataKeys, string> = {
  energy: BASE_URL.replace('{0}', '11'),
  population: BASE_URL.replace('{0}', '7'),
  transportation: BASE_URL.replace('{0}', '5'),
  commodities: BASE_URL.replace('{0}', '8'),
  foodWaterShelter: BASE_URL.replace('{0}', '0'),
  residential: BASE_URL.replace('{0}', '12'),
  nonResidentialStructures: BASE_URL.replace('{0}', '13'),
  personnel: `${BASE_URL}IPAWS/FeatureServer/8/query`,
  declarations: `https://services.arcgis.com/XG15cJAlne2vxtgt/arcgis/rest/services/TEMPO_affected_counties/FeatureServer/0/query`,
  communications: BASE_URL.replace('{0}', '4'),
  commerce: `${BASE_URL}IPAWS/FeatureServer/11/query`,
  healthMedical: BASE_URL.replace('{0}', '9'),
  county:
    'https://services.arcgis.com/XG15cJAlne2vxtgt/arcgis/rest/services/county_metrics_with_landscan_populations/FeatureServer/0/query',
};

const sectionIds = [
  'population',
  'commodities',
  'residential',
  'nonResidentialStructures',
  'declarations',
  'communications',
] as const;

export const allSectionsDataAvailabilitySelector = selectorFamily<
  Record<IncidentDataKeys, boolean>,
  string | undefined
>({
  key: 'allSectionsDataAvailability',
  get:
    incidentId =>
    ({ get }) => {
      const dataAvailability: Record<IncidentDataKeys, boolean> = {
        energy: false,
        population: false,
        transportation: false,
        commodities: false,
        foodWaterShelter: false,
        residential: false,
        nonResidentialStructures: false,
        personnel: false,
        declarations: false,
        communications: false,
        commerce: false,
        healthMedical: false,
        county: false,
      };

      if (incidentId == null) {
        return dataAvailability;
      }

      for (const sectionId of sectionIds) {
        const sectionData = get(
          incidentSectionStats({
            incidentId: incidentId,
            sectionId: sectionId,
          })
        );
        dataAvailability[sectionId] = sectionData.features.length > 0;
      }
      return dataAvailability;
    },
});

export async function asyncGetIncidentData(
  type: IncidentDataKeys,
  query: string,
  offset: number = 0,
  lastUpdatedOn?: number,
  comments?: string
) {
  const limit = 2000;
  let searchParams = new URLSearchParams(esriParamsObject);

  if (type !== 'county') {
    if (comments != null) {
      searchParams.set(
        'where',
        searchParams.get('where') +
          ` AND event='${query}' AND comments='${comments}'`
      );
    } else if (lastUpdatedOn != null) {
      const dateTimeArray = new Date(new Date(lastUpdatedOn).toUTCString())
        .toISOString()
        .split('T');

      const date = dateTimeArray[0];
      const time = dateTimeArray[1].split('.')[0];

      searchParams.set(
        'where',
        searchParams.get('where') +
          ` AND event='${query}' AND lastupdatedon=DATE '${date} ${time}'`
      );
    } else {
      searchParams.set(
        'where',
        searchParams.get('where') + ` AND EVENT='${query}'`
      );
    }
  }

  try {
    const response = await fetch(
      `${
        INCIDENT_FEATURESERVERS[type]
      }?${searchParams.toString()}&resultRecordCount=${limit}&resultOffset=${offset}`
    );

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    const buffer = await response.arrayBuffer();
    const data = arcgisPbfDecode(new Uint8Array(buffer));

    if (data.error) {
      console.error(type, data.error);
      return null;
    }

    return {
      ...data.featureCollection,
      exceededTransferLimit: data?.exceededTransferLimit ?? false,
      properties: {
        exceededTransferLimit: data?.exceededTransferLimit ?? false,
      },
    };
  } catch (error) {
    return featureCollection([]);
  }
}

export async function asyncGetIncidentNames(
  sectionId: IncidentDataKeys,
  incidentId: string
) {
  const searchParams = new URLSearchParams(esriListConfigParams(incidentId));
  searchParams.set('where', `EVENT='${incidentId}'`);
  searchParams.set('returnGeometry', 'false');
  searchParams.set('returnDistinctValues', 'true');

  if (supportComments.includes(sectionId)) {
    searchParams.set('outFields', 'event,lastupdatedon,comments');
  } else {
    searchParams.set('outFields', 'event,lastupdatedon');
  }

  if (sectionId === 'healthMedical') {
    searchParams.set('orderByFields', 'lastupdatedon desc');
  }

  try {
    const response = await fetch(
      `${INCIDENT_FEATURESERVERS[sectionId]}?${searchParams.toString()}`
    );

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    const data = (await response.json()) as FeatureCollection<
      null,
      { event: string; lastupdatedon: number; comments: string }
    >;

    return data;
  } catch (error) {
    return { features: [] };
  }
}

type CountyProperties = {
  county: string;
  NAME: string;
  counties_name: string;
  counties_state_name: string;
  name: string;
  state_name: string;
};

type CountyPropertyFormats =
  | {
      type: 'foodWaterShelter';
      props: {
        counties_name: string;
        counties_state_name: string;
      };
    }
  | { type: 'declarations'; props: { NAME: string; STATE_NAME: string } }
  | { type: 'communications'; props: { name: string; state_name: string } }
  | { type: 'commodoities'; props: { county: string; state: string } }
  | { type: 'default'; props: { county: string } };

export async function asyncGetIncidentCounties(
  sectionId: IncidentDataKeys,
  incidentId: string
): Promise<FeatureCollection<null, { county: string }>> {
  const searchParams = new URLSearchParams(esriListConfigParams(incidentId));
  searchParams.set('where', `event='${incidentId}'`);
  searchParams.set('returnGeometry', 'false');

  if (sectionId === 'communications') {
    searchParams.set('outFields', 'name,state_name');
  } else if (sectionId === 'foodWaterShelter') {
    searchParams.set('outFields', 'counties_name,counties_state_name');
  } else if (sectionId === 'declarations') {
    searchParams.set('outFields', 'NAME,STATE_NAME');
  } else if (sectionId === 'commodities') {
    searchParams.set('outFields', 'county,state');
  } else {
    searchParams.set('outFields', 'county');
  }

  function handleCountyFormat(county: CountyPropertyFormats) {
    switch (county.type) {
      case 'foodWaterShelter':
        return `${county.props.counties_name}, ${county.props.counties_state_name}`;
      case 'declarations':
        return `${county.props.NAME}, ${county.props.STATE_NAME}`;
      case 'communications':
        return `${county.props.name}, ${county.props.state_name}`;
      case 'commodoities':
        return `${county.props.county}, ${county.props.state}`;
      default:
        return county.props.county;
    }
  }

  const response = await fetch(
    `${INCIDENT_FEATURESERVERS[sectionId]}?${searchParams.toString()}`
  );

  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}`);
  }
  try {
    const data = (await response.json()) as FeatureCollection<
      null,
      CountyProperties
    >;

    const features = data.features.map(f => ({
      ...f,
      properties: {
        // @ts-ignore
        county: handleCountyFormat({
          // @ts-ignore
          type: ['foodWaterShelter', 'communications', 'declarations'].includes(
            sectionId
          )
            ? (sectionId as CountyPropertyFormats['type'])
            : 'default',
          props: f.properties,
        }),
      },
    }));

    return featureCollection(features);
  } catch (error) {
    return featureCollection([]);
  }
}

export const incidentCountiesSelector = selectorFamily<
  string[],
  { incidentId: string; sectionId: IncidentDataKeys }
>({
  key: 'incidentCountiesSelector',
  get:
    ({ incidentId, sectionId }) =>
    async () => {
      const data = await asyncGetIncidentCounties(sectionId, incidentId);

      return data.features.map(feature => feature.properties.county);
    },
});

type OutputStatistics = {
  statisticType: 'count' | 'sum' | 'min' | 'max' | 'avg' | 'stddev';
  onStatisticField: string;
  outStatisticFieldName: string;
};

type IncidentStats = {
  displayFieldName: string;
  features: {
    attributes: {
      [key: string]: number | string;
    };
  }[];
  fields: {
    name: string;
    type: 'esriFieldTypeOID' | 'esriFieldTypeString' | 'esriFieldTypeDouble';
    alias: string;
  }[];
};

export const firstRunAtom = atom({
  key: 'firstRun',
  default: true,
});

export const extentStateAtom = atom<'idle' | 'zoom-to-extent'>({
  key: 'extentState',
  default: 'idle',
});

export async function asyncGetIncidentStatistics(
  incidentId: string,
  sectionId: IncidentDataKeys,
  groupByFieldsForStatistics: string,
  outputStatistics: OutputStatistics[],
  counties?: string[],
  states?: string[],
  geometry?: { xmin: number; ymin: number; xmax: number; ymax: number }
): Promise<IncidentStats> {
  let searchParams = new URLSearchParams();

  searchParams.set('where', `event='${incidentId}'`);

  if (counties != null && counties.length > 0) {
    searchParams.set(
      'where',
      searchParams.get('where') +
        ` AND county IN (${counties.map(c => `'${c}'`).join(',')})`
    );
  }

  if (states != null && states.length > 0) {
    searchParams.set(
      'where',
      searchParams.get('where') +
        ` AND state IN (${states.map(s => `'${s}'`).join(',')})`
    );
  }

  if (geometry) {
    searchParams.set(
      'geometry',
      `${geometry.xmin},${geometry.ymin},${geometry.xmax},${geometry.ymax}`
    );
    searchParams.set('geometryType', 'esriGeometryEnvelope');
    searchParams.set('inSR', '4326');
    searchParams.set('spatialRel', 'esriSpatialRelIntersects');
  }

  searchParams.set('groupByFieldsForStatistics', groupByFieldsForStatistics);
  searchParams.set(
    'outStatistics',
    JSON.stringify(outputStatistics).replace('\\"', '')
  );

  searchParams.set('f', 'pjson');

  try {
    const response = await fetch(
      `${INCIDENT_FEATURESERVERS[sectionId]}?${searchParams.toString()}`
    );

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    const json = await response.json();

    if (json.error) {
      console.error(sectionId, json.error);
      return {
        displayFieldName: '',
        features: [],
        fields: [],
      };
    }

    return json;
  } catch (error) {
    return {
      displayFieldName: '',
      features: [],
      fields: [],
    };
  }
}

export const dataChunksAtom = atomFamily<IncidentState, string>({
  key: 'incidentDataChunks',
  default: {
    energy: { offset: 0, data: [] },
    population: { offset: 0, data: [] },
    transportation: { offset: 0, data: [] },
    commodities: { offset: 0, data: [] },
    foodWaterShelter: { offset: 0, data: [] },
    residential: { offset: 0, data: [] },
    nonResidentialStructures: { offset: 0, data: [] },
    personnel: { offset: 0, data: [] },
    declarations: { offset: 0, data: [] },
    communications: { offset: 0, data: [] },
    commerce: { offset: 0, data: [] },
    healthMedical: { offset: 0, data: [] },
    county: { offset: 0, data: [] },
  },
});

type IncidentRuntime = {
  comments?: string;
  event: string;
  lastupdatedon: number;
};

export const availableRuntimesAtom = atomFamily<IncidentRuntime[], string>({
  key: 'availableRuntimes',
  default: [],
});

export function getOutputStatisticsBySectionId(
  sectionId: string
): (OutputStatistics & { countyFieldName?: string })[] {
  switch (sectionId) {
    case 'population':
      return [
        {
          statisticType: 'sum',
          onStatisticField: 'ls_night_pop',
          outStatisticFieldName: 'ls_night_pop',
          countyFieldName: 'land_scan_night_pop',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'housingunits',
          outStatisticFieldName: 'housingunits',
          countyFieldName: 'hunits',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'pop65over',
          outStatisticFieldName: 'pop65over',
          countyFieldName: 'age65',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'lep',
          outStatisticFieldName: 'lep',
          countyFieldName: 'sum_lep',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'householdswithfs',
          outStatisticFieldName: 'householdswithfs',
          countyFieldName: 'hfsnap',
        },

        {
          statisticType: 'sum',
          onStatisticField: 'householdswithpa',
          outStatisticFieldName: 'householdswithpa',
          countyFieldName: 'hpa',
        },
      ];
    case 'residential':
      return [
        {
          statisticType: 'sum',
          onStatisticField: 'residential',
          outStatisticFieldName: 'residential',
        },
      ];
    case 'nonResidentialStructures':
      return [
        {
          statisticType: 'sum',
          onStatisticField: 'agriculturalru',
          outStatisticFieldName: 'agriculturalru',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'commercialoff',
          outStatisticFieldName: 'commercialoff',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'exemptgovernm',
          outStatisticFieldName: 'exemptgovernm',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'industrialgen',
          outStatisticFieldName: 'industrialgen',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'commercialret',
          outStatisticFieldName: 'commercialret',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'recreational',
          outStatisticFieldName: 'recreational',
        },
      ];

    case 'communications':
      return [
        {
          statisticType: 'avg',
          onStatisticField: 'percentout',
          outStatisticFieldName: 'percentout',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'cell_sites_out_due_to_power',
          outStatisticFieldName: 'cell_sites_out_due_to_power',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'cell_sites_out_due_to_transpor',
          outStatisticFieldName: 'cell_sites_out_due_to_transpor',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'cell_sites_out_due_to_damage',
          outStatisticFieldName: 'cell_sites_out_due_to_damage',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'populationaffected',
          outStatisticFieldName: 'populationaffected',
        },
      ];

    case 'energy':
      return [
        {
          statisticType: 'sum',
          onStatisticField: 'has_fuel__no_power',
          outStatisticFieldName: 'has_fuel__no_power',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'no_fuel__has_power',
          outStatisticFieldName: 'no_fuel__has_power',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'no_fuel__no_power',
          outStatisticFieldName: 'no_fuel__no_power',
        },
      ];

    case 'foodWaterShelter':
      return [
        {
          statisticType: 'avg',
          onStatisticField: 'sheltercapacitypercent',
          outStatisticFieldName: 'sheltercapacitypercent',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'popinopenshelters',
          outStatisticFieldName: 'popinopenshelters',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'boilwateraffectedpopulation',
          outStatisticFieldName: 'boilwateraffectedpopulation',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'sheltersclosed',
          outStatisticFieldName: 'sheltersclosed',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'sheltersopen',
          outStatisticFieldName: 'sheltersopen',
        },
      ];

    case 'healthMedical':
      return [
        {
          statisticType: 'sum',
          onStatisticField: 'dialysis_center',
          outStatisticFieldName: 'dialysis_center',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'hospitals',
          outStatisticFieldName: 'hospitals',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'nursing_homes',
          outStatisticFieldName: 'nursing_homes',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'urgent_care',
          outStatisticFieldName: 'urgent_care',
        },
      ];

    case 'commodities':
      return [
        {
          statisticType: 'sum',
          onStatisticField: 'vulnerablepop',
          outStatisticFieldName: 'vulnerablepop',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'plasticsheetreqs',
          outStatisticFieldName: 'plasticsheetreqs',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'peopleinneed',
          outStatisticFieldName: 'peopleinneed',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'waterreq',
          outStatisticFieldName: 'waterreq',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'mealreq',
          outStatisticFieldName: 'mealreq',
        },
      ];
    case 'transportation':
      return [
        {
          statisticType: 'sum',
          onStatisticField: 'road_closed',
          outStatisticFieldName: 'road_closed',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'totalnumalerts',
          outStatisticFieldName: 'totalnumalerts',
        },
      ];

    case 'declarations':
      return [
        {
          statisticType: 'avg',
          onStatisticField: 'Percent_home_insurance',
          outStatisticFieldName: 'Percent_home_insurance',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'is_affected',
          outStatisticFieldName: 'is_affected',
        },
        {
          statisticType: 'sum',
          onStatisticField: 'is_designated',
          outStatisticFieldName: 'is_designated',
        },
      ];

    default:
      return [];
  }
}

export const incidentSectionStats = selectorFamily<
  IncidentStats,
  {
    incidentId?: string;
    sectionId?: string;
    geometry?: { xmin: number; ymin: number; xmax: number; ymax: number };
    counties?: string[];
    states?: string[];
    groupByFieldsForStatistics?: string;
    disabledModels?: string[]; // Add disabledModels here
  }
>({
  key: 'incidentSectionStats',
  get:
    ({
      incidentId,
      sectionId,
      geometry,
      groupByFieldsForStatistics,
      counties,
      states,
      disabledModels,
    }) =>
    async () => {
      if (!incidentId || !sectionId) {
        return {
          displayFieldName: '',
          features: [],
          fields: [],
        };
      }

      const groupByFields =
        groupByFieldsForStatistics != null
          ? groupByFieldsForStatistics
          : ['population', 'residential', 'nonResidentialStructures'].includes(
              sectionId
            )
          ? 'lastupdatedon,comments'
          : 'lastupdatedon';

      try {
        const data = await asyncGetIncidentStatistics(
          incidentId,
          sectionId as IncidentDataKeys,
          groupByFields,
          getOutputStatisticsBySectionId(sectionId),
          counties,
          states,
          geometry
        );

        if (disabledModels != null && disabledModels.length > 0) {
          data.features = data.features.filter(f => {
            const comments = f.attributes.comments as string;
            return disabledModels.includes(comments) === false;
          });
        }

        return data;
      } catch (error) {
        return {
          displayFieldName: '',
          features: [],
          fields: [],
        };
      }
    },
});

export const refreshTriggerAtom = atom({
  key: 'refreshTrigger-incident',
  default: 0, // Initial value
});

export const usePeriodicIncidentDataRefresh = () => {
  const setRefreshTrigger = useSetRecoilState(refreshTriggerAtom);

  React.useEffect(() => {
    const interval = setInterval(() => {
      // Update the refresh trigger to re-fetch data
      setRefreshTrigger(currentValue => currentValue + 1);
    }, 900000); // 15 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  }, [setRefreshTrigger]);
};

export const incidentSectionRuntimes = selectorFamily({
  key: 'incidentSectionRuntimes',
  get:
    ({
      incidentId,
      sectionId,
      disabledModels,
    }: {
      incidentId: string;
      sectionId: IncidentDataKeys;
      disabledModels?: string[];
    }) =>
    async () => {
      if (sectionId === 'county') {
        return [
          {
            comments: '',
            event: incidentId,
            lastupdatedon: 0,
          },
        ];
      }
      const response = await asyncGetIncidentNames(sectionId, incidentId);
      const availableTimes = response.features
        .map(f => lowercaseObjectKeys(f.properties))
        .filter(f => {
          if (disabledModels == null || disabledModels.length === 0) {
            return true;
          }
          return disabledModels.includes(f.comments) === false;
        })
        .sort((a, b) => {
          if (
            typeof a.lastupdatedon === 'number' &&
            typeof b.lastupdatedon === 'number'
          ) {
            return a.lastupdatedon - b.lastupdatedon;
          }

          return 0;
        });

      return availableTimes as IncidentRuntime[];
    },
});

// All string-type keys in the object, and then uppercased
type LowercaseStringKeys<T> = Lowercase<Extract<keyof T, string>>;
// An object consisting of the above keys with the same values from the original object
type LowercaseObjectKeys<T extends { [key: string | number | symbol]: any }> = {
  [x in LowercaseStringKeys<T>]: x extends string ? T[Lowercase<x>] : T[x];
};

function lowercaseObjectKeys<T extends Record<any, any>>(existingObject: T) {
  return Object.keys(existingObject).reduce((acc, key) => {
    const newKey = `${key}`.toLowerCase() as keyof LowercaseObjectKeys<T>;
    acc[newKey] = existingObject[key];
    return acc;
  }, {} as LowercaseObjectKeys<T>);
}

export const incidentRuntimeAtom = atomFamily<
  {
    lastUpdatedOn: number;
    offset: number;
    type: IncidentDataKeys;
    data: FeatureCollection<Geometry, any>[];
  } | null,
  {
    incidentId: string;
    sectionId: IncidentDataKeys;
    lastUpdatedOn?: number;
  }
>({
  key: 'incidentRuntimeAtom',
  default: null,
});

function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export const incidentChunksSelector = selectorFamily<
  {
    lastUpdatedOn: number;
    offset: number;
    type: IncidentDataKeys;
    data: FeatureCollection[];
  }[],
  { incidentId: string; sectionId: IncidentDataKeys; visible: boolean }
>({
  key: 'incidentChunksSelector',
  get: ({
    incidentId,
    sectionId,
    visible,
  }: {
    incidentId: string;
    sectionId: IncidentDataKeys;
    visible: boolean;
  }) => {
    return ({ get }) => {
      get(refreshTriggerAtom);
      if (!visible) {
        return [];
      }

      const availableTimes = get(
        noWait(incidentSectionRuntimes({ incidentId, sectionId }))
      );
      if (
        availableTimes.state === 'loading' ||
        availableTimes.state === 'hasError'
      ) {
        return [];
      }

      const allChunks = availableTimes.contents.map(runtime => {
        const { lastupdatedon } = runtime;
        const chunk = get(
          incidentRuntimeAtom({
            incidentId,
            sectionId,
            lastUpdatedOn: lastupdatedon,
          })
        );

        return chunk;
      });

      return allChunks.filter(nonNullable);
    };
  },
});

export const selectedRunTimeAtom = atomFamily<
  { time: number; comments?: string } | null,
  string
>({
  key: 'incidentRunTime',
  default: null,
});

export const incidentIdsAtom = atom<string[]>({
  key: 'incidentIds',
  default: [],
});

export const incidentAtom = atomFamily<IncidentConfig | null, string>({
  key: 'incidentConfig',
  default: null,
});

export const incidentsSelector = selectorFamily<IncidentConfig | null, string>({
  key: 'incidentSelector',
  get:
    incidentId =>
    ({ get }) => {
      return get(incidentAtom(incidentId));
    },
  set:
    incidentId =>
    ({ set, reset }, newIncident) => {
      // if 'newMeal' is an instance of Default value,
      // the 'set' method will delete the atom from the atomFamily.
      if (newIncident instanceof DefaultValue) {
        // reset method deletes the atom from atomFamily. Then update ids list.
        reset(incidentAtom(incidentId));
        set(incidentIdsAtom, prevValue =>
          prevValue.filter(id => id !== incidentId)
        );
      } else {
        // creates the atom and update the ids list
        set(incidentAtom(incidentId), newIncident);
        set(incidentIdsAtom, prev => [...prev, incidentId]);
      }
    },
});

const supportComments = [
  'population',
  'residential',
  'nonResidentialStructures',
];

export const useSetIncidentDataChunks = (
  incidentKey: string,
  type: IncidentDataKeys,
  lastUpdatedOn?: number,
  comments?: string,
  onLoadingFinish?: Function
) => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        if (lastUpdatedOn != null) {
          const getIncidentChunks = async (
            incidentKey: string,
            type: IncidentDataKeys,
            offset: number
          ) => {
            const data = await asyncGetIncidentData(
              type,
              incidentKey,
              offset,
              lastUpdatedOn,
              comments
            );
            set(
              incidentRuntimeAtom({
                incidentId: incidentKey,
                sectionId: type,
                lastUpdatedOn,
              }),
              prev => {
                if (prev == null) {
                  return {
                    lastUpdatedOn,
                    offset,
                    type,
                    data: [data],
                  };
                }
                return {
                  ...prev,
                  type,
                  data: [...prev.data, data],
                  offset: prev.offset + (data?.features?.length ?? 0),
                  lastUpdatedOn: lastUpdatedOn ?? 0,
                };
              }
            );

            if (data.exceededTransferLimit) {
              getIncidentChunks(incidentKey, type, offset + 2000);
            } else {
              onLoadingFinish?.();
            }
          };
          getIncidentChunks(incidentKey, type, 0);
        }
      },
    [type, incidentKey, lastUpdatedOn]
  );
};

export const atRiskSections = [
  {
    title: 'Population',
    key: 'population',
  },
  {
    title: 'Residential Structures',
    key: 'residential',
  },
  {
    title: 'Non-Residential Structures',
    key: 'nonResidentialStructures',
  },
] as const;

export const communityLifeSections = [
  {
    title: 'Communications',
    key: 'communications',
  },
  {
    title: 'Energy',
    key: 'energy',
  },
  {
    title: 'Transportation',
    key: 'transportation',
  },
  {
    title: 'Commerce',
    key: 'commerce',
  },
  {
    title: 'Food, Water, Shelter',
    key: 'foodWaterShelter',
  },
  {
    title: 'Health & Medical',
    key: 'healthMedical',
  },
] as const;

export const resourceTrackerSections = [
  {
    title: 'Commodities',
    key: 'commodities',
  },
  {
    title: 'Personnel',
    key: 'personnel',
  },
  {
    title: 'Declarations',
    key: 'declarations',
  },
] as const;
