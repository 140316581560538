/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { Box, Typography } from '@mui/material';
import React from 'react';
import { StatsBox } from '../components/StatsBox';

export const AdvisoryStats = React.memo(() => {
  const stats = [
    { label: 'Injuries Reported', value: '27' },
    { label: 'Casualties Reported', value: '3' },
    { label: 'People Under\n Severe Advistory', value: '156,000' },
    { label: 'People Under\n Extreme Advistory', value: '79,256' },
    { label: 'Damaged Buildings', value: '562' },
    { label: 'Destroyed Buildings', value: '57' },
  ];

  return (
    <>
      {stats.map((stat, i) => (
        <StatsBox {...stat} field="test" key={i} />
      ))}
      <Box sx={{ width: 160 }}>
        <Typography
          variant="caption"
          sx={{
            color: 'rgb(210, 75, 98)',
            fontWeight: 'bold',
            direction: 'ltr',
          }}
        >
          * In Development - these numbers are for visualization and do not
          reflect the actual statistics yet.
        </Typography>
      </Box>
    </>
  );
});
