/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Typography,
} from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  incidentSectionRuntimes,
  selectedRunTimeAtom,
} from '../Incident/atoms';
import { IncidentState } from '../Incident/types';
import _useMapControl from 'react-map-gl/dist/esm/components/use-map-control';
import { Toast } from '../components/Toast';
import { Pause, PlayArrow } from '@mui/icons-material';
import { configSelector } from '../config/api';

type Props = {
  incidentId: string;
  sectionId: keyof IncidentState;
  hidden?: boolean;
};

export const IncidentDateSelect = React.memo((props: Props) => {
  const appConfig = useRecoilValue(configSelector);
  const incidentConfig = appConfig.incidents.find(
    i => i.id === props.incidentId
  );

  const { containerRef } = _useMapControl({
    captureScroll: true,
    captureDrag: false,
    captureClick: true,
    captureDoubleClick: false,
    capturePointerMove: false,
  });
  const [selectedRuntime, setSelectedRuntime] = useRecoilState(
    selectedRunTimeAtom(`${props.incidentId}-${props.sectionId}`)
  );

  const availableTimes = useRecoilValue(
    incidentSectionRuntimes({
      incidentId: props.incidentId,
      sectionId: props.sectionId,
      disabledModels: [
        ...(incidentConfig?.globalSettings?.disabledModules ?? []),
        ...(incidentConfig?.sectionSettings?.[props.sectionId]
          ?.disabledModels ?? []),
      ],
    })
  );

  const handleChange = React.useCallback(
    (event: any) => {
      setSelectedRuntime({
        time: new Date(event.target.value).valueOf(),
        comments:
          availableTimes.find(t => t.lastupdatedon === event.target.value)
            ?.comments ?? new Date(event.target.value).toLocaleString(),
      });
    },
    [setSelectedRuntime, availableTimes]
  );

  const hasTimes = availableTimes.length !== 0;
  const setDefaultTime = React.useCallback(() => {
    if (hasTimes && selectedRuntime == null) {
      setSelectedRuntime({
        time: availableTimes[availableTimes.length - 1].lastupdatedon,
        comments: availableTimes[availableTimes.length - 1].comments,
      });
    }
  }, [hasTimes, availableTimes, setSelectedRuntime, selectedRuntime]);

  const { pause, running, seconds, start } = useTimer({
    maxCount: availableTimes.length - 1,
  });

  React.useEffect(() => {
    if (running) {
      const time = availableTimes[seconds];
      const comments = time.comments;
      setSelectedRuntime({ time: time.lastupdatedon, comments });
    }
  }, [seconds, setSelectedRuntime, running, availableTimes]);

  React.useEffect(() => {
    setDefaultTime();
  }, [setDefaultTime]);

  const handleSliderChange = React.useCallback(
    (event: any, value: any) => {
      if (typeof value === 'number') {
        setSelectedRuntime({
          time: availableTimes[value].lastupdatedon,
          comments: availableTimes[value].comments,
        });
      }
    },
    [availableTimes, setSelectedRuntime]
  );

  if (props.hidden) {
    return (
      <Toast
        open={availableTimes.length === 0}
        message="No data available. This page will update as soon as new data feeds become available"
        severity="error"
      />
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'rgb(255, 255, 255)',
        borderRadius: 1,
        maxWidth: 250,
        p: 2,
        pb: 0,
        overflow: 'hidden',
      }}
      ref={containerRef}
    >
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="model-attribute-label" sx={{ backgroundColor: '#fff' }}>
          Select Model
        </InputLabel>

        {availableTimes.length > 0 ? (
          <>
            <Select
              label="Select a date range"
              onChange={handleChange}
              size="small"
              sx={{ width: '100%', fontSize: '0.8em' }}
              value={selectedRuntime?.time ?? 0}
              inputProps={{
                name: 'dateRange',
                id: 'dateRange',
              }}
            >
              {[...availableTimes]
                .sort((a, b) => b.lastupdatedon - a.lastupdatedon)
                .map(time => {
                  const date = new Date(time.lastupdatedon);
                  return (
                    <MenuItem
                      key={time.lastupdatedon}
                      value={time.lastupdatedon}
                    >
                      {date.toLocaleDateString()} {date.toLocaleTimeString()}{' '}
                      {time.comments}
                    </MenuItem>
                  );
                })}
            </Select>
            {availableTimes.length > 1 && (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 1,
                  pb: 1,
                }}
              >
                {false && (
                  <IconButton
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={running ? pause : start}
                  >
                    {running ? <Pause /> : <PlayArrow />}
                  </IconButton>
                )}
                <Slider
                  size="small"
                  track={false}
                  aria-labelledby="time-slider"
                  defaultValue={selectedRuntime?.time ?? 0}
                  min={0}
                  max={availableTimes.length - 1}
                  value={availableTimes
                    .map(a => a.lastupdatedon)
                    .indexOf(selectedRuntime?.time ?? 0)}
                  // sx={{ width: 'calc(100% - 48px)' }}
                  sx={{ width: '100%' }}
                  onChange={handleSliderChange}
                />
              </Box>
            )}
          </>
        ) : (
          <Typography variant="subtitle1">No data available</Typography>
        )}
        <Toast
          open={availableTimes.length === 0}
          message="No data available. This page will update as soon as new data feeds become available"
          severity="error"
        />
      </FormControl>
    </Box>
  );
});

export const interval =
  (delay = 0) =>
  (callback: Function) =>
    React.useEffect(() => {
      const id = setInterval(callback, delay);

      return () => clearInterval(id);
    }, [callback]);

const use1Second = interval(2000);

export const useTimer = ({
  seconds: initialSeconds = 0,
  running: initiallyRunning = false,
  maxCount,
}: { running?: boolean; seconds?: number; maxCount?: number } = {}) => {
  const [seconds, setSeconds] = React.useState(initialSeconds);
  const [running, setRunning] = React.useState(initiallyRunning);
  const tick = React.useCallback(
    () =>
      running
        ? setSeconds(seconds => {
            if (maxCount && seconds >= maxCount) {
              return 0;
            }
            return seconds + 1;
          })
        : undefined,
    [running, maxCount]
  );
  const start = () => setRunning(true);
  const pause = () => setRunning(false);
  const reset = () => setSeconds(0);
  const stop = () => {
    pause();
    reset();
  };

  use1Second(tick);

  return { pause, reset, running, seconds, start, stop };
};
