/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React from 'react';
import Drawer from '@mui/material/Drawer';
import { Menu, MenuOpen } from '@mui/icons-material';
import { atom, useRecoilState } from 'recoil';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import useResizeObserver from 'use-resize-observer';
import { useLocation } from 'react-router-dom';

type Props = {
  anchor?: 'left' | 'right';
  width?: number | string;
  children: React.ReactElement<any>;
};

export const mapDrawerAtom = atom({
  key: 'mapDrawer',
  default: true,
});

export const mapDrawerWidthAtom = atom({
  key: 'mapDrawerWidth',
  default: 357,
});

export const MapDrawer = React.memo((props: Props) => {
  const [open, setOpen] = useRecoilState(mapDrawerAtom);
  const [width, setWidth] = useRecoilState(mapDrawerWidthAtom);
  const location = useLocation();
  const matches = useMediaQuery('(min-width: 769px)');
  const { ref } = useResizeObserver({
    onResize: entry => {
      setWidth(entry?.width ?? 357);
    },
  });

  React.useEffect(() => {
    if (!matches) {
      setOpen(false);
    }
  }, [matches, location, setOpen]);

  const containerStyles = React.useMemo(
    () => ({
      fontSize: '0.8rem',
      height: '100%',
      margin: '0 auto',
      marginTop: 14,
      maxHeight: '100%',
      overflow: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      width: width,
      zIndex: 500,
    }),
    [width]
  );

  return (
    <>
      <Drawer
        PaperProps={{
          variant: 'outlined',
          elevation: 0,
          sx: {
            backgroundColor: 'rgb(245, 250, 252)',
            borderRight: 0,
            maxHeight: 'calc(100svh - 36px)',

            '@media print': {
              display: 'none',
            },
          },
        }}
        variant="persistent"
        anchor={props.anchor ?? 'left'}
        open={open}
      >
        <Box sx={containerStyles}>
          <div
            ref={ref}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              width: 'fit-content',
            }}
          >
            {props.children}
          </div>
        </Box>
      </Drawer>
    </>
  );
});

export const DrawerButton: React.FC = React.memo(props => {
  const [open, setOpen] = useRecoilState(mapDrawerAtom);

  return (
    <IconButton
      onClick={() => setOpen(!open)}
      size="small"
      sx={{ color: 'white' }}
      aria-label={open ? 'Close menu' : 'Open menu'}
    >
      {open ? <MenuOpen /> : <Menu />}
    </IconButton>
  );
});
