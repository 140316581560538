/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { FemaRegions } from '../Regions/FemaRegions';
import { IpawsLegend } from '../Ipaws/IPAWSLayer';
import { MapStylePicker } from '../map/MapStylePicker';
import {
  Button,
  Menu,
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  ToggleButton,
  Typography,
  Checkbox,
  ListItemText,
  InputLabel,
} from '@mui/material';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import React from 'react';
import { PostLegend } from '../Post/PostLayer';
import { Link, useMatch } from 'react-router-dom';
import { IncidentLegend } from '../Incident/IncidentLegend';
import { IncidentSection } from '../pages/IncidentPage';
import { PrimaryLayer } from '../pages/MapPage';
import { Suspense } from 'react';
import LayersIcon from '@mui/icons-material/Layers';
import { WowLegend } from '../wow/WowLayer';
import { CountyLegend, viewCountiesAtom } from '../Incident/CountyLayer';
import { useRecoilState, useRecoilValue } from 'recoil';
import { InfoModal } from '../components/InfoModal';
import { layerVisibility } from './atoms';
import { AssuredWorkload, BarChart } from '@mui/icons-material';
import {
  viewStructuresAtom,
  StructureViewTypes,
  StructuresLegend,
} from './layers/StructuresLayer';
import { Business } from '@mui/icons-material';
import { userAtom } from '../auth/userAtoms';
import { viewTribalBoundariesAtom } from './layers/TribalLayer';
import { useCheckStructuresData } from './layers/StructuresLayer';

type MapControlProps = {
  selectedMap?: PrimaryLayer;
  setSelectedMap?: React.Dispatch<React.SetStateAction<PrimaryLayer>>;
  miniMap?: boolean;
  context?: 'wowcast' | 'default';
};

export const MapControls = React.memo((props: MapControlProps) => {
  const [user] = useRecoilState(userAtom);

  const { selectedMap, setSelectedMap } = props;
  const [, setViewCountyBoundaries] = useRecoilState(viewCountiesAtom);
  const [, setViewTribalBoundaries] = useRecoilState(viewTribalBoundariesAtom);
  const [viewStructures, setViewStructures] =
    useRecoilState(viewStructuresAtom);
  const hightlightCounties = useRecoilValue(
    layerVisibility('county-highlights')
  );

  const isHome = useMatch({
    path: '/',
  });

  const isWowcast = useMatch({
    path: '/wowcast',
  });

  const mapPage = useMatch({
    path: '/:incidentId/:sectionId/:viewType/:attributeId?',
  });
  const isIncidentcast = useMatch({
    path: '/wowcast/:incidentId/:sectionId',
  });

  const isIncidentPage = isIncidentcast || mapPage;

  const incidentId =
    isIncidentPage?.params?.incidentId ?? isIncidentcast?.params?.incidentId;
  const sectionId = (isIncidentPage?.params?.sectionId ??
    'population') as IncidentSection;
  const viewType = mapPage?.params?.viewType ?? 'map';
  const hasStructuresData = useCheckStructuresData(incidentId);

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      if (setSelectedMap) {
        setSelectedMap(event.target.value as PrimaryLayer);
      }
    },
    [setSelectedMap]
  );
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

  const handleBoundariesChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    setSelectedOptions(value);
    setViewCountyBoundaries(value.includes('county'));
    setViewTribalBoundaries(value.includes('tribal'));
  };

  const options = [
    { name: 'County Boundaries', value: 'county' },
    { name: 'Tribal Boundaries', value: 'tribal' },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (filter: StructureViewTypes) => {
    if (filter === 'none') {
      setViewStructures({ view: false, filter: 'occupancy_type' });
    } else {
      setViewStructures({ view: true, filter });
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          width: 'auto',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
          position: 'absolute',
          bottom: props?.context === 'wowcast' ? 16 : 8,
          left:
            props?.context === 'wowcast'
              ? 16
              : props.miniMap
              ? 'max(20.4%, 308px)'
              : 16,

          pointerEvents: 'none',

          '@media (max-width: 1200px)': {
            transform: 'scale(0.9)',
            transformOrigin: 'bottom left',
          },
        }}
      >
        <Paper
          sx={{
            backgroundColor: 'rgba(0, 62, 103, 0.4)',
            backdropFilter: 'blur(8px)',
            p: 1,
            pb: 0,
            mb: 0.5,
            scrollPadding: '8px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: 'white',
              textIndent: '10px',
              alignItems: 'center',
              display: 'flex',
              pb: 1,
            }}
          >
            <LayersIcon /> Layers
          </Typography>
          {(isHome != null || isWowcast != null) && (
            <>
              {isHome != null && (
                <FormControl
                  fullWidth
                  sx={{
                    pointerEvents: 'auto',
                    overflow: 'hidden',
                    pb: 1,
                  }}
                >
                  <Select
                    labelId="layer-label"
                    id="layer-select"
                    value={selectedMap}
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: 'white',
                      fontWeight: 600,
                      color: theme => theme.palette.primary.main,
                      maxWidth: 250,
                    }}
                    onChange={handleChange}
                  >
                    <MenuItem dense value="wow">
                      WOW (Worst of the Worst)
                    </MenuItem>
                    <MenuItem dense value="nifc">
                      NIFC
                    </MenuItem>
                    <MenuItem dense value="canopy">
                      Canopy
                    </MenuItem>
                  </Select>
                </FormControl>
              )}

              <ScrollContainer>
                <Box sx={{ width: 'fit-content' }}>
                  {selectedMap != null &&
                    ['wow', 'post', 'nifc', 'canopy'].includes(selectedMap) && (
                      <Box sx={{ mb: 1 }}>
                        <PostLegend selectedMap={selectedMap} />
                      </Box>
                    )}
                  {selectedMap === 'wow' && <WowLegend />}

                  {selectedMap === 'ipaws' && (
                    <>
                      <Box sx={{ mb: 1 }}>
                        <PostLegend selectedMap={selectedMap} />
                      </Box>
                      <IpawsLegend />
                    </>
                  )}
                </Box>
              </ScrollContainer>
            </>
          )}
          {isIncidentPage != null && (
            <Suspense fallback={null}>
              <Box
                sx={{
                  pointerEvents: 'auto',
                  maxHeight: 'calc(100svh - 298px)',
                  minHeight: '50px',
                  overflow: 'auto',
                  overflowX: 'hidden',
                  scrollbarColor: 'rgba(244, 244, 244, 0.5) rgba(0, 0, 0, 0)',
                  scrollbarWidth: 'thin',
                  scrollPadding: '8px',
                  borderRadius: '4px',
                  mb: 1,
                }}
              >
                {hightlightCounties && (
                  <Box sx={{ mb: 1, pointerEvents: 'auto' }}>
                    <CountyLegend />
                  </Box>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ pointerEvents: 'auto' }}>
                    <IncidentLegend sectionId={sectionId} />
                  </Box>
                </Box>
              </Box>
            </Suspense>
          )}
        </Paper>

        {props.context !== 'wowcast' && (
          <Box
            data-print="hidden"
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              pointerEvents: 'auto',

              '> *': {
                mr: 1,
              },
              '@media print': {
                display: 'none!important',
              },
            }}
          >
            <MapStylePicker />
            <FemaRegions />

            <Box
              sx={{
                overflow: 'hidden',
                backgroundColor: 'white',
                borderRadius: '4px',
                height: 'fit-content',
                width: 'fit-content',
              }}
            >
              {viewStructures.view && viewStructures.filter !== 'highlight' && (
                <StructuresLegend />
              )}

              {!user?.token && (
                <ToggleButton
                  size="small"
                  value="counties"
                  selected={viewStructures.view}
                  onChange={() =>
                    setViewStructures({
                      filter: 'occupancy_type',
                      view: !viewStructures.view,
                    })
                  }
                  color="primary"
                  sx={{
                    backgroundColor: 'white',
                    border: '4px solid',
                  }}
                >
                  <Business sx={{ pr: 0.75 }} />
                  USA Structures
                </ToggleButton>
              )}

              {user?.token && (
                <>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    // color={viewStructures.view ? 'primary' : 'secondary'}

                    sx={{
                      backgroundColor: 'white',
                      border: '4px solid',
                      color: viewStructures.view ? 'primary' : 'inherit',
                      borderColor: viewStructures.view
                        ? 'primary'
                        : 'rgba(34, 34, 34, 0.7)',
                    }}
                  >
                    <Business sx={{ pr: 0.75 }} />
                    USA Structures
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => handleMenuClick('none')}>
                      Clear
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClick('occupancy_type')}>
                      Structures by Occupancy Type
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuClick('exposure')}
                      disabled={!hasStructuresData}
                    >
                      Exposured Structures
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuClick('highlight')}
                      disabled={!hasStructuresData}
                    >
                      Highlight Exposed Structures
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Box>

            {isIncidentPage != null && (
              <Box
                sx={{
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  height: 'fit-content',
                  width: 'fit-content',
                }}
              >
                <Link
                  replace
                  style={{
                    textDecoration: 'none',
                  }}
                  to={{
                    pathname: `/${incidentId}/${sectionId}/${
                      viewType === 'map' ? 'detail' : 'map'
                    }`,
                  }}
                >
                  <ToggleButton
                    size="small"
                    value="details"
                    color="primary"
                    selected={viewType === 'detail'}
                    sx={{
                      backgroundColor: 'white',
                      border: '4px solid',
                    }}
                  >
                    <BarChart sx={{ pr: 0.75 }} />
                    Detail View
                  </ToggleButton>
                </Link>
              </Box>
            )}
            <Box
              sx={{
                overflow: 'hidden',
                backgroundColor: 'white',
                borderRadius: '4px',
                height: 'fit-content',
                width: 'fit-content',
                p: 1,
              }}
            >
              <FormControl sx={{ width: 200 }}>
                <InputLabel size="small" id="boundaries-label" shrink={true}>
                  View Boundaries
                </InputLabel>
                <Select
                  multiple
                  labelId="boundaries-label"
                  label="View Boundaries"
                  value={selectedOptions}
                  onChange={handleBoundariesChange}
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <AssuredWorkload sx={{ mr: 0.5 }} />
                      {selected.join(', ')}
                    </Box>
                  )}
                  displayEmpty
                  name="boundaries"
                  id="boundaries"
                  inputProps={{ 'aria-label': 'Without label' }}
                  size="small"
                  placeholder="View Boundaries"
                >
                  {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={selectedOptions.indexOf(option.value) > -1}
                      />
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
      </Box>

      {props.context !== 'wowcast' && (
        <InfoModal type={sectionId ?? selectedMap} />
      )}
    </>
  );
});

const ScrollContainer: React.FC<{ children?: any }> = props => {
  const boxRef = React.useRef<HTMLElement | null>(null);
  const [hasScrollbar, setHasScrollbar] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      const boxElement = boxRef.current;
      if (boxElement) {
        const hasScrollbar = boxElement.scrollWidth > boxElement.clientWidth;
        setHasScrollbar(hasScrollbar);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [boxRef]);

  return (
    <Box
      ref={boxRef}
      overflow="auto"
      sx={{
        pointerEvents: 'auto',
        maxHeight: 'calc(100svh - 348px)',
        minHeight: '50px',
        overflow: 'auto',
        overflowX: 'hidden',
        scrollPaddingBlock: '8px',
        scrollPaddingInline: '8px',
        scrollbarColor: 'rgba(244, 244, 244, 0.5) rgba(0, 0, 0, 0)',
        scrollbarWidth: 'thin',
        borderRadius: '4px',
        mb: 1,
        '@-moz-document url-prefix()': {
          paddingRight: hasScrollbar ? '9px' : 0,
        },
      }}
    >
      {props.children}
    </Box>
  );
};
