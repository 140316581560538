/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { regionState } from '../Regions/FemaRegions';
import { states } from '../utils/stateNameToAbbreviation';
import { ReactComponent as TempoLogo } from '../images/tempo_full_color_DKGray.svg';

interface EmailSubscriptionPageProps {}

const EmailSubscriptionPage: React.FC<EmailSubscriptionPageProps> = () => {
  const [searchParams] = useSearchParams();
  const [id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [canopyAlerts, setCanopyAlerts] = useState(false);
  const [wowForecastThreats, setWowForecastThreats] = useState(false);
  const [wowIncidentAlerts, setWowIncidentAlerts] = useState(false);
  const [wildfireTaskerAlerts, setWildfireTaskerAlerts] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);

  const femaRegionsJson = useRecoilValueLoadable(regionState);

  const handleGetEmailSubscription = React.useCallback((requestId: string) => {
    const api = process.env.REACT_APP_TEMPO_SERVER_URL;
    fetch(`${api}/subscription/${requestId}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.subscriptionTypes) {
          setId(data._id);
          setEmail(data.email);
          setCanopyAlerts(
            data.subscriptionTypes.includes('canopy.observation')
          );
          setWowForecastThreats(
            data.subscriptionTypes.includes('wow.forecast')
          );
          setWowIncidentAlerts(
            data.subscriptionTypes.includes('wow.observation')
          );

          setWildfireTaskerAlerts(
            data.subscriptionTypes.includes('wildfire.observation')
          );

          setSelectedRegions(data.regions);
          setSelectedStates(data.states);
        }
      });
  }, []);

  React.useEffect(() => {
    const emailIdParam = searchParams.get('id');
    const emailParam = searchParams.get('email');
    if (emailIdParam != null) {
      setId(emailIdParam);
      handleGetEmailSubscription(emailIdParam);
    }

    if (emailParam != null) {
      setEmail(emailParam);
    }
  }, [searchParams, handleGetEmailSubscription]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleCanopyAlertsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCanopyAlerts(event.target.checked);
  };

  const handleWowForecastThreatsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWowForecastThreats(event.target.checked);
  };

  const handleWowIncidentAlertsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWowIncidentAlerts(event.target.checked);
  };

  const handleWildfireTaskerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWildfireTaskerAlerts(event.target.checked);
  };

  function onSubmit(
    email: string,
    subscriptions: string[],
    selectedRegions: string[]
  ) {
    const api = process.env.REACT_APP_TEMPO_SERVER_URL;
    fetch(`${api}/subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        subscriptionTypes: subscriptions,
        regions: selectedRegions,
        states: selectedStates,
      }),
    }).then(response => {
      if (response.ok) {
        alert('Confirmation email sent has been sent.');
      } else {
        console.log(response);
        alert('There was an error subscribing you. Please try again later.');
      }
    });
  }

  function handleUpdate(
    id: string,
    email: string,
    subscriptions: string[],
    selectedRegions: string[]
  ) {
    const api = process.env.REACT_APP_TEMPO_SERVER_URL;
    fetch(`${api}/subscription/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        subscriptionTypes: subscriptions,
        regions: selectedRegions,
        states: selectedStates,
      }),
    }).then(response => {
      if (response.ok) {
        alert('Confirmation email sent has been sent.');
      } else {
        alert('There was an error subscribing you. Please try again later.');
      }
    });
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const subscriptions = [];

    if (wowForecastThreats) {
      subscriptions.push('wow.forecast');
    }
    if (wowIncidentAlerts) {
      subscriptions.push('wow.observation');
    }

    if (canopyAlerts) {
      if (isValidFemaEmail(email)) {
        subscriptions.push('canopy.observation');
      } else {
        alert('Please enter a valid FEMA email address.');
        return;
      }
    }

    if (id && email === searchParams.get('email')) {
      handleUpdate(id, email, subscriptions, selectedRegions);
    } else {
      onSubmit(email, subscriptions, selectedRegions);
    }
  };

  function isValidFemaEmail(email: string): boolean {
    const pattern: RegExp =
      /^[a-zA-Z0-9._%+-]+@(fema\.dhs\.gov|nltgis\.com|associates\.fema\.dhs\.gov)$/;
    return pattern.test(email);
  }

  function isValidEmail(email: string): boolean {
    const pattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(email);
  }

  const handleUnsubscribe = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const api = process.env.REACT_APP_TEMPO_SERVER_URL;
    fetch(`${api}/subscription/unsubscribe/${email}`).then(response => {
      if (response.ok) {
        alert(
          'If your email is in our system, a confirmation email will be sent.'
        );
      } else {
        alert('There was an error unsubscribing you. Please try again later.');
      }
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ mb: 10, overflow: 'auto' }}
    >
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Paper elevation={3} style={{ padding: '24px' }}>
          <TempoLogo width="240" style={{ paddingBottom: '20px' }} />
          <Typography variant="h4" align="center">
            Subscribe to TEMPO Alerts
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="Email Address"
                variant="outlined"
                fullWidth
                margin="normal"
                type="email"
                value={email}
                onChange={handleEmailChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={canopyAlerts}
                    onChange={handleCanopyAlertsChange}
                    color="primary"
                    disabled={!isValidFemaEmail(email)}
                  />
                }
                label="TEMPO Tornado Alerts (Requires FEMA Email)"
              />
              <Typography variant="body2" sx={{ mb: 1 }}>
                Provides details on tornado location, structures exposed, and
                approximate maximum EF rank for new or updated tornado swaths
                every 15 minutes. Tornado swaths are provided by Canopy Weather.{' '}
                <b>FEMA internal users only.</b>
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={wildfireTaskerAlerts}
                    onChange={handleWildfireTaskerChange}
                    color="primary"
                  />
                }
                label="TEMPO Wildfire Tasker"
              />
              <Typography variant="body2" sx={{ mb: 1 }}>
                Provides geospatial data of the extents of wildfires with the
                largest numbers of exposed structures. Fire perimeters are
                provided by the National Interagency Fire Center (NIFC). Emails
                are designed for tasking of imagery collection. For users
                instead seeking summary alerts of wildfire threats or incidents,
                we recommend the WOW Forecast or Incident alerts above.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={wowForecastThreats}
                    onChange={handleWowForecastThreatsChange}
                    color="primary"
                  />
                }
                label="WOW Forecast Alerts"
              />
              <Typography variant="body2" sx={{ mb: 1 }}>
                Daily email update summarizing the worst threats from national
                forecasts related to tornado outlook, flooding, winter weather,
                and wildfires. Summary includes locations, threat severities,
                and potential impacts. Available to all users.
              </Typography>{' '}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={wowIncidentAlerts}
                    onChange={handleWowIncidentAlertsChange}
                    color="primary"
                  />
                }
                label="WOW Incident Alerts"
              />
            </FormControl>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Alerts of the most severe and impactful earthquakes, wildfires,
              and tornadoes, updated every 15 minutes. Summary includes
              locations, severities, and exposed structures, with links to
              additional data. Available to all users.
            </Typography>
            <Typography variant="h5" align="center" sx={{ mt: 4 }}>
              Optionally filter alerts by State or Region
            </Typography>

            <FormControl sx={{ p: 1, width: '100%' }}>
              <Typography variant="h6" align="center">
                FEMA Regions
              </Typography>
              <Typography
                variant="body2"
                align="center"
                style={{ marginBottom: '16px' }}
              >
                Select the FEMA regions you would like to receive alerts for.
              </Typography>
              {/* <InputLabel>Regions</InputLabel> */}
              <Select
                multiple
                value={selectedRegions}
                onChange={event =>
                  setSelectedRegions(event.target.value as string[])
                }
                renderValue={selected => selected?.join(', ')}
              >
                {femaRegionsJson.state === 'hasValue' &&
                  [...femaRegionsJson?.contents?.features]
                    ?.sort(
                      // @ts-ignore
                      (a, b) => a?.properties?.region - b?.properties?.region
                    )
                    .map?.((feature: any) => {
                      const region = feature.properties;
                      return (
                        <MenuItem key={region.region} value={region.region}>
                          {`Region ${region.region}`}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
            <FormControl sx={{ p: 1, position: 'relative', width: '100%' }}>
              <Typography variant="h6" align="center">
                States
              </Typography>
              <Typography
                variant="body2"
                align="center"
                style={{ marginBottom: '16px' }}
              >
                Select the states you would like to receive alerts for.
              </Typography>
              {/* <InputLabel>States</InputLabel> */}
              <Select
                multiple
                value={selectedStates}
                onChange={event =>
                  setSelectedStates(event.target.value as string[])
                }
                renderValue={selected => selected.join(', ')}
              >
                {Object.entries(states).map?.(([state, stateAbbr]) => {
                  return (
                    <MenuItem key={state} value={stateAbbr}>
                      {state}, {stateAbbr}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Button
              disabled={!isValidEmail(email)}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              Subscribe
            </Button>
          </form>
          <form onSubmit={handleUnsubscribe}>
            <Button
              sx={{ mt: 2 }}
              disabled={!isValidEmail(email)}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              Unsubscribe
            </Button>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EmailSubscriptionPage;
