import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface OAuthCallbackProps {
  onAuthenticated: () => void;
}

const OAuthCallback: React.FC<OAuthCallbackProps> = ({ onAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const hash = location.hash.substr(1);
      const result = hash.split('&').reduce((result, item) => {
        const parts = item.split('=');
        result[parts[0]] = parts[1];
        return result;
      }, {} as { [key: string]: string });

      if (result.access_token) {
        localStorage.setItem('arcgisOAuth', JSON.stringify(result));
        onAuthenticated();
        navigate('/'); // Navigate to home page
      }
    }
  }, [navigate, onAuthenticated, location.hash]);

  return <div>Authenticating...</div>;
};

export default OAuthCallback;
