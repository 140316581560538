/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import * as React from 'react';
import { incidentChunksSelector } from './atoms';
import { Legend } from '../components/Legend';
import { NonResidentialStructure } from './types';
import { RGBAColor } from '@deck.gl/core/utils/color';
import { primaryKeyForSection } from '../Post/PostLayer';
import { useGridCellLayer } from '../map/GridCellLayer';
import { useRecoilValue } from 'recoil';
import nonResidentialDict from './Incident_page_dictionaries/NonResidentialStructures_dict.json';

type Props = {
  extruded: boolean;
  visible: boolean;
  incidentId: string;
};

// export const selectedNonResidentialStructureAtom =
//   atom<NonResidentialStructureFeature | null>({
//     key: 'selectedNonResidentialStructure',
//     default: null,
//   });

const naturalBreakAttribures = [
  'exposurescore_allstructures',
  'exposurescore_agriculturalru',
  'exposurescore_commercialoff',
  'exposurescore_commercialret',
  'exposurescore_exemptgovernm',
  'exposurescore_heavyindustria',
  'exposurescore_industrialgen',
  'exposurescore_miscellaneous',
  'exposurescore_recreational',
  'exposurescore_vacantland',
] as (keyof NonResidentialStructure['features'][number]['properties'])[];

export const useNonResidentialStructureLayer = (props: Props) => {
  const allChunks = useRecoilValue(
    incidentChunksSelector({
      incidentId: props.incidentId,
      sectionId: 'nonResidentialStructures',
      visible: props.visible,
    })
  );
  const nonResidentialStructureData = React.useMemo(
    () => allChunks.flatMap(a => a.data),
    [allChunks]
  );

  const selectedPrimaryKey = useRecoilValue(
    primaryKeyForSection('nonResidentialStructures')
  ) as keyof NonResidentialStructure['features'][number]['properties'];

  const handleFillColor = React.useMemo(
    () =>
      naturalBreakAttribures.includes(selectedPrimaryKey)
        ? getFillColor(selectedPrimaryKey)
        : undefined,
    [selectedPrimaryKey]
  );

  const GridCellLayer = useGridCellLayer<
    NonResidentialStructure['features'][number]
  >({
    id: 'nonResidentialStructures',
    incidentId: props.incidentId,
    // @ts-ignore
    data: nonResidentialStructureData,
    extruded: props.extruded,
    visible: props.visible,
    getFillColor: handleFillColor,
  });

  return GridCellLayer;
};

const colorRange: RGBAColor[] = [
  [181, 9, 9, 190], // #B50909
  [219, 129, 0, 190], // #DB8100
  [226, 178, 0, 190], // #E2B200
  [143, 195, 201, 190], // #8FC3C9
  [95, 133, 188, 190], // #5F85BC
];

const getFillColor =
  (key: keyof NonResidentialStructure['features'][number]['properties']) =>
  (d: NonResidentialStructure['features'][number]): RGBAColor => {
    switch (d.properties[key]) {
      case 1:
        return colorRange[0];
      case 2:
        return colorRange[1];
      case 3:
        return colorRange[2];
      case 4:
        return colorRange[3];
      case 5:
        return colorRange[4];
      default:
        return [0, 0, 0, 0];
    }
  };

const elevationKeys = [
  'heavyindustria',
  'agriculturalru',
  'commercialoff',
  'commercialret',
  'exemptgovernm',
  'industrialgen',
  'miscellaneous',
  'recreational',
  'residential',
  'totalnumberofrelevantstructure',
  'vacantland',
  'ls_day_pop',
  'ls_night_pop',
  'pop',
  'households',
  'housingunits',
  'pop65over',
  'pop16overunemployed',
  'pop16overnotinlaborforce',
  'popamindianalaskan',
  'householdsinpoverty',
  'householdsdsfs',
  'householdsdsnofs',
  'householdswithfs',
  'householdswithpa',
  'housingunitsmobilehomes',
  'lep',
] as const;

export const postKeyToLabel = {
  event_id: 'Grouped IPAWS event',
  usng5km: '5km USNG cell',
  event: 'IPWAS event type',
  effective: 'Effective',
  expires: 'Expires',
  quantile_sum: 'Rank Sum',
  final_rank: 'Unscaled Rank',
  priority_rank: 'POST Priority',
  run_time: 'POST run time',
  commercialofficeimpacts: 'Commercial Office Impacts',
  ...nonResidentialDict,
} as const;

const PRIORITY_LEVELS = [
  'Highest Priority',
  'High Priority',
  'Medium Priority',
  'Low Priority',
  'Lowest Priority',
];

const legendItems = colorRange.map((color, index) => ({
  label: `${index + 1} - ${PRIORITY_LEVELS[index]}`,
  color: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
}));

const primaryProperties = naturalBreakAttribures
  .concat(elevationKeys)
  .map(key => ({ key, label: (nonResidentialDict as any)?.[key] ?? key }));

const elevationProperties = elevationKeys.map(key => ({
  label: nonResidentialDict?.[key] ?? key,
  key,
}));

export const NonResidentialLegend = () => {
  const primaryKey = useRecoilValue(
    primaryKeyForSection('nonResidentialStructures')
  ) as keyof typeof postKeyToLabel;

  return (
    <Legend
      id="nonResidentialStructures"
      title={(nonResidentialDict as any)?.[primaryKey] ?? primaryKey}
      items={legendItems}
      elevationProperties={elevationProperties}
      primaryProperties={primaryProperties}
    />
  );
};
