/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { Feature } from '@turf/turf';
import { atom, selector } from 'recoil';

export const currentFemaRegionsQuery = selector({
  key: 'CurrentFemaRegions',
  get: async ({ get }) => {
    const response = await fetch(
      'https://www.fema.gov/api/open/v1/FemaRegions'
    );
    const data = await response.json();
    return data.FemaRegions;
  },
});

export const currentRegionState = atom<Feature | null>({
  key: 'currentRegions',
  default: null,
});
