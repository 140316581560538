import React from 'react';
import { ToggleButton, Paper, Tooltip } from '@mui/material';
import { LabelOutlined } from '@mui/icons-material';
import { _MapContext as MapContext, _useMapControl } from 'react-map-gl';

export const ToggleLabelsButton = (props: {
  initialVisibility?: 'none' | 'visible';
}) => {
  const { map } = React.useContext(MapContext);
  const [labelsVisible, setLabelsVisible] = React.useState(
    props.initialVisibility === 'none' ? false : true
  );
  const { containerRef: toggleLabelsButtonRef } = _useMapControl({
    captureClick: true,
    captureScroll: true,
    captureDrag: false,
    captureDoubleClick: false,
    capturePointerMove: false,
  });

  const handleChange = React.useCallback(() => {
    setLabelsVisible(!labelsVisible);
    if (map) {
      map.getStyle().layers.forEach((l: any) => {
        if (l.type === 'symbol') {
          map.setLayoutProperty(
            l.id,
            'visibility',
            labelsVisible ? 'none' : 'visible'
          );
        }
      });
    }
  }, [setLabelsVisible, labelsVisible, map]);

  React.useEffect(() => {
    try {
      if (map && props.initialVisibility === 'none') {
        setTimeout(() => {
          const style = map?.getStyle();
          if (style && style.layers) {
            style.layers.forEach((l: any) => {
              if (l.type === 'symbol') {
                map.setLayoutProperty(l.id, 'visibility', 'none');
              }
            });
          }
        }, 200);
      }
    } catch (e) {
      console.error('Error setting initial label visibility:', e);
    }
  }, [map, props.initialVisibility]);

  return (
    <Tooltip title="Toggle labels">
      <Paper elevation={0}>
        <ToggleButton
          size="small"
          value="check"
          sx={{ backgroundColor: 'white', width: 30, height: 30 }}
          selected={labelsVisible}
          ref={toggleLabelsButtonRef}
          onChange={handleChange}
          aria-label="Toggle labels"
        >
          <LabelOutlined />
        </ToggleButton>
      </Paper>
    </Tooltip>
  );
};
