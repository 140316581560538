import { UserSession } from '@esri/arcgis-rest-auth';
import { IUser } from '@esri/arcgis-rest-types';
import { atom } from 'recoil';

export const userAtom = atom<(IUser & { token: string }) | null>({
  key: 'userAtom',
  default: null,
});

export const userSessionAtom = atom<UserSession | null>({
  key: 'userSessionAtom',
  default: null,
});
