import React from 'react';
import { ToggleButton, Paper, Tooltip } from '@mui/material';
import { ViewInArOutlined as ViewInArOutlinedIcon } from '@mui/icons-material';
import { FlyToInterpolator } from 'react-map-gl';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { visualModeAtom } from './atoms';
import { _useMapControl } from 'react-map-gl';
import { viewStateAtom } from './atoms';

export const DimensionButton = () => {
  const [visualMode, setVisualMode] = useRecoilState(visualModeAtom);
  const { containerRef: threeDButtonRef } = _useMapControl({
    captureClick: true,
    captureScroll: true,
    captureDrag: false,
    captureDoubleClick: false,
    capturePointerMove: false,
  });

  const setViewState = useSetRecoilState(viewStateAtom);

  const handleChange = React.useCallback(() => {
    // @ts-ignore
    setViewState(vs => ({
      ...vs,
      pitch: visualMode === '2d' ? 60 : 0,
      maxPitch: 85,
      transitionInterpolator: new FlyToInterpolator({ speed: 3.0 }),
      transitionDuration: 1000,
    }));
    setVisualMode(visualMode => (visualMode === '2d' ? '3d' : '2d'));
  }, [setViewState, visualMode, setVisualMode]);

  return (
    <Tooltip title="Toggle 3D map view">
      <Paper elevation={0}>
        <ToggleButton
          size="small"
          value="check"
          sx={{ backgroundColor: 'white', width: 30, height: 30 }}
          selected={visualMode === '3d'}
          ref={threeDButtonRef}
          onChange={handleChange}
          aria-label="Toggle 3D map view"
        >
          <ViewInArOutlinedIcon />
        </ToggleButton>
      </Paper>
    </Tooltip>
  );
};
