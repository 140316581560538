import React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FlightPoint } from '../pages/WowcastPage'; // Adjust the import path as necessary
import { RGBColor } from '@deck.gl/core/utils/color';

interface StoryListProps {
  features: FlightPoint[];
  flightPath: FlightPoint[];
  currentStep: number;
  onSelect: (point: FlightPoint) => void; // Function to handle selection
}

export const wowColorMap: Record<string, RGBColor> = {
  earthquake1: [139, 69, 19],
  rain1: [127, 23, 14],
  rain2: [1, 255, 0],
  rain3: [74, 137, 92],
  tornado1: [250, 1, 2],
  tornado2: [255, 254, 0],
  tornado3: [255, 69, 0],
  wildfire1: [181, 9, 9],
  wildfire2: [255, 221, 176],
  wildfire3: [253, 68, 3],
  winter1: [123, 104, 238],
  winter2: [74, 212, 240],
};

const categoryIcons: Record<string, string> = {
  tornado1:
    'https://nlt-tempo-static.s3.us-east-1.amazonaws.com/icons/tornado.png',
  tornado2:
    'https://nlt-tempo-static.s3.us-east-1.amazonaws.com/icons/tornado.png',
  tornado3:
    'https://nlt-tempo-static.s3.us-east-1.amazonaws.com/icons/tornado.png',
  rain1: 'https://nlt-tempo-static.s3.us-east-1.amazonaws.com/icons/rain.png',
  rain2: 'https://nlt-tempo-static.s3.us-east-1.amazonaws.com/icons/rain.png',
  winter1:
    'https://nlt-tempo-static.s3.us-east-1.amazonaws.com/icons/winter.png',
  earthquake1:
    'https://nlt-tempo-static.s3.us-east-1.amazonaws.com/icons/earthquake.png',
  wildfire1:
    'https://nlt-tempo-static.s3.us-east-1.amazonaws.com/icons/wildfire.png',
  wildfire2:
    'https://nlt-tempo-static.s3.us-east-1.amazonaws.com/icons/wildfire.png',
  wildfire3:
    'https://nlt-tempo-static.s3.us-east-1.amazonaws.com/icons/wildfire.png',
};

const StoryList: React.FC<StoryListProps> = React.memo(
  ({ features, flightPath, currentStep, onSelect }) => {
    const selectedItemRef = React.useRef<HTMLDivElement>(null); // Create a ref for the selected item

    React.useEffect(() => {
      if (selectedItemRef.current) {
        selectedItemRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }, [currentStep]);

    const forecastedEvents = features.filter(
      event => event.properties?.time_category === 'future'
    );

    const observationEvents = features.filter(
      event => event.properties?.time_category === 'past'
    );

    return (
      <Paper
        elevation={3}
        sx={{
          width: '20%',
          minWidth: 300,
          height: 'calc(100vh - var(--news-cast-height, 180px)) !important',
          overflowY: 'auto',
          position: 'absolute',
          backgroundColor: 'rgba(0, 62, 103, 0.8)',
          backdropFilter: 'blur(4px)',
          left: 0,
          top: 0,
          zIndex: 1000,
          '@media (max-width: 768px)': {
            '.MuiTypography-h4': {
              // Targeting Typography with variant h4
              fontSize: '1.25rem', // Smaller screens
            },
            '.MuiListItemText-primary': {
              // Targeting the primary text in ListItemText
              fontSize: '0.875rem', // Smaller screens
            },
            '.MuiListItemText-secondary': {
              // Targeting the secondary text in ListItemText
              fontSize: '0.75rem', // Smaller screens
            },
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: 'white',
            padding: 2,
            fontSize: '1.50rem',
            borderTop: '1px solid #f8c471',
            borderBottom: '1px solid #f8c471',
          }}
        >
          WoW - Observed Alerts
        </Typography>
        <List>
          {observationEvents.length === 0 && (
            <ListItem>
              <ListItemText
                primary="No WoW hazard exceeds Incident Thresholds"
                primaryTypographyProps={{
                  color: 'white',
                  fontSize: '1.25rem',
                }}
              />
            </ListItem>
          )}
          {observationEvents.map((point, index) => (
            <ListItem
              button
              key={index}
              onClick={() => onSelect(point)}
              sx={{
                bgcolor:
                  flightPath?.[currentStep]?.properties?.ObjectId ===
                  point.properties.ObjectId
                    ? 'primary.light'
                    : 'inherit',
                // Additional responsive styles can be added here if needed
              }}
              ref={
                flightPath?.[currentStep]?.properties?.ObjectId ===
                point.properties.ObjectId
                  ? selectedItemRef
                  : null
              } // Attach the ref to the selected item
            >
              <Avatar
                sx={{
                  bgcolor: wowColorMap[point.properties.CategoryID]
                    ? `rgb(${wowColorMap[point.properties.CategoryID].join(
                        ','
                      )})`
                    : 'grey.500', // Fallback color if CategoryID is not in wowColorMap
                  marginRight: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={categoryIcons?.[point.properties.CategoryID]}
                  alt={point.properties.CategoryID}
                  style={{ width: '24px' }}
                />
              </Avatar>
              <ListItemText
                primary={point.properties.Threat}
                secondary={
                  <>
                    {point.properties.ThreatLevel} <br />
                    {point.properties.state_intersect}
                  </>
                }
                primaryTypographyProps={{
                  color: '#f8c471',
                  fontSize: '1.75rem',
                }}
                secondaryTypographyProps={{
                  color: 'white',
                  fontSize: '1.50rem',
                }}
              />
            </ListItem>
          ))}
        </List>
        <Typography
          variant="h4"
          sx={{
            color: 'white',
            padding: 2,
            fontSize: '1.50rem',
            borderTop: '1px solid #f8c471',
            borderBottom: '1px solid #f8c471',
          }}
        >
          WoW - Forecast Alerts
        </Typography>
        <List>
          {forecastedEvents.length === 0 && (
            <ListItem>
              <ListItemText
                primary="No WoW hazard exceeds Forecast Thresholds"
                primaryTypographyProps={{
                  color: 'white',
                  fontSize: '1.25rem',
                }}
              />
            </ListItem>
          )}
          {forecastedEvents.map((point, index) => (
            <ListItem
              button
              key={index}
              onClick={() => onSelect(point)}
              sx={{
                bgcolor:
                  flightPath?.[currentStep]?.properties?.ObjectId ===
                  point.properties.ObjectId
                    ? 'primary.light'
                    : 'inherit',
                // Additional responsive styles can be added here if needed
              }}
              ref={
                flightPath?.[currentStep]?.properties?.ObjectId ===
                point.properties.ObjectId
                  ? selectedItemRef
                  : null
              } // Attach the ref to the selected item
            >
              <Avatar
                sx={{
                  bgcolor: wowColorMap[point.properties.CategoryID]
                    ? `rgb(${wowColorMap[point.properties.CategoryID].join(
                        ','
                      )})`
                    : 'grey.500', // Fallback color if CategoryID is not in wowColorMap
                  marginRight: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={categoryIcons?.[point.properties.CategoryID]}
                  alt={point.properties.CategoryID}
                  style={{ width: '24px' }}
                />
              </Avatar>
              <ListItemText
                primary={point.properties.Threat}
                secondary={
                  <>
                    {point.properties.ThreatLevel} <br />
                    {point.properties.state_intersect}
                  </>
                }
                primaryTypographyProps={{
                  color: '#f8c471',
                  fontSize: '1.75rem',
                }}
                secondaryTypographyProps={{
                  color: 'white',
                  fontSize: '1.50rem',
                }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    );
  }
);

export default StoryList;
