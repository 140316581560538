/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import * as React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { RGBAColor } from '@deck.gl/core/utils/color';
import { incidentChunksSelector } from './atoms';
import { Declarations } from './types';
import { Legend } from '../components/Legend';
import { useGridCellLayer } from '../map/GridCellLayer';
import { primaryKeyForSection } from '../Post/PostLayer';
import labelDictionary from './Incident_page_dictionaries/Grants_dict.json';
import { FillStyleExtension } from '@deck.gl/extensions';
import patternSprite from '../images/pattern.png';
import { layerVisibility } from '../map/atoms';
import { PopulationLegend, usePopulationLayer } from './PopulationLayer';
import { Box } from '@mui/material';

type Props = {
  extruded: boolean;
  visible: boolean;
  incidentId: string;
};

const patterns = ['dots', 'hatch-1x', 'hatch-2x', 'hatch-cross'];

const patternMapping = {
  'hatch-1x': {
    x: 4,
    y: 4,
    width: 120,
    height: 120,
    mask: true,
  },
  'hatch-2x': {
    x: 132,
    y: 4,
    width: 120,
    height: 120,
    mask: true,
  },
  'hatch-cross': {
    x: 4,
    y: 132,
    width: 120,
    height: 120,
    mask: true,
  },
  dots: {
    x: 132,
    y: 132,
    width: 120,
    height: 120,
    mask: true,
  },
};

export const useDeclarationsLayer = (props: Props) => {
  const allChunks = useRecoilValue(
    incidentChunksSelector({
      incidentId: props.incidentId,
      sectionId: 'declarations',
      visible: props.visible,
    })
  );
  const declarationsData = React.useMemo(
    () => allChunks.flatMap(a => a.data),
    [allChunks]
  );

  const selectedPrimaryKey = useRecoilValue(
    primaryKeyForSection('declarations')
  ) as keyof Declarations['features'][number]['properties'];

  const handleFillPattern = React.useCallback(
    (f: Declarations['features'][number]) => {
      switch (f.properties.designation_type) {
        case 'Individual Assistance and Public Assistance (A and B only)':
        case 'Public Assistance (A and B only)':
          return patterns[1];

        case 'Individual Assistance and Public Assistance':
        case 'Public Assistance':
          return patterns[3];

        case 'Individual Assistance':
          return patterns[0];
        case 'Individual Assistance and Public Assistance (B only)':
        case 'Public Assistance (B only)':
        default:
          return '';
      }
    },
    []
  );

  const handleFillColor = React.useMemo(
    () =>
      selectedPrimaryKey === 'designation_type'
        ? getDesignationFillColor(selectedPrimaryKey)
        : selectedPrimaryKey === 'Disaster_Declarations'
        ? getFillColor(selectedPrimaryKey)
        : undefined,
    [selectedPrimaryKey]
  );
  const visibleByUser = useRecoilValue(layerVisibility('declarations'));

  const populationLayer = usePopulationLayer({
    extruded: props.extruded,
    visible: props.visible,
    incidentId: props.incidentId,
  });

  const GridCellLayer = useGridCellLayer<Declarations['features'][number]>({
    id: 'declarations',
    incidentId: props.incidentId,
    // @ts-ignore
    data: declarationsData,
    primaryKey: selectedPrimaryKey,
    getFillColor: handleFillColor,
    visible: props.visible,
    extruded: props.extruded,
  });

  const PatternCellLayer = useGridCellLayer<Declarations['features'][number]>({
    id: 'declarations-pattern',
    incidentId: props.incidentId,
    // @ts-ignore
    data: declarationsData,
    primaryKey: selectedPrimaryKey,
    pickable: false,
    getFillColor: [255, 255, 255, 80],
    visible:
      props.visible &&
      visibleByUser &&
      selectedPrimaryKey === 'designation_type',
    extruded: props.extruded,
    extensions: [new FillStyleExtension({ pattern: true })],
    fillPatternMask: true,
    fillPatternAtlas: patternSprite,
    fillPatternMapping: patternMapping,
    getFillPattern: handleFillPattern,
    getFillPatternScale: 400,
    getFillPatternOffset: [0, 0],
  });

  return [GridCellLayer, PatternCellLayer, populationLayer];
};

const colorRange: RGBAColor[] = [
  [181, 9, 9, 100], // #B50909
  [219, 129, 0, 100], // #DB8100
  [226, 178, 0, 100], // #E2B200
  [143, 195, 201, 100], // #8FC3C9
  [95, 133, 188, 100], // #5F85BC
];

const getFillColor =
  (property: keyof Declarations['features'][number]['properties']) =>
  (d: Declarations['features'][number]): RGBAColor => {
    switch (d.properties['Disaster_Declarations']) {
      case 'Affected County':
        return colorRange[2];
      case 'Disaster Declared':
        return colorRange[0];
      default:
        return [0, 0, 0, 0];
    }
  };

const getDesignationFillColor =
  (property: keyof Declarations['features'][number]['properties']) =>
  (d: Declarations['features'][number]): RGBAColor => {
    if (d.properties['designation_type'].includes('Individual')) {
      return colorRange[0];
    } else if (d.properties['designation_type'].includes('Public')) {
      return colorRange[2];
    }
    if (d.properties['designation_type'].includes('Not Designated')) {
      return colorRange[3];
    }

    return [0, 0, 0, 0];
  };

const DisasterDeclarationsLegendItems = [
  {
    label: 'Affected County',
    color: `rgba(${colorRange[2]})`,
  },
  {
    label: 'Designated for Disaster Assistance',
    color: `rgba(${colorRange[0]})`,
  },
];

const DesignationTypeLegendItems = [
  {
    label: 'Individual Assistance and Public Assistance',
    color: `repeating-linear-gradient(45deg, transparent 46%, white 46%, white 56%, transparent 56%, transparent), repeating-linear-gradient(135deg, transparent 46%, white 46%, white 56%, transparent 56%, transparent), rgba(${colorRange[0]})`,
  },
  {
    label: 'Individual Assistance and Public Assistance (A and B only)',
    color: `linear-gradient(45deg, rgba(${colorRange[0]}) 46%, white 46%, white 56%, rgba(${colorRange[0]}) 56%, rgba(${colorRange[0]}))`,
  },

  {
    label: 'Individual Assistance and Public Assistance (B only)',
    color: `rgba(${colorRange[0]})`,
  },

  {
    label: 'Individual Assistance',
    color: `radial-gradient(circle at center, white 0, white 20%, rgba(${colorRange[0]}) 20%, rgba(${colorRange[0]}) 100%)`,
  },

  {
    label: 'Public Assistance',
    color: `repeating-linear-gradient(45deg, transparent 46%, white 46%, white 56%, transparent 56%, transparent), repeating-linear-gradient(135deg, transparent 46%, white 46%, white 56%, transparent 56%, transparent), rgba(${colorRange[2]})`,
  },

  {
    label: 'Public Assistance (A and B only)',
    color: `linear-gradient(45deg, rgba(${colorRange[2]}) 46%, white 46%, white 56%, rgba(${colorRange[2]}) 56%, rgba(${colorRange[2]}))`,
  },
  {
    label: 'Public Assistance (B only)',
    color: `rgba(${colorRange[2]})`,
  },
  {
    label: 'Not Designated',
    color: `rgba(${colorRange[3]})`,
  },
];

const PRIORITY_LEVELS = ['Highest', 'High', 'Medium', 'Low', 'Lowest'];

const declarationsMetricsKeys = [
  'designation_type',
  'Disaster_Declarations',
  'Exposed_Residences',
  'Percent_households_food_stamps',
  'Percent_25_and_over_bachelors_d',
  'Percent_25_and_over_high_school',
  'Percent_uninsured_health',
  'Percent_with_disability',
  'Percent_speak_English_less_than',
  'Percent_unemployment_16_years_a',
  'Percent_not_in_labor_force_16_y',
  'Percent_housing_units_mortgage',
  'Percent_home_insurance',
  'Percent_housing_owner_occupied',
  'Percent_households_public_assis',
].map(a => ({ key: a, label: (labelDictionary as any)[a] }));

const legendItems = colorRange.map((color, index) => ({
  label: `${index + 1} - ${PRIORITY_LEVELS[index]}`,
  color: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
}));

export const DeclarationsLegend = () => {
  const primaryKey = useRecoilValue(primaryKeyForSection('declarations'));

  const setLayerVisibliity = useSetRecoilState(layerVisibility('population'));

  React.useEffect(() => {
    setLayerVisibliity(false);
    return () => {
      setLayerVisibliity(true);
    };
  }, [setLayerVisibliity]);

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <PopulationLegend initialSize="minimized" />
      </Box>
      <Legend
        id="declarations"
        primaryProperties={declarationsMetricsKeys}
        elevationProperties={declarationsMetricsKeys}
        title={(labelDictionary as any)[primaryKey]}
        items={
          primaryKey === 'designation_type'
            ? DesignationTypeLegendItems
            : primaryKey === 'Disaster_Declarations'
            ? DisasterDeclarationsLegendItems
            : legendItems
        }
      />
    </>
  );
};
