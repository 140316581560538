/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import { HomeDrawer } from './Home';
import {
  IncidentDetailPage,
  IncidentMapChildren,
  IncidentPageDrawer,
} from './IncidentPage';
import { MapControls } from '../map/MapControls';
import { MapDrawer } from '../components/MapDrawer';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useSearchParams,
} from 'react-router-dom';
import { selectedRunTimeAtom } from '../Incident/atoms';
import { useRecoilValue } from 'recoil';
import React from 'react';
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels';
import { styled } from '@mui/material';

const Map = React.lazy(() => import('../map/Map'));

export type PrimaryLayer = 'wow' | 'ipaws' | 'post' | 'nifc' | 'canopy';

export const MapPage = React.memo(() => {
  const searchParams = useSearchParams();
  const canopyKmlId = searchParams[0].get('canopy_kml_id');
  const [transitionState, setTransitionState] = React.useState<
    'expanding' | 'collapsing' | 'idle'
  >('idle');

  const [selectedMap, setSelectedMap] = React.useState<PrimaryLayer>(
    canopyKmlId != null ? 'canopy' : 'wow'
  );

  React.useEffect(() => {
    if (canopyKmlId) {
      setSelectedMap('canopy');
    }
  }, [canopyKmlId]);

  const title = useMapTitle();
  const match = useMatch({
    path: '/:incidentId/:sectionId/:viewType/:attributeId?',
  });

  const { viewType } = match?.params ?? {};
  const panelRef = React.useRef<ImperativePanelHandle>(null);

  React.useEffect(() => {
    if (viewType === 'detail' && panelRef.current?.getCollapsed()) {
      setTransitionState('expanding');
      panelRef.current?.expand();
    }

    if (viewType == null || viewType === 'map') {
      setTransitionState('idle');
      panelRef.current?.collapse();
    }
  }, [viewType]);

  React.useEffect(() => {
    if (transitionState === 'expanding') {
      const timeout = setTimeout(() => {
        setTransitionState('idle');
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [transitionState]);

  return (
    <React.Suspense fallback={null}>
      <PanelGroup direction="horizontal">
        <Panel minSize={30}>
          <Map
            miniMap={viewType === 'detail'}
            title={title}
            selectedMap={selectedMap}
            outerChildren={
              <>
                <MapControls
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                />
              </>
            }
          >
            <Routes>
              <Route
                path=":incidentId/:sectionId/map"
                element={<IncidentMapChildren />}
              />
              <Route
                path=":incidentId/:sectionId"
                element={<Navigate replace to="map" />}
              />
            </Routes>
          </Map>
        </Panel>
        <>
          {viewType === 'detail' && <StyledResizeHandle />}
          <Panel
            ref={panelRef}
            minSize={26}
            defaultSize={viewType == null || viewType === 'map' ? 0 : 26}
            style={
              transitionState === 'expanding'
                ? {
                    transition: 'flex 500ms cubic-bezier(0, 0, 0.2, 1)',
                    zIndex: 9999,
                  }
                : {}
            }
            collapsible
          >
            <React.Suspense fallback={null}>
              <Routes>
                <Route
                  path=":incidentId/:sectionId/detail/:attributeId?"
                  element={<IncidentDetailPage />}
                />
              </Routes>
            </React.Suspense>
          </Panel>
        </>
      </PanelGroup>

      <MapDrawer>
        <Routes>
          <Route
            path=":incidentId/:sectionId/:viewType/:attributeId?"
            element={<IncidentPageDrawer />}
          />

          <Route
            path="/"
            element={
              <HomeDrawer
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
              />
            }
          />
        </Routes>
      </MapDrawer>
      <Routes>
        <Route
          path=":incidentId/:sectionId/:viewType/:attributeId?"
          element={null}
        />
      </Routes>
    </React.Suspense>
  );
});

const useMapTitle = () => {
  const isIncidentMap = useMatch({
    path: ':incidentId/:sectionId/:viewType/:attributeId?',
  });

  const selectedRuntime = useRecoilValue(
    selectedRunTimeAtom(
      `${isIncidentMap?.params.incidentId}-${isIncidentMap?.params.sectionId}`
    )
  );

  return isIncidentMap
    ? `${isIncidentMap?.params.incidentId}_${
        isIncidentMap?.params.sectionId
      }_${new Date(selectedRuntime?.time ?? new Date()).toUTCString()}_${
        selectedRuntime?.comments
      }`
    : 'Map';
};

const StyledResizeHandle = styled(PanelResizeHandle)`
  width: 8px;
  background-color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;
