/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React from 'react';
import { MapOutlined } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem, Slider } from '@mui/material';
import { atom, useRecoilState } from 'recoil';
import { userAtom } from '../auth/userAtoms';

// type StyleOptions =
//   | 'Ocean/World_Ocean_Base'
//   | 'World_Imagery'
//   | 'openstreetmap'
//   | 'Canvas/World_Dark_Gray_Base'
//   | 'Canvas/World_Light_Gray_Base';

type StyleOptions =
  | 'ArcGIS:Imagery'
  | 'ArcGIS:LightGray'
  | 'ArcGIS:DarkGray'
  | 'ArcGIS:Navigation'
  | 'ArcGIS:NavigationNight'
  | 'ArcGIS:Streets'
  | 'ArcGIS:StreetsNight'
  | 'ArcGIS:Topographic'
  | 'OSM:Standard'
  | 'planet';

const STYLE_KEYS: StyleOptions[] = [
  'ArcGIS:Imagery',
  'ArcGIS:LightGray',
  'ArcGIS:DarkGray',
  'ArcGIS:Navigation',
  'ArcGIS:NavigationNight',
  'ArcGIS:Streets',
  'ArcGIS:StreetsNight',
  'ArcGIS:Topographic',
  'OSM:Standard',
  'planet',
];

export const mapStyleAtom = atom({
  key: 'mapStyle',
  default: 'ArcGIS:StreetsNight',
});

export const mapImageryAtom = atom<string | null>({
  key: 'mapImagery',
  default: null,
});

export const mosaicAtom = atom<string>({
  key: 'mosaic',
  default: 'global_monthly_2016_05_mosaic',
});

const planetKey = 'PLAK54e73183ee41466faabd3f67414f044d';

export interface MosaicLinks {
  _self: string;
  tiles: string;
}

export interface Grid {
  quad_size: number;
  resolution: number;
  quad_pattern?: string;
}

export interface Mosaic {
  _links: MosaicLinks;
  bbox: number[];
  coordinate_system: string;
  datatype: string;
  first_acquired: string;
  grid: Grid;
  id: string;
  interval: string;
  item_types: string[];
  last_acquired: string;
  level: number;
  name: string;
  product_type: string;
}

export const MosaicPicker = () => {
  const [mosaics, setMosaics] = React.useState<Mosaic[]>([]);
  const [mosaic, setMosaic] = useRecoilState<string>(mosaicAtom);
  const planetUrl = `https://api.planet.com/basemaps/v1/mosaics?api_key=${planetKey}`;

  const handleFetchMosaic = React.useCallback(
    (url: string) => {
      fetch(url)
        .then(res => res.json())
        .then(
          (data: {
            _links: { _next: string; _self: string };
            mosaics: Mosaic[];
          }) => {
            setMosaics(mosaics => [...mosaics, ...data.mosaics]);
            if (data?._links?._next != null) {
              handleFetchMosaic(data._links._next);
            }
          }
        )
        .catch(err => {
          console.log(err);
        });
    },
    [setMosaics]
  );

  React.useEffect(() => {
    handleFetchMosaic(planetUrl);
  }, [handleFetchMosaic, planetUrl]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleMenuClick = React.useCallback(
    (mosaic: string) => {
      setMosaic(mosaic);
      handleClose();
    },
    [handleClose, setMosaic]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: 300,
        backgroundColor: 'white',
        borderRadius: 1,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<MapOutlined />}
      >
        {new Date(
          // @ts-ignore
          mosaics?.find(f => f.name === mosaic)?.first_acquired
        ).toLocaleDateString() ?? 'Planet Mosaic'}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ maxHeight: 500 }}
      >
        {[...mosaics]
          .sort(
            (a, b) =>
              new Date(b.first_acquired).valueOf() -
              new Date(a.first_acquired).valueOf()
          )
          .map(mosaic => (
            <MenuItem dense onClick={() => handleMenuClick(mosaic.name)}>
              {`${new Date(mosaic.first_acquired).toLocaleDateString()} -
              ${new Date(mosaic.last_acquired).toLocaleDateString()}`}
            </MenuItem>
          ))}
      </Menu>
      <Slider
        size="small"
        track={false}
        aria-labelledby="time-slider"
        min={0}
        max={mosaics.length - 1}
        value={mosaics.map(m => m.name).indexOf(mosaic ?? '')}
        sx={{ width: 200, m: 1 }}
        onChange={(event, value) => {
          if (typeof value === 'number') {
            setMosaic([...mosaics.map(m => m.name)][value]);
          }
        }}
      />
    </Box>
  );
};

export const MapStylePicker = React.memo(() => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [user] = useRecoilState(userAtom);

  const [mapStyle, setMapStyle] = useRecoilState(mapStyleAtom);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleMenuClick = React.useCallback(
    (style: StyleOptions) => {
      setMapStyle(style);
      handleClose();
    },
    [handleClose, setMapStyle]
  );

  return (
    <>
      <div className="style-picker">
        <Button
          variant="contained"
          color="primary"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          startIcon={<MapOutlined />}
          sx={{ backdropFilter: 'blur(10px)' }}
        >
          Base Map
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {STYLE_KEYS.filter(k => {
            if (k === 'planet') {
              if (user != null && user.username === 'FEMA_TEMPO') {
                return true;
              } else {
                return false;
              }
            }
            return true;
          }).map(style => (
            <MenuItem dense key={style} onClick={() => handleMenuClick(style)}>
              {style}
            </MenuItem>
          ))}
        </Menu>
      </div>
      {mapStyle === 'planet' && <MosaicPicker />}
    </>
  );
});
