/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React from 'react';
import { Feature, Id, Polygon } from '@turf/helpers';
import { FlyToInterpolator, RGBAColor } from '@deck.gl/core';
import { GeoJsonLayer } from '@deck.gl/layers';
import { ipawsChunksSelector } from './selectors';
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { IPAWSProperties } from './types';
import { Legend } from '../components/Legend';
import { PathStyleExtension } from '@deck.gl/extensions';
import { RGBColor } from '@deck.gl/core/utils/color';
import { viewStateAtom } from '../map/atoms';
import { useMatch } from 'react-router-dom';
import { layerVisibility } from '../map/atoms';
import { selectedFeatureAtom } from '../Incident/FeaturePopup';

export const selectedAlertAtom = atom<Feature<
  Polygon,
  Partial<IPAWSProperties>
> | null>({
  key: 'selectedAlert',
  default: null,
});

type Props = {
  visible: boolean;
};

export const useIPAWSLayer = (props: Props) => {
  const match = useMatch({ path: '/' });
  const ipawsData = useRecoilValueLoadable(ipawsChunksSelector(match != null));
  const [selectedAlert] = useRecoilState(selectedFeatureAtom);

  const setViewState = useSetRecoilState(viewStateAtom);
  const visibleByUser = useRecoilValue(layerVisibility('ipaws'));

  const getLineColor = React.useCallback(
    (d: Feature<Polygon, IPAWSProperties> | null): RGBAColor => {
      if (d?.id === selectedAlert?.id) {
        return [0, 0, 255, 255];
      }
      if (d?.properties.event) {
        const eventColor = ipawsColorMap?.[d?.properties?.event];
        if (eventColor) {
          return eventColor;
        }
        return [0, 0, 0, 255];
      }
      return [0, 0, 0, 255];
    },
    [selectedAlert]
  );
  const getLineWidth = React.useCallback(
    (d: Feature<Polygon, IPAWSProperties> | null): number => {
      if (d?.id === selectedAlert?.id) {
        return 1000;
      }
      return 1;
    },
    [selectedAlert]
  );

  const handleClick = React.useCallback(
    (info: any) => {
      if (props.visible && info?.object) {
        // @ts-ignore
        setViewState((view: any) => ({
          ...view,
          longitude: info?.coordinate?.[0],
          latitude: info?.coordinate?.[1],
          transitionInterpolator: new FlyToInterpolator({ speed: 3.0 }),
          transitionDuration: 1000,
        }));
      }
    },
    [setViewState, props.visible]
  );

  const handleFillColor = React.useCallback(
    (f: any) => getIpawsFillColor(f, selectedAlert?.id ?? null),
    [selectedAlert]
  );

  const isVisible = props.visible && visibleByUser;

  const layers = React.useMemo(
    () =>
      ipawsData.state === 'hasValue'
        ? ipawsData.contents.map(
            (data, index) =>
              new GeoJsonLayer<Feature<Polygon, IPAWSProperties> | null>({
                id: `ipaws-${index}`,
                // @ts-ignore
                data: data,
                getFillColor: handleFillColor,
                pickable: isVisible,
                // highlightColor: [255, 255, 0, 255],
                autoHighlight: false,
                // highlightedObjectIndex:
                //   data?.features?.map(f => f.id)?.indexOf(selectedAlert?.id ?? -1) ??
                //   -1,
                visible: isVisible,
                extensions: [new PathStyleExtension({ offset: true })],
                // @ts-ignore
                getOffset: 0,
                onClick: handleClick,
                getLineColor,
                getLineWidth,
                lineWidthMinPixels: 1,
                lineWidthScale: 1,
                stroked: isVisible,
                updateTriggers: {
                  highlightedObjectIndex: [selectedAlert?.id],
                  getFillColor: [selectedAlert?.id],
                  getLineWidth: [selectedAlert?.id],
                  getLineColor: [selectedAlert?.id],
                },
              })
          )
        : [],
    [
      handleFillColor,
      handleClick,
      getLineColor,
      getLineWidth,
      ipawsData,
      selectedAlert?.id,
      isVisible,
    ]
  );

  return layers;
};

export const ipawsColorMap: Record<string, RGBColor> = {
  'Blizzard Warning': [253, 68, 3],
  'Hurricane Warning': [216, 0, 49],
  'Fire Weather Watch': [255, 221, 176],
  'Flood Advisory': [0, 254, 127],
  'Flood Warning': [1, 255, 0],
  'Flash Flood Warning': [127, 23, 14],
  'Flood Watch': [74, 137, 92],
  'High Wind Warning': [219, 165, 33],
  'High Wind Watch': [184, 134, 11],
  'Lake Wind Advisory': [204, 181, 145],
  'Lakeshore Flood Advisory': [157, 249, 77],
  'Marine Weather Statement': [254, 218, 184],
  'Red Flag Warning': [255, 21, 148],
  'Severe Thunderstorm Warning': [241, 169, 59],
  'Severe Thunderstorm Watch': [205, 118, 146],
  'Special Weather Statement': [255, 229, 181],
  'Storm Warning': [149, 0, 212],
  'Tropical Storm Warning': [164, 48, 43],
  'Tornado Warning': [250, 1, 2],
  'Tornado Watch': [255, 254, 0],
  'Wind Advisory': [209, 180, 140],
  'Wind Chill Advisory': [174, 238, 239],
  'Winter Storm Warning': [255, 104, 181],
  'Winter Storm Watch': [70, 130, 180],
  'Winter Weather Advisory': [124, 104, 238],
  nifc: [255, 221, 176],
  tornado_rank_5: [181, 9, 9],
  tornado_rank_4: [219, 129, 0],
  tornado_rank_3: [226, 178, 0],
  tornado_rank_2: [143, 195, 201],
  tornado_rank_1: [95, 133, 188],
};

const getIpawsFillColor = (
  d: Feature<Polygon, IPAWSProperties> | null,
  selectedId: Id | null
): RGBAColor => {
  const alpha = selectedId == null ? 80 : selectedId === d?.id ? 80 : 80;

  if (d?.properties.event) {
    const eventColor = ipawsColorMap?.[d?.properties?.event];
    if (eventColor) {
      return [...eventColor, alpha];
    }
  }

  return [255, 255, 255, alpha];
};

export const IpawsLegend = () => {
  const ipawsData = useRecoilValue(ipawsChunksSelector(true));

  const eventCount = ipawsData.reduce<Record<string, number>>((acc, chunk) => {
    const events = chunk.features.map(f => f.properties.event);
    const chunkEvents = events.reduce<Record<string, number>>(
      (chunkAcc, event) => {
        if (event in acc) {
          chunkAcc[event]++;
        } else {
          chunkAcc[event] = 1;
        }
        return acc;
      },
      acc
    );

    return chunkEvents;
  }, {});

  return (
    <Legend
      id="ipaws"
      disableZoomToExtent
      title="IPAWS Events"
      items={Object.keys(eventCount)
        .sort()
        .map(event => ({
          label: event,
          color: `rgb(${ipawsColorMap[event]})`,
          value: eventCount[event],
        }))}
    />
  );
};
