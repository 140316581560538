/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import * as React from 'react';
import { incidentChunksSelector } from './atoms';
import { Legend } from '../components/Legend';
import { ResidentialStructures } from './types';
import { RGBAColor } from '@deck.gl/core/utils/color';
import { primaryKeyForSection } from '../Post/PostLayer';
import { useGridCellLayer } from '../map/GridCellLayer';
import { useRecoilValue } from 'recoil';
import residentialDict from './Incident_page_dictionaries/ResidentialStructures_dict.json';
import { selectedElevationKeyAtom } from '../map/atoms';

type Props = {
  extruded: boolean;
  visible: boolean;
  incidentId: string;
};

export const useResidentialStructureLayer = (props: Props) => {
  const allChunks = useRecoilValue(
    incidentChunksSelector({
      incidentId: props.incidentId,
      sectionId: 'residential',
      visible: props.visible,
    })
  );
  const residentialStructureData = React.useMemo(
    () => allChunks.flatMap(a => a.data),
    [allChunks]
  );

  const selectedElevationKey = useRecoilValue<
    keyof ResidentialStructures['features'][number]['properties']
  >(selectedElevationKeyAtom);
  const selectedPrimaryKey = useRecoilValue(
    primaryKeyForSection('residential')
  );

  const GridCellLayer = useGridCellLayer<
    ResidentialStructures['features'][number]
  >({
    id: 'residential',
    incidentId: props.incidentId,
    // @ts-ignore
    data: residentialStructureData,
    extruded: props.extruded,
    visible: props.visible,
    getFillColor:
      selectedPrimaryKey === 'exposurescoreformap' ? getFillColor : undefined,
    getElevation: d => {
      const value = d.properties[selectedElevationKey];
      if (typeof value === 'number') {
        return value;
      }
      return 0;
    },
  });

  return GridCellLayer;
};

const colorRange: RGBAColor[] = [
  [181, 9, 9, 190], // #B50909
  [219, 129, 0, 190], // #DB8100
  [226, 178, 0, 190], // #E2B200
  [143, 195, 201, 190], // #8FC3C9
  [95, 133, 188, 190], // #5F85BC
];

const getFillColor = (
  d: ResidentialStructures['features'][number]
): RGBAColor => {
  const { exposurescoreformap } = d.properties;
  switch (exposurescoreformap) {
    case 1:
      return colorRange[0];
    case 2:
      return colorRange[1];
    case 3:
      return colorRange[2];
    case 4:
      return colorRange[3];
    case 5:
      return colorRange[4];
    default:
      return [0, 0, 0, 0];
  }
};

const elevationKeys = [
  'exposurescoreformap',
  'residential',
  'ls_day_pop',
  'ls_night_pop',
  'pop',
  'households',
  'housingunits',
  'pop65over',
  'pop16overunemployed',
  'pop16overnotinlaborforce',
  'popamindianalaskan',
  'householdsinpoverty',
  'householdsdsfs',
  'householdsdsnofs',
  'householdswithfs',
  'householdswithpa',
  'housingunitsmobilehomes',
  'lep',
] as const;

export const postKeyToLabel = {
  event_id: 'Grouped IPAWS event',
  usng5km: '5km USNG cell',
  event: 'IPWAS event type',
  effective: 'Effective',
  expires: 'Expires',
  quantile_sum: 'Rank Sum',
  final_rank: 'Unscaled Rank',
  priority_rank: 'POST Priority',
  run_time: 'POST run time',
  ...residentialDict,
} as const;

const PRIORITY_LEVELS = [
  'Highest Priority',
  'High Priority',
  'Medium Priority',
  'Low Priority',
  'Lowest Priority',
];

const legendItems = colorRange.map((color, index) => ({
  label: `${index + 1} - ${PRIORITY_LEVELS[index]}`,
  color: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
}));

const elevationProperties = elevationKeys.map(key => ({
  label: postKeyToLabel?.[key] ?? key,
  key,
}));

export const ResidentialLegend = () => {
  const primaryKey = useRecoilValue(
    primaryKeyForSection('residential')
  ) as keyof typeof postKeyToLabel;

  return (
    <Legend
      id="residential"
      title={postKeyToLabel[primaryKey]}
      items={legendItems}
      primaryProperties={[...elevationProperties]}
      elevationProperties={elevationProperties}
    />
  );
};
