/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Feature } from '@turf/turf';
import { atom, useRecoilValue } from 'recoil';
import countyDict from './Incident_page_dictionaries/County_dict.json';
import communicationsDict from './Incident_page_dictionaries/Communication_dict.json';
import commoditiesDict from './Incident_page_dictionaries/Commodities_dict.json';
import energyDict from './Incident_page_dictionaries/Energy_dict.json';
import foodWaterDict from './Incident_page_dictionaries/FoodWaterShelter_dict.json';
import healthDict from './Incident_page_dictionaries/HealthAndMedical_dict.json';
import nonResidentialDict from './Incident_page_dictionaries/NonResidentialStructures_dict.json';
import populationExposureDict from './Incident_page_dictionaries/PopulationExposure_dict.json';
import residentialStructuresDict from './Incident_page_dictionaries/ResidentialStructures_dict.json';
import transportationDict from './Incident_page_dictionaries/Transportation_dict.json';
import grantsDict from './Incident_page_dictionaries/Grants_dict.json';
import wowDict from './Incident_page_dictionaries/Wow_dict.json';
import {
  getPercentColor,
  getRankColor,
  primaryKeyForSection,
} from '../Post/PostLayer';
import { useMatch } from 'react-router-dom';
import { IncidentSection } from '../pages/IncidentPage';

const RANK_KEYS: string[] = [
  'priority_rank',
  'exposurescore',
  'exposurescoreformap',
  'impactscore',
  'exposurescore_allstructures',
  'hf_np_exposure',
  'hf_hp_exposure',
  'nf_hp_exposure',
  'nf_np_impact',
  'exposurescore_agriculturalru',
  'exposurescore_commercialoff',
  'exposurescore_commercialret',
  'exposurescore_exemptgovernm',
  'exposurescore_heavyindustria',
  'exposurescore_industrialgen',
  'exposurescore_miscellaneous',
  'exposurescore_recreational',
  'exposurescore_residential',
  'exposurescore_vacantland',
].map(k => k.toLowerCase());

const PERCENT_RANK_KEYS = ['PEOPLEINNEED', 'SHELTERCAPACITYPERCENT'].map(k =>
  k.toLowerCase()
);

const sectionHighlights: Record<IncidentSection | 'canopy', string[]> = {
  canopy: ['event_name', 'ef_rank', 'structures_exposed'],
  population: [
    'LS_NIGHT_POP',
    'HOUSINGUNITS',
    'POP65OVER',
    'LEP',
    'HOUSEHOLDSWITHFS',
    'HOUSEHOLDSWITHPA',
  ],
  residential: ['residential', 'totalnumberofrelevantstructure'],
  nonResidentialStructures: [
    'AGRICULTURALRU',
    'COMMERCIALOFF',
    'COMMERCIALRET',
    'EXEMPTGOVERNM',
    'HEAVYINDUSTRIA',
    'INDUSTRIALGEN',
    'RECREATIONAL',
  ],
  // foodWaterShelter: [
  //   'SHELTERCAPACITYPERCENT',
  //   'BOILWATERAFFECTEDPOPULATION',
  //   'POPINOPENSHELTERS',
  //   'SHELTERTOTALCAPACITY',
  //   'SHELTERSCLOSED',
  //   'SHELTERSOPEN',
  //   'COUNTIESSJ_SHELTERCAP_FIPS',
  //   'COUNTIESSJ_SHELTERCAP_FREQUENCY',
  //   'COUNTIESSJ_SHELTERCAP_FREQUENC',
  //   'COUNTIESSJ_POPSERVED_SUM_POPUL',
  // ],
  // healthMedical: [
  //   'DIALYSIS_CENTER',
  //   'HOSPITALS',
  //   'NURSING_HOMES',
  //   'URGENT_CARE',
  // ],
  // transportation: ['ROAD_CLOSED', 'TOTALNUMALERTS'],
  // energy: [
  //   'APPROXIMATENUMBEROFCUSTOMERSNOP',
  //   'HAS_FUEL__NO_POWER',
  //   'NO_FUEL__HAS_POWER',
  //   'NO_FUEL__NO_POWER',
  //   'has_fuel__has_power',
  // ],
  communications: [
    'CELL_SITES_OUT',
    'CELL_SITES_SERVED',
    'CELL_SITES_OUT_DUE_TO_POWER',
    'CELL_SITES_OUT_DUE_TO_TRANSPOR',
    'CELL_SITES_OUT_DUE_TO_DAMAGE',
  ],
  // commodities: [
  //   'VULNERABLEPOP',
  //   'PLASTICSHEETREQS',
  //   'PEOPLEINNEED',
  //   'WATERREQ',
  //   'MEALREQ',
  //   'miscellaneous',
  //   'vacantland',
  //   'rpl_themes',
  //   'f_total',
  //   'hdrreqs',
  //   'dmereqs',
  //   'medicalsuppliesreqs',
  //   'infanttodkitreqs',
  //   'totalcotreqs',
  //   'normalcotreqs',
  //   'edccotreqs',
  //   'blanketreqs',
  //   'daysofresponse',
  //   'tpmr',
  //   'cmr',
  //   'smr',
  //   'edcotreqs',
  //   'infanttodkitsreqs',
  // ],
  declarations: [
    'is_affected',
    'is_designated',
    'designation_type',
    'Disasters_last_12_months',
    'Exposed_Residences',
    'HMA_Grant_Count',
    'HMA_Grant_Total_Cost',
    'HMA_Grant_Federal_Share_Total_C',
    'Disaster_Declarations',
    'Percent_home_insurance',
  ],
};

const subcategories: {
  label: string;
  keys: string[];
}[] = [
  {
    label: 'Highlights',
    keys: [
      ...RANK_KEYS,
      'totalnumfacilities',
      'PERCENT_OUT',
      'POPULATIONAFFECTED',
      'Event',
      'LASTUPDATEDON',
      'COMMENTS',
    ],
  },
  {
    label: 'POST priorties',
    keys: [
      'ef_rank',
      'Threat',
      'Category',
      'ThreatLevel',
      'ThreatDescription',
      'event_name',
      'priority_rank',
      'final_rank',
      'run_time',
      'quantile_sum',
      'effective',
      'expires',
      'lep',
      'event_id',
      'DataSource',
      'time_category',
    ],
  },
  {
    label: 'Demographics',
    keys: [
      'age65',
      'amalask',

      'land_scan_day_pop',
      'land_scan_night_pop',
      'nolaborforce16',
      'per_amalask',
      'per_dhisnfs',
      'per_hdisfs',
      'per_hfsnap',
      'per_hpa',
      'per_hpov',
      'per_humb',
      'per_lep',
      'per_nolaborforce16',
      'per_pop65',
      'per_unemp16',
      'sum_lep',
      'unemp16',
      'ls_day_pop',
      'ls_night_pop',
      'pop',
      'population',
      'pop65over',
      'pop16overunemployed',
      'pop16overnotinlaborforce',
      'popamindianalaskan',
      'Percent_receving_SSI',
      'Per_capita_income',
      'Percent_AIAN',
      'Percent_age_under_18',
      'Percent_age_65_and_over',
      'Percent_below_poverty_level',
      'Percent_households_food_stamps',
      'Percent_25_and_over_bachelors_d',
      'Percent_25_and_over_high_school',
      'Percent_uninsured_health',
      'Percent_with_disability',
      'Percent_speak_English_less_than',
      'Percent_unemployment_16_years_a',
      'Percent_not_in_labor_force_16_y',
    ],
  },
  {
    label: 'Location details',
    keys: [
      'usng5km',
      'usng1km',
      'usng_resolution',
      'state',
      'county',
      'fips',
      'state_fips',
      'households',
      'housingunits',
      'householdsinpoverty',
      'householdsdsfs',
      'householdsdsnofs',
      'householdswithfs',
      'householdswithpa',
      'housingunitsmobilehomes',
      'county_cell_tower_count',
      'county_dialysis_count',
      'county_gas_station_count',
      'county_hospital_count',
      'county_name',
      'county_nursing_home_count',
      'county_shelter_count',
      'hdisfs',
      'hdisnfs',
      'hfsnap',
      'hholds',
      'hpa',
      'hpov',
      'humb',
      'hunits',
      'NAME',
      'STATE_NAME',
      'State_Tax_Revenue_in_millions_o',
      'State_GDP_in_millions_of_dollar',
      'State_Total_Taxable_Resources_i',
      'Percent_housing_units_mortgage',
      'Percent_housing_owner_occupied',
      'Percent_households_public_assis',
      'Mean_household_income',
      'Median_household_income',
      'COUNTIES_NAME',
      'COUNTIES_STATE_NAME',
      'COUNTIES_CNTY_FIPS',
      'COUNTIES_POPULATION',
      'COUNTIES_POP2010',
      'COUNTIESSJ_POPSERVED_SUM_POPULA',
      'structures_exposed',
      'county_intersect',
      'state_intersect',
      'fips_intersect',
      'BUILD_ID',
      'OCC_CLS',
      'PRIM_OCC',
      'SEC_OCC',
      'PROP_ADDR',
      'PROP_CITY',
      'PROP_ST',
      'PROP_ZIP',
      'OUTBLDG',
      'HEIGHT',
      'SQMETERS',
      'SQFEET',
      'H_ADJ_ELEV',
      'L_ADJ_ELEV',
      'FIPS',
      'CENSUSCODE',
      'PROD_DATE',
      'SOURCE',
      'USNG',
      'LONGITUDE',
      'LATITUDE',
      'STATE_FIPS',
      'REMARKS',
      'exposure_binary',
      'exposure_quantity',
      'exposure_source',
      'exposure_binary_list',
      'exposure_quantity_list',
      'exposure_source_list',
      'damage_assessment_category',
      'damage_assessment_source',
    ],
  },
];

// const hiddenProperties = [
//   'id',
//   'geoid',
//   'globalid',
//   'geoid_1644434122041',
//   'fips_1',
//   'OBJECTID',
//   'OBJECTID_1',
//   'SHAPE__Area',
//   'SHAPE__Length',
//   'creationdate',
//   'creator',
//   'editdate',
//   'editor',
//   'date_',
//   'join_count',
//   'target_fid',
//   'cnty_fips',
//   'state_abbr',
//   'county_st_affected',
//   'county_state',
//   'affected_counties',
//   'countycaps',
//   'fema_regio',
//   'totalnumberofrelevantstructu_1',
//   'risk_score_max',
//   'hrcn_risks_max',
//   'avln_risks_max',
//   'cfld_risks_max',
//   'cwav_risks_max',
//   'drgt_risks_max',
//   'erqk_risks_max',
//   'hail_risks_max',
//   'hwav_risks_max',
//   'istm_risks_max',
//   'lnds_risks_max',
//   'ltng_risks_max',
//   'rfld_risks_max',
//   'swnd_risks_max',
//   'trnd_risks_max',
//   'tsun_risks_max',
//   'vlcn_risks_max',
//   'wfir_risks_max',
//   'wntw_risks_max',
//   'usng5km_1',
//   'residentialc',
//   'agriculturalruc',
//   'commercialoffc',
//   'commercialretc',
//   'exemptgovernmc',
//   'heavyindustriac',
//   'industrialgenc',
//   'miscellaneousc',
//   'recreationalc',
//   'vacantlandc',
//   'countiessj_sheltercap_objectid',
//   'lastupdatedon1',
// ].map(k => k.toLowerCase());

export const selectedFeatureAtom = atom<Feature | any | null>({
  key: 'selectedFeature',
  default: null,
});

export const commonDictionary = {
  canopy_published_date: 'Canopy Published Date',
  Category: 'Category',
  CommercialOfficeImpacts: 'Commercial Office Impact',
  county_intersect: 'County intersect',
  county: 'County',
  DataSource: 'Data Source',
  ef_rank: 'Enhanced Fujita Rank',
  effective: 'Effective',
  event_id: 'Grouped IPAWS event',
  event_name: 'Event Name',
  event: 'IPWAS event type',
  expires: 'Expires',
  ExposureScore: 'Exposure Score',
  final_rank: 'Unscaled Rank',
  fips_intersect: 'FIPS intersect',
  fips: 'County FIPS code',
  households: 'Number of Households',
  householdsdsfs: 'Number of Households on disability and food stamps',
  householdsdsnofs: 'Number of Households on disability no food stamps',
  householdsinpoverty: 'Number of Households in poverty',
  householdswithfs: 'Number of Households with food stamps/SNAP',
  householdswithpa: 'Number of Households with public assistance',
  housingunits: 'Number of Housing units',
  housingunitsmobilehomes: 'Number of Housing units that are mobile homes',
  ImpactScore: 'Impact Score',
  IncidentName: 'Incident Name',
  LastUpdatedOn: 'Last Updated On',
  lep: 'Number of Households with Limited English Proficiency',
  ls_day_pop: 'LandScan Daytime Population',
  ls_night_pop: 'LandScan Nightime Population',
  PercentOut: 'Percent Out',
  pop: 'Population',
  pop16overnotinlaborforce: 'Population 16+ not in labor force',
  pop16overunemployed: 'Population age 16+ and unemployed',
  pop65over: 'Population age 65 and over',
  popamindianalaskan: 'Population American Indian or Alaska Native',
  priority_rank: 'POST Priority',
  quantile_sum: 'Rank Sum',
  RankPotentialPowerOutageImpact: 'Rank Potential Power Outage Impact',
  run_time: 'POST run time',
  state_intersect: 'State intersect',
  state: 'State',
  structures_exposed: 'Structures Exposed',
  Threat: 'Threat',
  ThreatDescription: 'Threat Description',
  ThreatLevel: 'Threat Level',
  time_category: 'Time Category',
  usng5km: '5km USNG cell',
  created_at: 'Created At',
  Shape__Area: 'Shape Area',
  Shape__Length: 'Shape Length',
  kml_id: 'KML ID',
  BUILD_ID: 'Build ID',
  OCC_CLS: 'Occupancy Class',
  PRIM_OCC: 'Primary Occupancy',
  SEC_OCC: 'Secondary Occupancy',
  PROP_ADDR: 'Property Address',
  PROP_CITY: 'Property City',
  PROP_ST: 'Property State',
  PROP_ZIP: 'Property Zip Code',
  OUTBLDG: 'Outbuilding',
  HEIGHT: 'Height',
  SQMETERS: 'Square Meters',
  SQFEET: 'Square Feet',
  H_ADJ_ELEV: 'Height Adjusted Elevation',
  L_ADJ_ELEV: 'Low Adjusted Elevation',
  FIPS: 'FIPS Code',
  CENSUSCODE: 'Census Code',
  PROD_DATE: 'Production Date',
  SOURCE: 'Source',
  USNG: 'US National Grid',
  LONGITUDE: 'Longitude',
  LATITUDE: 'Latitude',
  IMAGE_NAME: 'Image Name',
  IMAGE_DATE: 'Image Date',
  VAL_METHOD: 'Validation Method',
  REMARKS: 'Remarks',
  UUID: 'UUID',
  STATE_FIPS: 'State FIPS Code',
};

export const postKeyToLabel: any = {
  county: countyDict,
  canopy: {
    ...commonDictionary,
    structures_exposed: 'Structures Exposed in Swath',
  },
  communications: communicationsDict,
  commodities: commoditiesDict,
  energy: energyDict,
  foodWaterShelter: foodWaterDict,
  healthMedical: healthDict,
  transportation: transportationDict,
  residential: residentialStructuresDict,
  population: populationExposureDict,
  nonResidentialStructures: nonResidentialDict,
  declarations: grantsDict,
  'wow-perimeter': wowDict,
} as const;

export const FeatureInfo = (feature: Feature<any, any>, layerId: string) => {
  const isCanopy = feature.properties.canopy_published_date != null;

  const match = useMatch({
    path: ':incidentId/:sectionId/:viewType/:attributeId?',
  });
  const sectionKey = isCanopy
    ? 'canopy'
    : ((match?.params.sectionId ?? '') as IncidentSection);

  const primaryKey = useRecoilValue(primaryKeyForSection(sectionKey ?? ''));
  const { properties } = feature;

  const highlights = sectionHighlights[sectionKey];

  const subcats = React.useMemo(() => {
    const [first, ...rest] = subcategories;

    const subcategoriesWithHighlights = [
      {
        ...first,
        keys: [...(highlights ?? []), ...first.keys],
      },
      ...rest,
    ];

    if (feature == null) {
      return subcategoriesWithHighlights;
    }

    return subcategoriesWithHighlights;
  }, [feature, highlights]);

  if (properties == null) {
    return null;
  }

  return (
    <Box
      sx={{
        pl: 1,
        pr: 1,
        minWidth: 354,
        backgroundColor: 'rgb(255, 255, 255)',
        borderLeft: `4px solid rgb(${getRankColor(properties[primaryKey])})`,
      }}
    >
      <Typography
        variant="caption"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <span>{properties.event ?? properties.EVENT}</span>
      </Typography>
      <Typography variant="h6">
        {properties?.county ??
          properties?.COUNTY ??
          properties?.county_name ??
          properties?.County_State}{' '}
        {['declarations', 'communications'].includes(sectionKey) && (
          <>
            {properties?.name}, {properties?.state_name}
          </>
        )}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          // width: '90%',
        }}
      >
        {properties.usng5km && <span>USNG: {properties.usng5km}</span>}
        {properties.USNG5KM && <span>USNG: {properties.USNG5KM}</span>}
        {properties.USNG1KM && <span>USNG: {properties.USNG1KM}</span>}

        <span>
          FIPS:{' '}
          {properties?.fips ??
            properties?.FIPS ??
            properties?.COUNTIES_CNTY_FIPS ??
            properties.geoid}
        </span>
      </Typography>

      <Box sx={{ mt: 1 }}>
        {subcats.map((subcat, index) => {
          const categoryProps = lowercaseKeys(properties);

          const keys = subcat.keys.filter(
            subkey => categoryProps?.[subkey.toLowerCase()] != null
          );

          if (keys?.length === 0) {
            return null;
          }

          return (
            <details
              key={index}
              open={index === 0}
              style={{
                borderBottom: '1px solid rgb(207, 226, 240)',
              }}
            >
              <summary
                style={{
                  padding: '8px',
                }}
              >
                {subcat.label}
              </summary>
              <Box key={subcat.label}>
                <Box>
                  {keys.map(subkey => {
                    const key = subkey.toLowerCase() as string;

                    return (
                      <Typography
                        key={key}
                        variant="body2"
                        sx={{
                          borderBottom: '1px solid rgb(207, 226, 240)',
                          pt: 0.5,
                          pb: 0.5,

                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          // width: '90%',
                          span: {
                            display: 'flex',
                            alignItems: 'center',
                            maxWidth: '75%',
                            padding: '0 8px',

                            svg: {
                              marginRight: 1,
                            },
                          },
                        }}
                      >
                        {/* @ts-ignore */}
                        <span>
                          {lowercaseKeys(
                            sectionKey
                              ? postKeyToLabel[sectionKey]
                              : commonDictionary
                          )[key] ?? key}
                        </span>
                        <span
                          style={{
                            ...(RANK_KEYS.includes(key)
                              ? {
                                  backgroundColor: `rgb(${getRankColor(
                                    categoryProps[key]
                                  )})`,
                                  borderRadius: 1,
                                  color: 'white',
                                }
                              : PERCENT_RANK_KEYS.includes(key)
                              ? {
                                  backgroundColor: `rgb(${getPercentColor(
                                    categoryProps[key]
                                  )})`,
                                  borderRadius: 1,
                                  color: 'white',
                                }
                              : {}),
                          }}
                        >
                          {[
                            'expires',
                            'effective',
                            'run_time',
                            'lastupdatedon',
                            'prod_date',
                          ].includes(key.toLowerCase())
                            ? // @ts-ignore
                              new Date(categoryProps?.[key]).toLocaleString()
                            : processValue(categoryProps?.[key])}
                        </span>
                      </Typography>
                    );
                  })}
                </Box>
              </Box>
            </details>
          );
        })}
      </Box>
    </Box>
  );
};

const processValue = (value: string | number | undefined) => {
  if (typeof value === 'undefined') {
    return '';
  }

  if (typeof value === 'number') {
    return Math.round(value).toLocaleString();
  }

  return value.toLocaleString();
};

const lowercaseKeys = (obj: { [key: string]: any }) => {
  if (obj == null) {
    return {};
  }
  return Object.keys(obj).reduce<{ [key: string]: any }>((acc, key) => {
    acc[key.toLowerCase()] = obj[key];
    return acc;
  }, {});
};
