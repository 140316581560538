import React from 'react';
import { GeoJsonLayer } from '@deck.gl/layers';
// @ts-ignore
import arcgisPbfDecode from 'arcgis-pbf-parser';
import { atom, useRecoilValue } from 'recoil';

export const viewTribalBoundariesAtom = atom<boolean>({
  key: 'tribalBoundaries',
  default: false,
});

export const useTribalLayer = () => {
  const viewTribalBoundaries = useRecoilValue(viewTribalBoundariesAtom);
  const [tribalData, setTribalData] = React.useState({
    features: [],
    type: 'FeatureCollection',
  });

  const handleFetch = async () => {
    const response = await fetch(
      'https://tigerweb.geo.census.gov/arcgis/rest/services/TIGERweb/AIANNHA/MapServer/47/query?where=1%3D1&returnGeometry=true&outFields=*&outSR=4326&f=pbf'
    );
    const buffer = await response.arrayBuffer();
    const data = arcgisPbfDecode(new Uint8Array(buffer));
    setTribalData(data.featureCollection);
  };

  React.useEffect(() => {
    handleFetch();
  }, []);

  const layer = new GeoJsonLayer({
    id: 'tribal-geojson-layer',
    data: tribalData,
    visible: viewTribalBoundaries,
    pickable: true,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 2,
    getLineColor: [255, 204, 102, 120],
    getFillColor: [255, 204, 102, 10],
    getLineWidth: 4,

    updateTriggers: {
      getFillColor: [tribalData, viewTribalBoundaries],
      getLineColor: [tribalData, viewTribalBoundaries],
      getLineWidth: [tribalData, viewTribalBoundaries],
    },
  });

  return layer;
};
