import { GeoJsonLayer, RGBAColor } from 'deck.gl';
import React from 'react';
import { WebMercatorViewport } from 'react-map-gl';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { visualModeAtom, debouncedViewStateAtom } from '../atoms';
import { Feature, FeatureCollection, Polygon } from '@turf/turf';
import { useMatch } from 'react-router-dom';
import { userAtom } from '../../auth/userAtoms';
import { Box, Typography, Paper } from '@mui/material';

type Building = {
  OBJECTID: number;
  BUILD_ID?: number | null;
  OCC_CLS?: string | null;
  PRIM_OCC?: string | null;
  SEC_OCC?: string | null;
  PROP_ADDR?: string | null;
  PROP_CITY?: string | null;
  PROP_ST?: string | null;
  PROP_ZIP?: string | null;
  OUTBLDG?: string | null;
  HEIGHT?: number | null;
  SQMETERS?: number | null;
  SQFEET?: number | null;
  H_ADJ_ELEV?: number | null;
  L_ADJ_ELEV?: number | null;
  FIPS?: string | null;
  CENSUSCODE?: string | null;
  PROD_DATE?: Date | null;
  SOURCE?: string | null;
  USNG?: string | null;
  LONGITUDE?: number | null;
  LATITUDE?: number | null;
  IMAGE_NAME?: string | null;
  IMAGE_DATE?: Date | null;
  VAL_METHOD?: string | null;
  REMARKS?: string | null;
  UUID: string;
  STATE_FIPS?: string | null;
  Shape__Area?: number | null;
  Shape__Length?: number | null;
};

export type StructureViewTypes =
  | 'occupancy_type'
  | 'exposure'
  | 'none'
  | 'highlight';

export const viewStructuresAtom = atom<{
  view: boolean;
  filter: StructureViewTypes;
}>({
  key: 'usaStructuresViewAtom',
  default: {
    view: false,
    filter: 'occupancy_type',
  },
});

const structuresAtom = atom<Record<string, Feature<Polygon, Building>>>({
  key: 'structuresAtom',
  default: {},
});

export const useCheckStructuresData = (incidentId: string | undefined) => {
  const [dataExists, setDataExists] = React.useState<boolean>(false);
  const [user] = useRecoilState(userAtom);
  const token = user?.token;

  React.useEffect(() => {
    const checkData = async () => {
      if (!incidentId || !token) return;

      const incidentWhereClause = `event_ID = '${incidentId}'`;
      const url = `https://services.arcgis.com/XG15cJAlne2vxtgt/arcgis/rest/services/POST_TEMPO_Exposed_Structure_Polygons/FeatureServer/0/query?where=${encodeURIComponent(
        incidentWhereClause
      )}&returnCountOnly=true&f=json&token=${token}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        setDataExists(data.count > 0);
      } catch (error) {
        console.error('Error checking data:', error);
        setDataExists(false);
      }
    };

    checkData();
  }, [incidentId, token]);

  return dataExists;
};

export const useStructuresLayer = () => {
  const [visualMode] = useRecoilState(visualModeAtom);
  const viewStructures = useRecoilValue(viewStructuresAtom);

  const [structures, setStructures] = useRecoilState(structuresAtom);
  const [debouncedViewState] = useRecoilState(debouncedViewStateAtom);
  const match = useMatch({
    path: '/:incidentId/:sectionId/:viewType/:attributeId?',
  });
  const [user] = useRecoilState(userAtom);
  const [exposedStructuresIds, setExposedStructuresIds] = React.useState<
    string[]
  >([]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchStructures = async () => {
      try {
        const viewport = new WebMercatorViewport({
          ...debouncedViewState,
          width:
            debouncedViewState.width ?? document.documentElement.clientWidth,
          height:
            debouncedViewState.height ?? document.documentElement.clientHeight,
        });

        const boundingBox = viewport.getBounds();
        const geometry = `${boundingBox[0][0]},${boundingBox[0][1]},${boundingBox[1][0]},${boundingBox[1][1]}`;
        const whereClause = `1=1`;
        const incidentWhereClause = `event_ID = '${match?.params.incidentId}'`;

        let exposureUrl = `https://services.arcgis.com/XG15cJAlne2vxtgt/arcgis/rest/services/POST_TEMPO_Structure_Exposure/FeatureServer/0/query?where=${encodeURIComponent(
          incidentWhereClause
        )}&geometry=${geometry}&geometryType=esriGeometryEnvelope&spatialRel=esriSpatialRelIntersects&outFields=*&returnGeometry=true&outSR=4326&f=geojson&token=${
          user?.token
        }`;

        if (user?.token && viewStructures.filter === 'exposure') {
          const exposedStructuresResponse = await fetch(exposureUrl, {
            signal,
          });
          const exposedStructures = await exposedStructuresResponse.json();
          const exposedStructureIds = exposedStructures.features.map(
            (f: Feature) => f?.properties?.UUID
          );
          setExposedStructuresIds(exposedStructureIds);
        }

        let url = `https://services2.arcgis.com/FiaPA4ga0iQKduv3/arcgis/rest/services/USA_Structures_View/FeatureServer/0/query?where=${whereClause}&geometry=${geometry}&geometryType=esriGeometryEnvelope&spatialRel=esriSpatialRelIntersects&outFields=*&returnGeometry=true&outSR=4326&f=geojson`;

        const structuresResponse = await fetch(url, { signal });
        const geoJson: FeatureCollection<Polygon, Building> & {
          properties: { exceededTransferLimit: boolean };
        } = await structuresResponse.json();

        setStructures(currentStructures => {
          const updatedStructures = { ...currentStructures };
          geoJson.features.forEach(feature => {
            const uuid = feature.properties.UUID;
            if (uuid) {
              updatedStructures[uuid] = updatedStructures[uuid]
                ? {
                    ...updatedStructures[uuid],
                    properties: {
                      ...updatedStructures[uuid].properties,
                      ...feature.properties,
                    },
                  }
                : feature;
            }
          });
          return updatedStructures;
        });
      } catch (error: any) {
        if (error.name !== 'AbortError') {
          console.error('Fetch error:', error);
        }
      }
    };

    if (viewStructures.view) {
      fetchStructures();
    }

    return () => {
      abortController.abort();
    };
  }, [
    debouncedViewState,
    viewStructures,
    match?.params.incidentId,
    user?.token,
    setStructures,
  ]);

  const featureIds = Array.from(Object.keys(structures));

  const featureChunks = React.useMemo(() => {
    const chunks = [];
    const chunkSize = 2000;

    for (let i = 0; i < featureIds.length; i += chunkSize) {
      chunks.push(featureIds.slice(i, i + chunkSize));
    }

    return chunks;
  }, [featureIds]);

  const handleFillColor = React.useCallback(
    (f: any): RGBAColor => {
      if (viewStructures.filter === 'highlight') {
        // @ts-ignore
        if (exposedStructuresIds.includes(f.properties.UUID)) {
          return [237, 28, 36, 140]; // Bright red with full opacity
        }
        return [158, 158, 158, 140]; // Medium gray with full opacity
      }

      // @ts-ignore
      switch (f.properties.OCC_CLS) {
        case 'Commercial':
          return [255, 159, 158, 140];
        case 'Industrial':
          return [163, 163, 163, 140];
        case 'Residential':
          return [255, 229, 110, 140];
        case 'Education':
          return [254, 173, 86, 140];
        case 'Government':
          return [79, 183, 218, 140];
        case 'Other':
          return [200, 160, 120, 140];
        default:
          return [255, 255, 255, 140];
      }
    },
    [viewStructures.filter, exposedStructuresIds]
  );

  const handleLineColor = React.useCallback(
    (f: any): RGBAColor => {
      if (viewStructures.filter === 'highlight') {
        // @ts-ignore
        if (exposedStructuresIds.includes(f.properties.UUID)) {
          return [237, 28, 36, 255]; // Bright red with full opacity
        }
        return [158, 158, 158, 255]; // Medium gray with full opacity
      }

      // @ts-ignore
      switch (f.properties.OCC_CLS) {
        case 'Commercial':
          return [255, 159, 158, 255];
        case 'Industrial':
          return [163, 163, 163, 255];
        case 'Residential':
          return [255, 229, 110, 255];
        case 'Education':
          return [254, 173, 86, 255];
        case 'Government':
          return [79, 183, 218, 255];
        case 'Other':
          return [200, 160, 120, 255];
        default:
          return [255, 255, 255, 255];
      }
    },
    [viewStructures.filter, exposedStructuresIds]
  );

  const layer = React.useMemo(
    () =>
      featureChunks.map((chunk, i) => {
        let features = chunk.map(id => structures[id]);

        if (viewStructures.filter === 'exposure') {
          features = features.filter(f =>
            exposedStructuresIds.includes(f.properties.UUID)
          );
        }

        const layer = new GeoJsonLayer({
          id: `usa-structures-layer-${i}`,
          data: features,
          pickable: true,
          stroked: true,
          filled: true,
          // lineWidthScale: 1,
          lineWidthMinPixels: 2,
          getLineColor: handleLineColor,
          getFillColor: handleFillColor,
          extruded: visualMode === '3d',
          visible: viewStructures.view,
          getElevation: f => {
            // @ts-ignore
            return f.properties.HEIGHT ?? 20;
          },
          updateTriggers: {
            getElevation: [visualMode],
          },
        });

        return layer;
      }),
    [
      featureChunks,
      structures,
      viewStructures,
      visualMode,
      exposedStructuresIds,
      handleFillColor,
      handleLineColor,
    ]
  );

  return layer;
};

const occupancyTypeColors = {
  Commercial: [255, 159, 158],
  Industrial: [163, 163, 163],
  Residential: [255, 229, 110],
  Education: [254, 173, 86],
  Government: [79, 183, 218],
  Other: [200, 160, 120],
};

export const StructuresLegend = React.memo(() => {
  const generateOccupancyTypeLegendItems = () => {
    return Object.entries(occupancyTypeColors).map(([type, color]) => ({
      label: type,
      color: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
    }));
  };

  const occupancyTypeLegendItems = generateOccupancyTypeLegendItems();

  return (
    <Box sx={{ position: 'absolute', bottom: 54 }}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Occupancy Type
        </Typography>
        <Box>
          {occupancyTypeLegendItems.map(item => (
            <Box
              key={item.label}
              sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: '50%',
                  mr: 1,
                  backgroundColor: item.color,
                }}
              />
              <Typography variant="body2">{item.label}</Typography>
            </Box>
          ))}
        </Box>
      </Paper>
    </Box>
  );
});
