/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React from 'react';
import _useMapControl from 'react-map-gl/dist/esm/components/use-map-control';
import {
  Paper,
  Box,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Slider,
  Tooltip,
} from '@mui/material';
import { primaryKeyForSection } from '../Post/PostLayer';
import {
  CenterFocusStrong,
  CloseFullscreen,
  OpenInFull,
  Settings,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import {
  selectedElevationKeyAtom,
  selectedElevationScaleAtom,
  layerVisibility,
} from '../map/atoms';
import countyDict from '../Incident/Incident_page_dictionaries/County_dict.json';
import communicationsDict from '../Incident/Incident_page_dictionaries/Communication_dict.json';
import commoditiesDict from '../Incident/Incident_page_dictionaries/Commodities_dict.json';
import energyDict from '../Incident/Incident_page_dictionaries/Energy_dict.json';
import foodWaterDict from '../Incident/Incident_page_dictionaries/FoodWaterShelter_dict.json';
import healthDict from '../Incident/Incident_page_dictionaries/HealthAndMedical_dict.json';
import nonResidentialDict from '../Incident/Incident_page_dictionaries/NonResidentialStructures_dict.json';
import populationExposureDict from '../Incident/Incident_page_dictionaries/PopulationExposure_dict.json';
import residentialStructuresDict from '../Incident/Incident_page_dictionaries/ResidentialStructures_dict.json';
import transportationDict from '../Incident/Incident_page_dictionaries/Transportation_dict.json';
import grantsDict from '../Incident/Incident_page_dictionaries/Grants_dict.json';
import wowDict from '../Incident/Incident_page_dictionaries/Wow_dict.json';
import { useMatch } from 'react-router-dom';
import { IncidentDateSelect } from '../map/IncidentDateSelect';
import { IncidentState } from '../Incident/types';
import { selectedRunTimeAtom, extentStateAtom } from '../Incident/atoms';

export const keyToLabel: any = {
  county: countyDict,
  communications: communicationsDict,
  commodities: commoditiesDict,
  energy: energyDict,
  foodWaterShelter: foodWaterDict,
  healthMedical: healthDict,
  transportation: transportationDict,
  residential: residentialStructuresDict,
  population: populationExposureDict,
  nonResidentialStructures: nonResidentialDict,
  declarations: grantsDict,
  'wow-perimeter': wowDict,
} as const;

export type LegendProps = {
  id: string;
  title?: string;
  items: { label: string; color: string; value?: string | number }[];
  initialSize?: 'fullscreen' | 'minimized';
  primaryProperties?: { key: string; label: string }[];
  elevationProperties?: { key: string; label: string }[];
  disableZoomToExtent?: boolean;
};

const iconProps = { width: '0.8em', height: '0.8em' };

export const Legend = React.memo((props: LegendProps) => {
  const isIncidentPage = useMatch(
    '/:incidentId/:sectionId/:viewType/:attributeId?'
  );

  const isIncidentNrccPage = useMatch('/wowcast/:incidentId/:sectionId');

  const incidentId =
    isIncidentNrccPage?.params.incidentId ?? isIncidentPage?.params.incidentId;
  const sectionId = isIncidentPage?.params.sectionId ?? 'population';

  const selectedRuntime = useRecoilValue(
    selectedRunTimeAtom(`${incidentId}-${props.id ?? sectionId}`)
  );

  const [currentView, setCurrentView] = React.useState<'legend' | 'settings'>(
    'legend'
  );
  const [primaryKey, setPrimaryKey] = useRecoilState(
    primaryKeyForSection(props.id)
  );

  const [visible, setLayerVisibliity] = useRecoilState(
    layerVisibility(props.id)
  );

  const [selectedElevationKey, setSelectedElevationKey] = useRecoilState(
    selectedElevationKeyAtom
  );

  const [elevationScale, setSelectedElevationScale] = useRecoilState(
    selectedElevationScaleAtom
  );

  const [size, setSize] = React.useState<'fullscreen' | 'minimized'>(
    props?.initialSize ?? 'fullscreen'
  );

  const { containerRef } = _useMapControl({
    captureScroll: true,
    captureDrag: false,
    captureClick: true,
    captureDoubleClick: false,
    capturePointerMove: false,
  });

  const { containerRef: elevationButtonRef } = _useMapControl({
    captureScroll: true,
    captureDrag: false,
    captureClick: true,
    captureDoubleClick: false,
    capturePointerMove: false,
  });

  const handlePrimaryKeyChange = (event: any) => {
    setPrimaryKey(event.target.value);
  };

  const handleChange = (event: any) => {
    setSelectedElevationKey(event.target.value);
  };

  const setExtentState = useSetRecoilState(extentStateAtom);

  const handleZoomToExtent = React.useCallback(() => {
    setExtentState('zoom-to-extent');
  }, [setExtentState]);

  return (
    <Paper
      elevation={0}
      sx={{
        overflow: 'hidden',
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          minWidth: 228,
          maxWidth: 250,
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {size === 'fullscreen' && currentView === 'legend' && (
          <Box sx={{ p: 1, overflowY: 'auto', maxHeight: 220 }}>
            {props.items.map((item, index) => (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: 12,
                  marginBottom: 0.75,
                }}
                key={index}
              >
                <Box
                  sx={{
                    background: item.color,
                    height: 14,
                    width: 14,
                    maxHeight: 14,
                    maxWidth: 14,
                    minHeight: 14,
                    minWidth: 14,
                    borderRadius: '2px',
                    marginRight: 1,
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <span>{item.label}</span>
                  {item.value && <b>{item.value}</b>}
                </Box>
              </Box>
            ))}
          </Box>
        )}

        {incidentId && (
          <IncidentDateSelect
            hidden={size === 'minimized' || currentView !== 'settings'}
            incidentId={incidentId}
            sectionId={
              (props.id ?? sectionId ?? 'population') as keyof IncidentState
            }
          />
        )}
        {size === 'fullscreen' &&
          props.elevationProperties != null &&
          currentView === 'settings' && (
            <Box sx={{ p: 1, overflowY: 'auto', maxHeight: 320 }}>
              {props.primaryProperties != null && (
                <>
                  <FormControl sx={{ width: '100%', p: 1 }}>
                    {' '}
                    <InputLabel
                      id="primary-attribute-label"
                      sx={{ p: 1, backgroundColor: '#fff' }}
                    >
                      Primary Attribute
                    </InputLabel>
                    <Select
                      labelId="Primary By"
                      id="primary-select"
                      value={primaryKey}
                      label="Primary Attribute"
                      onChange={handlePrimaryKeyChange}
                      sx={{ width: '100%', fontSize: '0.8em' }}
                      size="small"
                    >
                      {props.primaryProperties.map(
                        (elevationProperty, index) => (
                          <MenuItem
                            key={index}
                            dense
                            value={elevationProperty.key}
                          >
                            {elevationProperty.label}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </>
              )}
              <FormControl sx={{ mt: 1, width: '100%', p: 1 }}>
                <InputLabel
                  id="elevation-attribute-label"
                  sx={{ p: 1, backgroundColor: '#fff' }}
                >
                  Elevation Attribute
                </InputLabel>
                <Select
                  labelId="Elevation By"
                  id="elevation-select"
                  value={selectedElevationKey}
                  label="Elevation Attribute"
                  onChange={handleChange}
                  sx={{ width: '100%', fontSize: '0.8em' }}
                  ref={elevationButtonRef}
                  size="small"
                >
                  {props.elevationProperties.map((elevationProperty, index) => (
                    <MenuItem key={index} dense value={elevationProperty.key}>
                      {elevationProperty.label}
                    </MenuItem>
                  ))}
                </Select>
                <Typography sx={{ mt: 1 }} variant="caption" gutterBottom>
                  Elevation Scale
                </Typography>
                <Slider
                  size="small"
                  defaultValue={elevationScale}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  onChange={(event, value) =>
                    !Array.isArray(value) && setSelectedElevationScale(value)
                  }
                />
              </FormControl>
            </Box>
          )}

        <Box
          sx={{
            backgroundColor: 'rgb(245, 250, 252)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 1,
              backgroundColor: 'rgb(245, 250, 252)',
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                display: 'block',
                lineHeight: 1,
                whiteSpace: 'pre-line',
              }}
              variant="overline"
            >
              {`${
                keyToLabel?.[props.id]?.[primaryKey] ?? props?.title ?? 'Legend'
              }\n\n`}
              {selectedRuntime?.comments != null &&
                selectedRuntime?.comments !==
                  new Date(selectedRuntime?.time).toLocaleString() &&
                `Model:\n${selectedRuntime?.comments ?? ''}\n\n`}
              {selectedRuntime?.time != null &&
                `Updated On\n${new Date(
                  selectedRuntime?.time
                ).toLocaleString()}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: 'rgb(245, 250, 252)',
            overflow: 'hidden',
            '@media print': {
              display: 'none',
            },
          }}
          data-print="hidden"
        >
          {!props.disableZoomToExtent && (
            <Tooltip title="Zoom to extent">
              <IconButton size="small" onClick={handleZoomToExtent}>
                <CenterFocusStrong sx={iconProps} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Toggle visibility">
            <IconButton
              size="small"
              onClick={() => setLayerVisibliity(visible => !visible)}
            >
              {visible ? (
                <Visibility sx={iconProps} />
              ) : (
                <VisibilityOff sx={iconProps} />
              )}
            </IconButton>
          </Tooltip>
          {props.elevationProperties != null && (
            <Tooltip title="Toggle legend settings">
              <IconButton
                size="small"
                onClick={() => {
                  setSize('fullscreen');
                  setCurrentView(
                    currentView === 'legend' ? 'settings' : 'legend'
                  );
                }}
                aria-label={
                  currentView === 'legend' ? 'Legend settings' : 'View legend'
                }
              >
                {currentView === 'legend' ? (
                  <Settings sx={iconProps} />
                ) : (
                  <LegendToggleIcon sx={iconProps} />
                )}
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Toggle legend size">
            <IconButton
              size="small"
              onClick={() => {
                setSize(size === 'fullscreen' ? 'minimized' : 'fullscreen');
              }}
              aria-label="Toggle legend size"
            >
              {size === 'fullscreen' ? (
                <CloseFullscreen sx={iconProps} />
              ) : (
                <OpenInFull sx={iconProps} />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Paper>
  );
});
