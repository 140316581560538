/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import * as React from 'react';
import { atom, useRecoilValue } from 'recoil';
import { Communication } from './types';
import { incidentChunksSelector } from './atoms';
import { Legend } from '../components/Legend';
import { RGBAColor } from '@deck.gl/core/utils/color';
import { getPercentColor, primaryKeyForSection } from '../Post/PostLayer';
import { useGridCellLayer } from '../map/GridCellLayer';
import communicationsDict from './Incident_page_dictionaries/Communication_dict.json';
import { selectedElevationKeyAtom } from '../map/atoms';

type Props = {
  extruded: boolean;
  visible: boolean;
  incidentId: string;
};

export const selectedCommunicationAtom = atom<
  Communication['features'][number] | null
>({
  key: 'selectedCommunication',
  default: null,
});

export const useCommunicationsLayer = (props: Props) => {
  const allChunks = useRecoilValue(
    incidentChunksSelector({
      incidentId: props.incidentId,
      sectionId: 'communications',
      visible: props.visible,
    })
  );
  const communicationsData = React.useMemo(
    () => allChunks.flatMap(a => a.data),
    [allChunks]
  );

  const selectedElevationKey = useRecoilValue<
    keyof Communication['features'][number]['properties']
  >(selectedElevationKeyAtom);
  const selectedPrimaryKey = useRecoilValue(
    primaryKeyForSection('communications')
  );

  const GridCellLayer = useGridCellLayer<Communication['features'][number]>({
    id: 'communications',
    incidentId: props.incidentId,
    // @ts-ignore
    data: communicationsData,
    extruded: props.extruded,
    visible: props.visible,
    getFillColor:
      selectedPrimaryKey === 'percentout' ? getFillColor : undefined,
    getElevation: (d): number => {
      const value = d.properties[selectedElevationKey];
      if (typeof value === 'number') {
        return value;
      }
      return 0;
    },
  });

  return GridCellLayer;
};

const colorRange: RGBAColor[] = [
  [181, 9, 9, 190], // #B50909
  [219, 129, 0, 190], // #DB8100
  [226, 178, 0, 190], // #E2B200
  [143, 195, 201, 190], // #8FC3C9
  [95, 133, 188, 190], // #5F85BC
];

const getFillColor = (d: Communication['features'][number]): RGBAColor => {
  const { percentout } = d.properties;
  return getPercentColor(percentout);
};

const primaryKeys = [
  'cell_sites_out_due_to_damage',
  'cell_sites_out_due_to_power',
  'cell_sites_out_due_to_transpor',
  'cell_sites_out',
  'cell_sites_served',
  'percentout',
  'population',
  'populationaffected',
  'sqmi',
] as const;

export const keyToLabel = {
  population: 'Population',
  sqmi: 'SQMI',
  ...communicationsDict,
} as const;

const communicationItems = [
  { label: '60% - 100%', color: `rgb(${colorRange[0]})` },
  { label: '45% - 60%', color: `rgb(${colorRange[1]})` },
  { label: '30% - 45%', color: `rgb(${colorRange[2]})` },
  { label: '15% - 30%', color: `rgb(${colorRange[3]})` },
  { label: '0% - 15%', color: `rgb(${colorRange[4]})` },
];

const primaryProperties = primaryKeys.map(key => ({
  label: keyToLabel?.[key] ?? key,
  key,
}));

export const CommunicationsLegend = () => {
  return (
    <Legend
      id="communications"
      title="Cell Sites Out"
      items={communicationItems}
      primaryProperties={[...primaryProperties]}
      elevationProperties={primaryProperties}
    />
  );
};
