/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import 'maplibre-gl/dist/maplibre-gl.css';
import {
  MapRef,
  NavigationControl,
  StaticMap,
  _MapContext as MapContext,
} from 'react-map-gl';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Box, CircularProgress, Paper } from '@mui/material';
import DeckGL from '@deck.gl/react';
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { mapDrawerAtom, mapDrawerWidthAtom } from '../components/MapDrawer';
import {
  ContextProviderValue,
  InitialViewStateProps,
  ViewStateProps,
} from '@deck.gl/core/lib/deck';
import { FeatureCollection } from '@turf/turf';
import { MapView, FlyToInterpolator } from '@deck.gl/core';
import { useRegionLayer } from '../Regions/FemaRegions';
import { useHomeLayers } from '../pages/Home';
import { useIncidentLayers } from '../Incident/incidentHooks';
import { PrimaryLayer } from '../pages/MapPage';
import { useCountyLayer } from '../Incident/CountyLayer';
import { debouncedViewStateAtom, viewStateAtom, visualModeAtom } from './atoms';
import { mapStyleAtom } from './MapStylePicker';
import { useDebounce } from 'use-debounce';
import { useLocation, useMatch, useSearchParams } from 'react-router-dom';
import { WebMercatorViewport } from '@deck.gl/core';
import LayerInfoPopup, { ClickInfo } from './layers/LayerInfoPopup';
import { PickInfo } from 'deck.gl';
import { selectedFeatureAtom } from '../Incident/FeaturePopup';
import { useStructuresLayer } from './layers/StructuresLayer';
import { useTribalLayer } from './layers/TribalLayer';
import { ToggleLabelsButton } from './ToggleLabelsButton';
import { DownloadButton } from './DownloadButton';
import { DimensionButton } from './DimensionButton';

type Props = {
  layers?: any[];
  featureCollection?: FeatureCollection;
  outerChildren?: React.ReactElement;
  children?: React.ReactNode;
  selectedMap: PrimaryLayer;
  title: string;
  miniMap?: boolean;
  hideDrawer?: boolean;
  context?: string;
};

const esriToken =
  process.env.REACT_APP_ARCGIS_API_KEY ??
  'AAPK980bae57996e40f59d613b3dd4b2d2f36xS38_jutL1jL-P9_HmTCMm2NKt1HLf-vlb3ZzjuJ3F11GxrAGnsRg1_3actRoNh';

export const initialViewState: InitialViewStateProps = {
  altitude: 1.5,
  bearing: 0,
  latitude: 39.446888593720935,
  longitude: -95.65494071225665,
  maxZoom: 18,
  minPitch: 0,
  maxPitch: 85,
  minZoom: 0,
  pitch: 0,
  zoom: 3.4944034428445687,
};

const Map: React.FC<Props> = props => {
  const [viewState, setViewState] = useRecoilState(viewStateAtom);
  const [openDrawer] = useRecoilState(mapDrawerAtom);
  const [mapDrawerWidth] = useRecoilState(mapDrawerWidthAtom);
  // const [mapStyle] = React.useState<string | null>('World_Imagery');
  const [mapStyle] = useRecoilState(mapStyleAtom);
  const [visualMode, setVisualMode] = useRecoilState(visualModeAtom);
  const [debounceViewState] = useDebounce(viewState, 1000);
  const setDebouncedViewState = useSetRecoilState(debouncedViewStateAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const [firstLoad, setFirstLoad] = React.useState(true);
  const incidentPage = useMatch({
    path: '/:incidentId/:sectionId/:viewType/:attributeId?',
  });

  const incidentcastPage = useMatch({
    path: '/wowcast/:incidentId/:sectionId',
  });

  const isIncidentPage = incidentPage != null || incidentcastPage != null;

  const bboxSearchParams = searchParams.get('bbox');
  const hasBbox = searchParams.has('bbox');
  const isCanopyUrl = searchParams.has('canopy_kml_id');

  const bboxViewState = React.useMemo(() => {
    if (bboxSearchParams != null && bboxSearchParams.length > 0) {
      const viewport = new WebMercatorViewport({
        width: debounceViewState.width ?? document.documentElement.clientWidth,
        height:
          debounceViewState.height ?? document.documentElement.clientHeight,
        initialViewState,
      });
      const [minLng, minLat, maxLng, maxLat] = bboxSearchParams
        .split(',')
        .map(Number);

      const viewstate = viewport.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: 20,
        }
      );

      return viewstate;
    }
  }, [bboxSearchParams, debounceViewState]);

  const handleInitialBounds = React.useCallback(() => {
    if (bboxViewState != null && firstLoad) {
      setViewState({
        width: bboxViewState.width,
        height: bboxViewState.height,
        // @ts-expect-error
        latitude: bboxViewState.latitude,
        // @ts-expect-error
        longitude: bboxViewState.longitude,
        zoom: bboxViewState.zoom,
        // @ts-expect-error
        bearing: bboxViewState.bearing,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
      });
      setFirstLoad(false);
    }
  }, [bboxViewState, setViewState, setFirstLoad, firstLoad]);

  React.useEffect(() => {
    if (hasBbox) {
      handleInitialBounds();
    }
  }, [hasBbox, handleInitialBounds, isIncidentPage]);

  // React.useEffect(() => {
  //   if (bboxSearchParams != null && bboxSearchParams.length > 0 && firstLoad) {
  //     setFirstLoad(false);

  //     setViewState({
  //       width: viewState.width,
  //       height: viewState.height,
  //       // @ts-expect-error
  //       latitude: viewstate.latitude,
  //       // @ts-expect-error
  //       longitude: viewstate.longitude,
  //       zoom: viewstate.zoom,
  //       // @ts-expect-error
  //       bearing: viewstate.bearing,
  //       transitionDuration: 1000,
  //       transitionInterpolator: new FlyToInterpolator(),
  //     });
  //   }
  // }, [bboxSearchParams, setViewState, viewState, firstLoad, isCanopyUrl]);
  const location = useLocation();
  const isInternal = location.state?.internal ?? false;

  const handleBbox = React.useCallback(() => {
    const viewport = new WebMercatorViewport({
      width: debounceViewState.width ?? document.documentElement.clientWidth,
      height: debounceViewState.height ?? document.documentElement.clientHeight,
      ...debounceViewState,
    });
    // @ts-ignore
    const bbox = viewport.getBounds() as number[];

    if (isCanopyUrl) {
      searchParams.set(
        'canopy_kml_id',
        searchParams.get('canopy_kml_id') ?? ''
      );
    }
    searchParams.set('bbox', encodeURI(bbox.map(n => n.toFixed(3)).join(',')));
    setSearchParams(searchParams, {
      replace: true,
      state: {
        internal: isInternal,
      },
    });
  }, [
    debounceViewState,
    setSearchParams,
    searchParams,
    isCanopyUrl,
    isInternal,
  ]);

  React.useEffect(() => {
    if (
      firstLoad &&
      bboxSearchParams == null &&
      !isIncidentPage &&
      !isCanopyUrl
    ) {
      setFirstLoad(false);
    }

    if (!firstLoad) {
      setDebouncedViewState(debounceViewState);
      handleBbox();
    }
  }, [
    isIncidentPage,
    bboxSearchParams,
    debounceViewState,
    setDebouncedViewState,
    setSearchParams,
    isCanopyUrl,
    handleBbox,
    firstLoad,
    setFirstLoad,
  ]);

  // const debouncedBbox = React.useMemo(() => {
  //   const vp = new WebMercatorViewport(debounceViewState);
  //   const nw = vp.unproject([0, 0]);
  //   const se = vp.unproject([vp.width, vp.height]);
  //   const bbox = [nw[0], se[1], se[0], nw[1]] as [
  //     number,
  //     number,
  //     number,
  //     number
  //   ];
  //   return bbox;
  // }, [debounceViewState]);

  // React.useEffect(() => {
  //   if (isIncidentPage) {
  //     setFirstLoad(true);
  //   }
  // }, [isIncidentPage, setFirstLoad]);

  // const tornado = useWMTSLayer();

  // const nwsLayer = NWSRadarLayer();
  // const planetTiles = usePlanetTiles();
  // const planetLayer = PlanetMosaicLayer({
  //   bbox: debouncedBbox,
  //   visible: true,
  //   width: debounceViewState?.width ?? 0,
  //   height: debounceViewState?.height ?? 0,
  // });

  // const nwsLayer = NWSRadarLayer();

  const handleViewStateChange = React.useCallback(
    ({ viewState }: { viewState: ViewStateProps }) => {
      setViewState({ ...viewState, maxPitch: 85 });
    },
    [setViewState]
  );

  const deckRef = React.useRef<DeckGL>();
  const mapRef = React.useRef<MapRef | null>(null);

  const hasPitch = (viewState?.pitch ?? 0) > 20;

  const handleDragEnd = React.useCallback(() => {
    if (hasPitch && visualMode === '2d') {
      setVisualMode('3d');
    } else if (!hasPitch && visualMode === '3d') {
      setVisualMode('2d');
    }
  }, [hasPitch, setVisualMode, visualMode]);

  const containerStyles = React.useMemo(() => {
    if (props.hideDrawer) {
      return {
        height: 'calc(100% - 64px)',
        '@media print': {
          height: '100%',
        },
      };
    }

    return {
      marginLeft: openDrawer ? `${mapDrawerWidth}px` : 0,
      transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      height: 'calc(100% - 64px)',

      '@media print': {
        marginLeft: 0,
      },
    };
  }, [openDrawer, mapDrawerWidth, props.hideDrawer]);

  const styleUrl = `https://basemaps-api.arcgis.com/arcgis/rest/services/styles/${mapStyle}?type=style&token=${esriToken}`;

  const zoomLevel = React.useMemo(
    () => ((debounceViewState?.zoom ?? 0) > 8 ? 'inner' : 'outer'),
    [debounceViewState?.zoom]
  );

  const [clickInfo, setClickInfo] = React.useState<ClickInfo | null>(null);
  const [activeObjectIndex, setActiveObjectIndex] = React.useState<number>(0);
  const [, setSelectedFeature] = useRecoilState(selectedFeatureAtom);

  function onClick(event: PickInfo<any>, info: any) {
    const { x, y, coordinate } = event;

    if (deckRef.current) {
      const pickedObjects = deckRef.current.pickMultipleObjects({
        x,
        y,
        radius: 1,
      });

      if (pickedObjects.length > 0) {
        setClickInfo({
          x,
          y,
          coordinate,
          // @ts-ignore
          objects: pickedObjects.map(info => ({
            layer: info.layer,
            object: info.object,
          })),
        });
        setActiveObjectIndex(0);
        setSelectedFeature(pickedObjects[0].object);
      } else {
        setClickInfo(null);
      }
    }
  }

  function handlePrevClick() {
    setActiveObjectIndex(prevIndex => {
      const newIndex = Math.max(prevIndex - 1, 0);
      setSelectedFeature(clickInfo?.objects[newIndex].object);
      return newIndex;
    });
  }

  function handleNextClick() {
    setActiveObjectIndex(prevIndex => {
      const newIndex = Math.min(
        prevIndex + 1,
        (clickInfo?.objects.length ?? 0) - 1
      );
      setSelectedFeature(clickInfo?.objects[newIndex].object);
      return newIndex;
    });
  }

  return (
    // @ts-ignore
    <Box display="flex" className="full-width-height" sx={containerStyles}>
      <Box
        id="map-container"
        className={['map-data-container', props.miniMap ? 'mini-map' : ''].join(
          ' '
        )}
        sx={
          props.context === 'wowcast'
            ? {
                height: 'calc(100vh - 228px) !important',
              }
            : {
                height: 'calc(100vh - 134px) !important',
              }
        }
        display="flex"
        width={1}
        height={1}
      >
        <HandleLayers
          context={props.context}
          deckRef={deckRef}
          selectedMap={props.selectedMap}
          mapStyle={mapStyle}
          zoomLevel={zoomLevel}
        >
          <LoadingIndicator status="init" />
          <DeckGL
            /* @ts-ignore */
            ref={deckRef}
            reuseMaps
            controller={true}
            // @ts-ignore
            ContextProvider={MapContext.Provider}
            layers={[
              // planetTiles,
              // planetLayer,
              // tornado,
              ...(props?.layers ?? []),
            ]}
            glOptions={{
              preserveDrawingBuffer: true,
            }}
            onViewStateChange={handleViewStateChange}
            onDragEnd={handleDragEnd}
            views={[new MapView({ repeat: true })]}
            viewState={viewState}
            onClick={onClick}
            onLoad={() => {
              const clouds = document.getElementById('clouds');
              if (clouds) {
                clouds.style.visibility = 'visible';
              }
            }}
          >
            <StaticMap
              {...viewState}
              ref={mapRef}
              mapStyle={styleUrl}
              reuseMaps
              preserveDrawingBuffer
              attributionControl={true}
              mapOptions={{
                antialias: true,
                customAttribution: 'NLT',
                preserveDrawingBuffer: true,
                attributionControl: true,
                maxPitch: 85,
              }}
            />
            {props.context !== 'wowcast' && (
              <MapButtons
                miniMap={props.miniMap}
                context={props.context}
                deckRef={deckRef}
                title={props.title}
              />
            )}
            {props.children}

            <LayerInfoPopup
              clickInfo={clickInfo}
              activeObjectIndex={activeObjectIndex}
              handlePrevClick={handlePrevClick}
              handleNextClick={handleNextClick}
              onClose={() => setClickInfo(null)}
            />
          </DeckGL>
        </HandleLayers>

        {props.outerChildren}
      </Box>
    </Box>
  );
};

export default Map;

const LoadingIndicator = (props: { status: 'loading' | 'init' | 'loaded' }) => {
  if (props.status !== 'loading') {
    return null;
  }

  return (
    <CircularProgress
      sx={{
        position: 'absolute',
        left: 'calc(50% - 20px)',
        top: 'calc(50% - 20px)',
        zIndex: 3,
      }}
      color="primary"
    />
  );
};

type MapButtonsProps = {
  miniMap?: boolean;
  context?: string;
  title: string;
  deckRef?: React.MutableRefObject<DeckGL<ContextProviderValue> | undefined>;
};

const MapButtons = React.memo((props: MapButtonsProps) => {
  const incidentPage = useMatch({
    path: '/:incidentId/:sectionId/:viewType/:attributeId?',
  });

  const incidentcastPage = useMatch({
    path: '/wowcast/:incidentId/:sectionId',
  });

  const match = {
    params: {
      incidentId:
        incidentPage?.params.incidentId ?? incidentcastPage?.params.incidentId,
      sectionId: incidentPage?.params.sectionId ?? 'population',
      viewType: incidentPage?.params.viewType ?? 'map',
    },
  };

  return (
    <Box
      data-print="hidden"
      sx={{
        width: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        // alignItems: props.miniMap ? 'flex-end' : 'flex-start',
        flexDirection:
          match?.params.viewType === 'detail' ? 'column' : 'column',
        position: 'absolute',
        top: 8,
        left: 8,
        // left: !props.miniMap ? 8 : 'auto',
        // right: props.miniMap ? 8 : 'auto',

        '> *': {
          margin: '0 0.5rem 0.5rem 0',
        },

        '@media (max-height: 800px)': {
          flexDirection: 'row',

          '> *': {
            margin: '0 0.5rem 0 0',
          },
        },
      }}
    >
      <Paper elevation={0} sx={{ marginBottom: 1 }}>
        <NavigationControl
          className="navigation-control"
          captureScroll={true}
          captureDrag={false}
          captureClick={true}
          captureDoubleClick={false}
          capturePointerMove={false}
        />
      </Paper>

      <DimensionButton />
      <DownloadButton title={props.title} />
      <ToggleLabelsButton />
    </Box>
  );
});

type HandleLayersProps = {
  children?: React.ReactNode;
  mapStyle: string | null;
  selectedMap: PrimaryLayer;
  mapRef?: React.MutableRefObject<MapRef | undefined>;
  deckRef?: React.MutableRefObject<DeckGL<ContextProviderValue> | undefined>;
  context?: string;
  zoomLevel: 'inner' | 'outer';
};

type ChildProps = {
  layers: any[];
  status: 'loading' | 'init' | 'loaded';
};

const HandleLayers = React.memo((props: HandleLayersProps) => {
  const countyLayer = useCountyLayer({
    autoHighlight: props.zoomLevel === 'outer',
  });

  const regionLayer = useRegionLayer({
    mode: props.context === 'wowcast' ? 'all' : 'select',
  });

  const homeLayers = useHomeLayers({
    selectedMap: props.selectedMap,
  });
  const { layers: incidentLayers, status } = useIncidentLayers();
  const structuresLayer = useStructuresLayer();
  const tribalLayer = useTribalLayer();

  const layers = React.useMemo(() => {
    return [
      countyLayer[0],
      regionLayer,
      ...homeLayers,
      ...incidentLayers,
      countyLayer[1],
      structuresLayer,
      tribalLayer,
    ];
  }, [
    countyLayer,
    regionLayer,
    homeLayers,
    incidentLayers,
    structuresLayer,
    tribalLayer,
  ]);

  const childrenWithProps = React.Children.map<ReactNode, ReactNode>(
    props.children,
    child => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      const item = child as ReactElement<PropsWithChildren<ChildProps>>;
      if (React.isValidElement(item)) {
        if (item.props.layers != null) {
          return React.cloneElement(item, {
            status,
            layers: [...item.props.layers, ...layers],
          });
        } else {
          return React.cloneElement(item, { status });
        }
      }

      return child;
    }
  );

  return <>{childrenWithProps}</>;
});
