/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import Popup from 'react-map-gl/dist/esm/components/popup';
import _MapContext from 'react-map-gl/dist/esm/components/map-context';
import _useMapControl from 'react-map-gl/dist/esm/components/use-map-control';
import { Box, Button, Drawer } from '@mui/material';

export type PopupStatus = 'expanded' | 'collapsed' | 'closed';

type ChildProps = {
  popupStatus: PopupStatus;
};

export const PopupContainer = (props: {
  children: React.ReactNode;
  onClose: () => void;
  latitude: number;
  longitude: number;
  expandable?: boolean;
}) => {
  const [popupStatus, setPopupStatus] =
    React.useState<PopupStatus>('collapsed');

  const { expandable = true } = props;

  React.useEffect(() => {
    if (!expandable) {
      setPopupStatus('collapsed');
    }
  }, [expandable]);

  const childrenWithProps = React.Children.map<ReactNode, ReactNode>(
    props.children,
    child => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      const item = child as ReactElement<PropsWithChildren<ChildProps>>;
      if (React.isValidElement(item) && typeof item.type === 'function') {
        return React.cloneElement(item, { popupStatus });
      }

      if (React.isValidElement(item)) {
        return React.cloneElement(item);
      }

      return child;
    }
  );

  const context = React.useContext(_MapContext);

  if (popupStatus === 'expanded') {
    return (
      <Drawer
        PaperProps={{
          variant: 'outlined',
          elevation: 0,
          sx: {
            backgroundColor: 'rgb(245, 250, 252)',
            borderRight: 0,
            pointerEvents: 'auto',
            minWidth: 400,
          },
        }}
        variant="persistent"
        anchor="right"
        open={popupStatus === 'expanded'}
        onClose={() => setPopupStatus('collapsed')}
      >
        <DetailsBox
          setPopupStatus={setPopupStatus}
          children={childrenWithProps}
        />
      </Drawer>
    );
  }

  return (
    <Popup
      captureClick
      captureScroll
      captureDrag
      captureDoubleClick
      capturePointerMove
      closeButton={true}
      closeOnClick={false}
      onClose={props.onClose}
      anchor="top"
      offsetTop={10}
      latitude={props.latitude}
      longitude={props.longitude}
    >
      {expandable && (
        <Button
          onClick={evt => {
            context.eventManager.once(
              'click',
              (e: any) => e.stopPropagation(),
              evt.target
            );

            setPopupStatus('expanded');
          }}
        >
          Click to expand
        </Button>
      )}
      <Box sx={{ maxHeight: 400, width: 400, overflow: 'auto' }}>
        {childrenWithProps}
      </Box>
    </Popup>
  );
};

const DetailsBox = (
  props: PropsWithChildren<{
    setPopupStatus: React.Dispatch<React.SetStateAction<PopupStatus>>;
  }>
) => {
  const context = React.useContext(_MapContext);
  const { containerRef } = _useMapControl({
    captureScroll: true,
    captureClick: true,
    captureDrag: true,
    captureDoubleClick: true,
    capturePointerMove: true,
  });

  return (
    <Box ref={containerRef} sx={{ pt: 18 }}>
      <div>
        <Button
          onClick={evt => {
            context.eventManager.once(
              'click',
              (e: any) => e.stopPropagation(),
              evt.target
            );
            props.setPopupStatus('collapsed');
          }}
        >
          Close
        </Button>
      </div>
      {props.children}
    </Box>
  );
};
