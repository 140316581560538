/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Fab } from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { List, ListItem, Typography } from '@mui/material';
import info from './info.json';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  maxHeight: 600,
  overflow: 'hidden',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
};

const childStyle = {
  whiteSpace: 'pre-wrap',
  overflowY: 'scroll',
  maxHeight: 'inherit',
  pt: 2,
  px: 4,
  pb: 3,
};

type Props = {
  type: string | undefined;
};

export const InfoModal = ({ type }: Props) => {
  const [open, setOpen] = React.useState(false);
  const infoItem = info.data.find(item => item.type === type);

  return (
    <>
      <Fab
        data-print="hidden"
        color="primary"
        aria-label="text-snippet"
        sx={{ position: 'absolute', bottom: 20, right: 20 }}
        onClick={() => setOpen(true)}
      >
        <TextSnippetIcon />
      </Fab>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          {!infoItem ? (
            <Typography my={1} variant="h6" p={3}>
              No data available
            </Typography>
          ) : (
            <Box sx={{ ...childStyle }}>
              {infoItem.title && (
                <Typography mb={2} variant="h5">
                  {infoItem.title}
                </Typography>
              )}
              {infoItem.desc && (
                <Typography variant="body2">{infoItem.desc}</Typography>
              )}
              {infoItem.contact && (
                <Typography variant="body2">
                  <Typography my={1} variant="h6">
                    Contact Details
                  </Typography>
                  {infoItem.contact}
                </Typography>
              )}
              {infoItem.references && (
                <Typography variant="body2">
                  <Typography my={1} variant="h6">
                    References
                  </Typography>
                  {infoItem.references}
                </Typography>
              )}
              {infoItem.sources && (
                <Typography variant="body2">
                  <Typography mt={1} variant="h6">
                    Data sources
                  </Typography>
                  <List sx={{ listStyle: 'disc', paddingLeft: '1.5rem' }}>
                    {infoItem.sources.map((source, idx) => (
                      <ListItem
                        key={idx}
                        component="li"
                        style={{ display: 'list-item', padding: 0 }}
                      >
                        <Typography variant="body2">{source}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};
