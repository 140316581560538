import React, { useEffect, useState } from 'react';
import { useWowLayer } from '../wow/WowLayer';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { visualModeAtom } from '../map/atoms';
import { wowChunksSelector, usePeriodicWowDataRefresh } from '../wow/selectors';
import bbox from '@turf/bbox';
import centerOfMass from '@turf/center-of-mass';
import { FeatureCollection, Feature } from '@turf/helpers';
import { ReactComponent as TempoLogo } from '../images/tempo_full_color_DKGray.svg';
import Box from '@mui/material/Box';
import StoryList from '../wowcast/StoryList';
import { Link } from 'react-router-dom';
import 'maplibre-gl/dist/maplibre-gl.css';
import { StaticMap, _MapContext as MapContext } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { MapView } from '@deck.gl/core';
import { GeoJsonLayer } from '@deck.gl/layers';
import { InitialViewStateProps, ViewStateProps } from '@deck.gl/core/lib/deck';
import { mapStyleAtom } from '../map/MapStylePicker';
import { useRegionLayer } from '../Regions/FemaRegions';
import { ToggleLabelsButton } from '../map/ToggleLabelsButton';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useAnimationLogic } from '../utils/useAnimationLogic';
import { FlyToInterpolator } from '@deck.gl/core';
import { PostLegend } from '../Post/PostLayer';
import { Typography } from '@mui/material';
import { RGBColor } from '@deck.gl/core/utils/color';

export interface FlightPoint {
  latitude: number;
  longitude: number;
  zoom: number;
  properties: any;
  bbox?: [number, number, number, number];
}

const calculateZoomLevel = (minLng: number, maxLng: number): number => {
  const baseZoomLevel = Math.floor(Math.log2(360 / (maxLng - minLng)));
  const adjustedZoomLevel = baseZoomLevel;
  // Optionally, you can clamp the zoom level to ensure it stays within a desired range
  return adjustedZoomLevel;
};

const createFlightPathFromIndividualFeatures = (
  featureCollection: FeatureCollection<any, any>
): FlightPoint[] => {
  const flightPath: FlightPoint[] = [];

  featureCollection.features.forEach((feature: Feature) => {
    const [minLng, minLat, maxLng, maxLat] = bbox(feature);
    const featureCenter = centerOfMass(feature);
    const zoomLevel = calculateZoomLevel(minLng, maxLng); // Use the new function to calculate zoom level

    const corners: FlightPoint[] = [
      {
        latitude: featureCenter.geometry.coordinates[1],
        longitude: featureCenter.geometry.coordinates[0],
        zoom: zoomLevel,
        properties: feature.properties,
        bbox: [minLng, minLat, maxLng, maxLat],
      },
      {
        latitude: maxLat,
        longitude: minLng,
        zoom: zoomLevel,
        properties: feature.properties,
      },
      {
        latitude: minLat,
        longitude: maxLng,
        zoom: zoomLevel,
        properties: feature.properties,
      },
      {
        latitude: minLat,
        longitude: minLng,
        zoom: zoomLevel,
        properties: feature.properties,
      },
      {
        latitude: maxLat,
        longitude: minLng,
        zoom: zoomLevel,
        properties: feature.properties,
      },
    ];
    flightPath.push(...corners);
  });

  return flightPath;
};

const esriToken =
  process.env.REACT_APP_ARCGIS_API_KEY ??
  'AAPK980bae57996e40f59d613b3dd4b2d2f36xS38_jutL1jL-P9_HmTCMm2NKt1HLf-vlb3ZzjuJ3F11GxrAGnsRg1_3actRoNh';

export const initialViewState: InitialViewStateProps = {
  altitude: 1.5,
  bearing: 0,
  latitude: 39.446888593720935,
  longitude: -95.65494071225665,
  maxZoom: 18,
  minPitch: 0,
  maxPitch: 85,
  minZoom: 0,
  pitch: 0,
  zoom: 3.5,
};

const mainView = new MapView({
  id: 'main',
  controller: true,
});

export const WowcastPage: React.FC = () => {
  usePeriodicWowDataRefresh();
  const [isPulsing, setIsPulsing] = useState(false);

  const [minimapViewState, setMinimapViewState] = useState(initialViewState);
  const [minimapView, setMinimapView] = useState(
    () =>
      new MapView({
        id: 'minimap',
        x: window.innerWidth - 420,
        y: window.innerHeight - 640,
        width: '400px',
        height: '400px',
        controller: true,
        clear: true,
      })
  );
  const [mainViewState, setMainViewState] = useState(initialViewState);

  const [visualMode, setVisualMode] = useRecoilState(visualModeAtom);
  const [mapStyle] = useRecoilState(mapStyleAtom);
  const pulseTimeoutIdRef = React.useRef<NodeJS.Timeout | null>(null);
  const stepTimeoutIdRef = React.useRef<NodeJS.Timeout | null>(null);
  const wowLayers = useWowLayer({
    extruded: visualMode === '3d',
    visible: true,
  });
  const regionLayer = useRegionLayer({ mode: 'all' });
  const wowPerimeters = useRecoilValueLoadable(
    wowChunksSelector({ type: 'perimeter', visible: true })
  );
  const [currentFeatureProperties, setCurrentFeatureProperties] =
    useState<Record<string, any>>();

  const flightPath = React.useMemo(() => {
    return createFlightPathFromIndividualFeatures(
      wowPerimeters.contents[0] ?? { features: [] }
    );
  }, [wowPerimeters.contents]);

  const {
    currentStep,
    isAnimating,
    setIsAnimating,
    goToNextStep,
    setCurrentStep,
  } = useAnimationLogic(flightPath?.length ?? 0, 0);

  const handleSelectStory = React.useCallback(
    (point: FlightPoint) => {
      // setCurrentFeatureProperties(point.properties);
      setCurrentStep(
        flightPath.findIndex(
          p => p.properties.ObjectId === point.properties.ObjectId
        )
      );
      setIsAnimating(true); // Optional: Stop animation when a story is selected
    },
    [flightPath, setCurrentStep, setIsAnimating]
  );

  React.useEffect(() => {
    if (flightPath.length > 0) {
      setCurrentFeatureProperties(flightPath[currentStep].properties);
    }
  }, [currentStep, flightPath]);

  useEffect(() => {
    const handleResize = () => {
      setMinimapView(
        new MapView({
          id: 'minimap',
          x: window.innerWidth - 420,
          y: window.innerHeight - 640,
          width: '400px',
          height: '400px',
          controller: true,
          clear: true,
        })
      );
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set correct position

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isAnimatingRef = React.useRef(isAnimating);
  isAnimatingRef.current = isAnimating;

  const flyToNextPoint = React.useCallback(() => {
    if (!isAnimatingRef.current) return;

    const { latitude, longitude, zoom, properties } = flightPath[
      currentStep
    ] ?? { latitude: 0, longitude: 0, zoom: 0 };

    setCurrentFeatureProperties(properties); // Update the feature properties
    if (
      currentFeatureProperties?.threat_id !== properties?.threat_id ||
      properties?.ThreatLevel !== currentFeatureProperties?.ThreatLevel
    ) {
      setIsPulsing(true);
      if (pulseTimeoutIdRef.current) {
        clearTimeout(pulseTimeoutIdRef.current);
      }
      pulseTimeoutIdRef.current = setTimeout(() => {
        setIsPulsing(false);
      }, 1700);
    }

    setMinimapViewState(prevState => ({
      ...prevState,
      latitude,
      longitude,
      zoom,
      pitch: 0,
      transitionDuration: 3000,
      transitionInterpolator: new FlyToInterpolator(),
    }));

    if (stepTimeoutIdRef.current) {
      clearTimeout(stepTimeoutIdRef.current);
    }
    stepTimeoutIdRef.current = setTimeout(() => {
      goToNextStep();
    }, 10000);
    // Return a cleanup function to clear timeouts
  }, [
    currentStep,
    flightPath,
    setMinimapViewState,
    setCurrentFeatureProperties,
    currentFeatureProperties,
    isAnimatingRef,
    setIsPulsing,
    goToNextStep,
  ]);

  React.useEffect(() => {
    return () => {
      if (pulseTimeoutIdRef.current) {
        clearTimeout(pulseTimeoutIdRef.current);
      }
      if (stepTimeoutIdRef.current) {
        clearTimeout(stepTimeoutIdRef.current);
      }
    };
  }, []);

  const handlePlayPause = React.useCallback(() => {
    setIsAnimating(prevIsAnimating => {
      const newIsAnimating = !prevIsAnimating;
      return newIsAnimating;
    });
  }, [setIsAnimating]);

  useEffect(() => {
    setVisualMode('2d');
  }, [setVisualMode]);

  useEffect(() => {
    if (isAnimating) {
      flyToNextPoint();
    }
  }, [isAnimating, flyToNextPoint, currentStep]);

  const styleUrl = `https://basemaps-api.arcgis.com/arcgis/rest/services/styles/${mapStyle}?type=style&token=${esriToken}`;

  const handleViewStateChange = React.useCallback(
    ({
      viewState,
      interactionState,
      viewId,
    }: {
      viewState: ViewStateProps;
      interactionState: any;
      viewId: string;
    }) => {
      if (viewId === 'main') {
        setMainViewState(viewState);
      }
      if (viewId === 'minimap') {
        setMinimapViewState(viewState);
      }
    },
    [setMinimapViewState]
  );

  const wowColorMap: Record<string, RGBColor> = {
    earthquake1: [139, 69, 19],
    rain1: [127, 23, 14],
    rain2: [1, 255, 0],
    rain3: [74, 137, 92],
    tornado1: [250, 1, 2],
    tornado2: [255, 254, 0],
    tornado3: [255, 69, 0],
    wildfire1: [181, 9, 9],
    wildfire2: [255, 221, 176],
    wildfire3: [253, 68, 3],
    winter1: [123, 104, 238],
    winter2: [74, 212, 240],
  };

  const bboxOutline = () =>
    new GeoJsonLayer({
      id: 'bbox-outline',
      data: flightPath
        .map(p => {
          const bbox = p.bbox;
          if (!bbox) return null;
          // Construct a Polygon geometry from the bbox
          const coordinates = [
            [
              [bbox[0], bbox[1]], // bottom left
              [bbox[0], bbox[3]], // top left
              [bbox[2], bbox[3]], // top right
              [bbox[2], bbox[1]], // bottom right
              [bbox[0], bbox[1]], // back to bottom left to close the polygon
            ],
          ];
          return {
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: coordinates },
            properties: {
              ObjectId: p.properties.ObjectId,
              CategoryID: p.properties.CategoryID,
            },
          };
        })
        .filter(p => p !== null),
      // extensions: [dataFilterExtension],
      // filterRange: [1, 1],
      pickable: false,
      stroked: true,
      filled: false,
      lineWidthScale: 20,
      lineWidthMinPixels: 2,
      getLineColor: (d: any) => {
        return [...wowColorMap[d.properties.CategoryID], 255];
      },
      getLineWidth: 20,
      updateTriggers: {
        filterValue: [currentStep],
        getLineColor: [currentStep],
      },
    });

  return (
    <div
      style={{
        width: '100vw',
        height: '100svh',
        backgroundColor: 'rgb(0, 62, 103)',
      }}
    >
      <Box display="flex" className="full-width-height">
        <Box
          id="wowcast-map-container"
          display="flex"
          width={1}
          height={1}
          sx={{
            position: 'relative',
            height: 'calc(100vh - 210px) !important',
            overflow: 'hidden',
            marginTop: 0,
          }}
        >
          <DeckGL
            initialViewState={initialViewState}
            reuseMaps
            // @ts-ignore
            ContextProvider={MapContext.Provider}
            // @ts-ignore
            layers={[regionLayer, wowLayers, bboxOutline()]}
            views={[mainView, minimapView]}
            // @ts-ignore
            viewState={{ main: mainViewState, minimap: minimapViewState }}
            // @ts-ignore
            onViewStateChange={handleViewStateChange}
          >
            {/* @ts-ignore */}
            <MapView id="main">
              <StaticMap
                mapStyle={styleUrl}
                attributionControl={true}
                // @ts-ignore
                mapOptions={{
                  antialias: true,
                  customAttribution: 'NLT',
                  attributionControl: true,
                  interactive: false,
                }}
              />
            </MapView>
            {/* @ts-ignore */}
            <MapView id="minimap">
              <Typography
                sx={{
                  color: 'white',
                  padding: 2,
                  fontSize: '1.50rem',
                  position: 'absolute',
                  top: -50,
                }}
              >
                {currentFeatureProperties?.time_category === 'past'
                  ? 'Observed Alert'
                  : 'Forecasted Alert'}
                {' - '} {currentFeatureProperties?.Threat}
              </Typography>
              <StaticMap
                mapStyle={styleUrl}
                mapOptions={{
                  antialias: true,
                  customAttribution: 'NLT',
                  preserveDrawingBuffer: true,
                  attributionControl: false,
                  interactive: false,
                }}
                style={{
                  border: '1px solid white',
                }}
              />
            </MapView>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                padding: '0.5rem',
              }}
            >
              <ToggleLabelsButton initialVisibility="none" />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: '50px',
                right: '8px',
                zIndex: 1000,
              }}
            >
              <IconButton
                onClick={handlePlayPause}
                color="primary"
                sx={{ backgroundColor: 'white', width: 30, height: 30 }}
                aria-label={isAnimating ? 'Pause' : 'Play'}
              >
                {isAnimating ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
            </Box>
          </DeckGL>
        </Box>
      </Box>
      <Box
        sx={{
          color: 'white',
          display: 'flex',
          position: 'absolute',
          flexDirection: 'column',
          alignItems: 'flex-end',
          padding: '0.5rem',
          bottom: 214,
          left: 'max(23.4%, 370px)',
          width: '200px',
          zIndex: 1000,
        }}
      >
        <PostLegend selectedMap="wow" />
      </Box>

      <StoryList
        features={wowPerimeters.contents[0]?.features ?? []}
        flightPath={flightPath}
        currentStep={currentStep}
        onSelect={handleSelectStory}
      />
      <NewsCastFooter
        isPulsing={isPulsing}
        currentFeatureProperties={currentFeatureProperties}
      />
    </div>
  );
};

type NewsCastFooterProps = {
  isPulsing: boolean;
  currentFeatureProperties?: Record<string, any>;
};

const NewsCastFooter = React.memo((props: NewsCastFooterProps) => {
  const { currentFeatureProperties } = props;
  return (
    <Box
      sx={{
        bottom: 0,
        right: 0,
        zIndex: 1000,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'flex-end',
        maxHeight: 210,
      }}
      className="news-cast-wrap"
    >
      <div
        className={`news-cast-container ${
          props.isPulsing ? 'pulsing-highlight' : ''
        }`}
      >
        <Link to="/">
          <TempoLogo
            height={100}
            width={140}
            style={{
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              padding: '0.5rem',
              minHeight: 100,
              minWidth: 120,
            }}
            aria-label="TEMPO"
          />
        </Link>
        {currentFeatureProperties != null && (
          <>
            <div className="news-cast-detail">
              Threat <strong>{currentFeatureProperties?.Threat}</strong>
            </div>
            <div className="news-cast-detail">
              Description
              <span>
                <strong>{currentFeatureProperties?.ThreatDescription}</strong>
              </span>
            </div>
            <div className="news-cast-detail">
              Threshold Exceeded{' '}
              <strong>{currentFeatureProperties?.ThreatLevel}</strong>
            </div>
            <div className="news-cast-detail">
              Areas Affected
              <strong>{currentFeatureProperties?.state_intersect}</strong>
            </div>
            {currentFeatureProperties?.structures_exposed && (
              <div className="news-cast-detail">
                Structures Exposed
                <strong>
                  {currentFeatureProperties?.structures_exposed.toLocaleString()}
                </strong>
              </div>
            )}
            {currentFeatureProperties?.residential_struc_exposed && (
              <div className="news-cast-detail">
                Residential Structures Exposed
                <strong>
                  {currentFeatureProperties?.residential_struc_exposed.toLocaleString()}
                </strong>
              </div>
            )}
            <div className="news-cast-detail">
              Last Updated
              <strong>
                {new Date(
                  currentFeatureProperties?.LastUpdatedOn
                ).toLocaleString()}
              </strong>
            </div>
            <div className="news-cast-detail">
              Source
              <strong>{currentFeatureProperties?.DataSource}</strong>
            </div>
          </>
        )}

        <div className="news-cast-footer">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center',
              padding: '0.5rem 0.5rem',
              width: '200px',
              textAlign: 'right',
            }}
          >
            <div className="qr-code-background" />
            FEMA Contact:{' '}
            <a
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
              href="mailto:adam.barker@fema.dhs.gov"
            >
              adam.barker@fema.dhs.gov
            </a>
          </Box>
        </div>
      </div>
      <Box
        sx={{
          padding: '0.5rem 1rem',
          width: '100%',
          textAlign: 'left',
          fontSize: '1.4rem',
        }}
      >
        The WoW layer displays areas across the U.S. that are under severe
        weather conditions or other hazards and risks. The layer is displayed
        alongside POST`s relative population exposure rank, a metric used to
        prioritize disaster response operations based on the predicted exposure
        of vulnerable populations. This web page and the data shown will
        automatically refresh every 15 minutes.
      </Box>
    </Box>
  );
});
