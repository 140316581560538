import { useState, useCallback, useEffect } from 'react';

export const useAnimationLogic = (
  flightPathLength: number,
  initialStep = 0
) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [isAnimating, setIsAnimating] = useState(false);

  const goToNextStep = useCallback(() => {
    setCurrentStep(prevStep => (prevStep + 1) % flightPathLength);
  }, [flightPathLength]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isAnimating) {
      timeoutId = setTimeout(goToNextStep, 10000); // Example duration
    }
    return () => clearTimeout(timeoutId);
  }, [isAnimating, goToNextStep]);

  return {
    currentStep,
    isAnimating,
    setIsAnimating,
    goToNextStep,
    setCurrentStep,
  };
};
