/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import React from 'react';
import './App.css';
import Layout from './components/Layout';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useParams,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { RecoilRoot } from 'recoil';
import { theme } from './theme';
import { MapPage } from './pages/MapPage';
import SubscriptionPage from './subscriptions/SubscriptionPage';
import SubscriptionConfirmPage from './subscriptions/SubscriptionConfirmPage';
import UnsubscribeConfirmPage from './subscriptions/UnsubscribeConfirmPage';
import OAuthCallback from './auth/OAuthCallback';
import { WowcastPage } from './pages/WowcastPage';
import { IncidentcastPage } from './pages/IncidentcastPage';

const App = () => {
  const handleAuthenticated = () => {
    console.log('authenticated');
  };

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={null}>
          <BrowserRouter>
            <Routes>
              <Route
                path="*"
                element={
                  <Layout>
                    <div id="clouds">
                      <div className="cloud x1"></div>
                      <div className="cloud x2"></div>
                      <div className="cloud x3"></div>
                      <div className="cloud x4"></div>
                      <div className="cloud x5"></div>
                    </div>
                    <MapPage />
                  </Layout>
                }
              />
              <Route path="/wowcast" element={<WowcastPage />} />
              <Route
                path="/wowcast/:incidentId/:sectionId"
                element={<IncidentcastPage />}
              />
              <Route path="/subscriptions" element={<SubscriptionPage />} />

              <Route path="/:incidentId" element={<IncidentRedirect />} />

              <Route
                path="/subscriptions/unsubscribe/confirm/:id"
                element={<UnsubscribeConfirmPage />}
              />

              <Route
                path="/subscriptions/confirm/:id"
                element={<SubscriptionConfirmPage />}
              />
              <Route
                path="/auth/callback"
                element={
                  <OAuthCallback onAuthenticated={handleAuthenticated} />
                }
              />
            </Routes>
          </BrowserRouter>
        </React.Suspense>
      </ThemeProvider>
    </RecoilRoot>
  );
};

const IncidentRedirect = () => {
  const params = useParams();

  if (params.incidentId && !params.viewType) {
    return <Navigate to={`/${params.incidentId}/population/map`} replace />;
  }

  return null;
};

export default App;
