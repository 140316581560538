/**
 * Copyright 2020 New Light Technologies, Inc.
 *
 * With Supporting Sponsorship from the Federal Emergency Management Agency (Contract: GSA Stars II GS-06F-0968Z)
 * In accordance with FAR 52.227-14(c)(iii), New Light Technologies, Inc. grants to the Government and others acting on its behalf a paid-up, nonexclusive, irrevocable, worldwide license in such copyrighted computer software and data to reproduce, prepare derivative works, and perform publicly and display publicly (but not to distribute copies to the public) by or on behalf of the Government.
 * Any other use, distribution, reproduction, modification, or publication without the prior express written authorization of New Light Technologies, Inc. is strictly prohibited.
 * All other rights are reserved by their respective copyright holders.
 *
 */
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { ExposureStats } from '../Incident/ExposureStats';
import { useRecoilValueLoadable } from 'recoil';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { allSectionsDataAvailabilitySelector } from '../Incident/atoms';

import { IncidentChart } from '../Incident/IncidentChart';
import {
  Business,
  CellTower,
  Home,
  InventoryOutlined,
  People,
} from '@mui/icons-material';

export type IncidentSection =
  | typeof atRiskSections[number]['key']
  | typeof resourceTrackerSections[number]['key']
  | typeof communityLifeSections[number]['key'];

export const IncidentPageDrawer = React.memo(() => {
  const params = useParams();

  const sectionId = params.sectionId;
  const selectedSection = sectionId ?? 'population';
  const navigate = useNavigate();
  const incidentId = params.incidentId;
  const isMatch = params.incidentId != null;

  const sectionDataAvailability = useRecoilValueLoadable(
    allSectionsDataAvailabilitySelector(incidentId)
  );

  const handleChange = React.useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      nextView: IncidentSection
    ) => {
      if (nextView != null && isMatch) {
        navigate(`/${incidentId}/${nextView}/${params.viewType}`);
      }
    },
    [navigate, incidentId, isMatch, params.viewType]
  );

  return (
    <>
      <Box
        sx={{
          flexWrap: 'wrap',
          mt: 2,
          maxWidth: '180px',
        }}
      >
        <Divider />
        <Typography variant="h6" sx={{ p: 1 }}>
          At Risk
        </Typography>
        <Divider />
        <Nav>
          {atRiskSections.map(section => (
            <ListItemButton
              dense
              key={section.key}
              selected={section.key === selectedSection}
              onClick={event => handleChange(event, section.key)}
              disabled={
                sectionDataAvailability.state === 'loading'
                  ? false
                  : !sectionDataAvailability.contents[section.key]
              }
            >
              {section.icon && (
                <ListItemIcon
                  sx={{ color: theme => theme.palette.primary.dark }}
                >
                  {section.icon}
                </ListItemIcon>
              )}

              <ListItemText primary={section.title} />
            </ListItemButton>
          ))}
          {communityLifeSections.map(section => (
            <ListItemButton
              dense
              key={section.key}
              selected={section.key === selectedSection}
              onClick={event => handleChange(event, section.key)}
              disabled={
                sectionDataAvailability.state === 'loading'
                  ? false
                  : !sectionDataAvailability.contents[section.key]
              }
            >
              {section.icon && (
                <ListItemIcon
                  sx={{ color: theme => theme.palette.primary.dark }}
                >
                  {section.icon}
                </ListItemIcon>
              )}

              <ListItemText primary={section.title} />
            </ListItemButton>
          ))}

          {resourceTrackerSections.map(section => (
            <ListItemButton
              dense
              key={section.key}
              selected={section.key === selectedSection}
              onClick={event => handleChange(event, section.key)}
              disabled={
                sectionDataAvailability.state === 'loading'
                  ? false
                  : !sectionDataAvailability.contents[section.key]
              }
            >
              {section.icon && (
                <ListItemIcon
                  sx={{ color: theme => theme.palette.primary.dark }}
                >
                  {section.icon}
                </ListItemIcon>
              )}

              <ListItemText primary={section.title} />
            </ListItemButton>
          ))}
        </Nav>
      </Box>
    </>
  );
});

const Nav = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

export const IncidentMapChildren = React.memo(() => {
  const { sectionId, incidentId, viewType } = useParams<{
    sectionId: IncidentSection;
    incidentId: string;
    viewType: 'map' | 'detail';
  }>();
  const selectedSection = sectionId ?? 'population';

  return (
    <>
      <Box
        data-print="hidden"
        sx={{
          display: 'grid',
          padding: 1,
          top: 0,
          position: 'absolute',
          right: 0,
          maxWidth: 500,
          '@media (max-width: 768px)': {
            display: 'none',
          },
        }}
      >
        <ExposureStats
          incidentId={incidentId ?? 'default'}
          section={selectedSection}
          viewType={viewType ?? 'map'}
          hideFilters
        />
      </Box>
    </>
  );
});

const atRiskSections = [
  {
    title: 'Population',
    key: 'population',
    icon: <People />,
  },
  {
    title: 'Residential Structures',
    key: 'residential',
    icon: <Home />,
  },
  {
    title: 'Non-Residential Structures',
    key: 'nonResidentialStructures',
    icon: <Business />,
  },
] as const;

const communityLifeSections = [
  // {
  //   title: 'Power',
  //   key: 'power',
  //   icon: <EvStation />,
  // },
  {
    title: 'Communications',
    key: 'communications',
    icon: <CellTower />,
  },
  // {
  //   title: 'Transportation',
  //   key: 'transportation',
  //   icon: <CarCrash />,
  // },
  // {
  //   title: 'Commerce',
  //   key: 'commerce',
  // },
] as const;

const resourceTrackerSections = [
  // {
  //   title: 'Commodities',
  //   key: 'commodities',
  //   icon: <FoodBank />,
  // },

  {
    title: 'Declarations',
    key: 'declarations',
    icon: <InventoryOutlined />,
  },
] as const;

export const incidentPageSections = [
  ...atRiskSections,
  ...resourceTrackerSections,
  ...communityLifeSections,
];

export const IncidentDetailPage = React.memo(() => {
  const params = useMatch({
    path: ':incidentId/:sectionId/:viewType/:attributeId?',
  })?.params;
  const containerStyles = React.useMemo(() => {
    return {
      '@media print': {
        marginLeft: 0,
      },
    };
  }, []);

  return (
    <React.Suspense fallback={null}>
      <Box
        sx={{
          ...containerStyles,
          backgroundColor: 'white',
          pt: 1,
          pb: 10,
          overflow: 'hidden',
          overflowY: 'auto',
          height: 'calc(100svh - 142px)',
          zIndex: 99,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            backgroundColor: 'white',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {params?.incidentId && params?.sectionId && params?.viewType && (
            <ExposureStats
              incidentId={params.incidentId}
              section={params.sectionId as IncidentSection}
              viewType={params.viewType}
            />
          )}

          <IncidentChart />
        </Box>
      </Box>
    </React.Suspense>
  );
});
